import React from 'react'

import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'

interface TagsProps {
  category?: any
  segments?: any[]
  sx?: object
}

const Tags = (props: TagsProps) => {
  const { category, segments, sx } = props

  const tags = [
    ...(category?.fields.title ? [category?.fields.title] : []),
    ...(segments?.map((segments) => segments.fields.name) ?? [])
  ]

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 0.5,
          ...sx
        }}
      >
        {tags.map((tag) => (
          <Chip key={tag} label={tag} />
        ))}
      </Box>
    </>
  )
}

export default Tags
