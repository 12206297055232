import React from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import * as WidgetTagStatusActions from 'redux/actions/WidgetTagStatuses'

import Box from 'components_new/atoms/Box'
import Grid from 'components_new/atoms/Grid'

import SettingsDrawer from 'components_new/molecules/SettingsDrawer'
import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'
import SettingsEditItem from 'components_new/molecules/SettingsItem/SettingsEditItem'
import SettingsToggleOption from 'components_new/molecules/SettingsToggleOption'

import {
  getTagOptions,
  isLoading,
  ITagStatusData,
  translateSeverity,
  translateStatusCondition,
  translateStatusDateValue,
  translateStatusValueLabel
} from './utils'
import {
  TagStatusCondition,
  TagStatusDateValue,
  TagStatusItem,
  TagStatusSeverity,
  WidgetTagObject,
  WidgetTagStatusPatchAttributes
} from 'types/GlobalWidgetTag'
import { DatasetType, WidgetObject } from 'types/GlobalWidget'
import { ApplicationState } from 'redux/Stores/types'

interface EditProps {
  open: boolean
  onBack: () => void
  onClose: () => void
  statusItem: TagStatusItem
  tag: WidgetTagObject
  widget: WidgetObject
}

const Edit = (props: ComponentProps) => {
  const {
    onBack,
    onClose,
    open,
    statusItem,
    tag,
    TagOptionStore,
    tryDeleteWidgetTagStatus,
    tryUpdateWidgetTagStatus,
    widget
  } = props

  const options = getTagOptions(tag, TagOptionStore)
  const loading = isLoading(tag, TagOptionStore)

  const getValue = (): number | string | TagStatusDateValue => {
    if (typeof statusItem.value === 'object') {
      return statusItem.value.selected
    } else {
      return statusItem.value
    }
  }

  const handleUpdate = (body: Partial<ITagStatusData>) => {
    if ('value' in body && (body.value === null || body.value === '')) {
      // do nothing
      return
    }

    tryUpdateWidgetTagStatus(
      statusItem.id,
      { data: body } as WidgetTagStatusPatchAttributes,
      widget.dashboard_id,
      widget.id,
      tag.id
    )
  }

  const handleDelete = () => {
    tryDeleteWidgetTagStatus(
      statusItem.id,
      widget.dashboard_id,
      widget.id,
      tag.id
    )

    onBack()
  }

  return (
    <>
      {/*-- drawer --*/}
      <SettingsDrawer
        open={open}
        onBack={onBack}
        onClose={onClose}
        title={'Redigera indikator'}
      >
        {statusItem ? (
          <>
            <SettingsGroup helperText="Ange ett villkor som ska uppfyllas.">
              {/*-- condition --*/}
              <SettingsEditItem<TagStatusCondition>
                input={{
                  options: statusItem.condition.options.map((option) => {
                    return {
                      label: translateStatusCondition[option][statusItem.type],
                      value: option
                    }
                  }),
                  type: 'select',
                  value: statusItem.condition.selected
                }}
                onSubmit={(value) => handleUpdate({ condition: value })}
                title={translateStatusValueLabel[statusItem.type]}
                renderValue={(value) =>
                  translateStatusCondition[value][statusItem.type]
                }
              />

              {/*-- conditional value --*/}
              {statusItem.type === DatasetType.DATE &&
              typeof statusItem.value === 'object' &&
              (statusItem.value.selected === TagStatusDateValue.DAYS_FROM_NOW ||
                statusItem.value.selected ===
                  TagStatusDateValue.DAYS_SINCE_NOW) ? (
                    <SettingsEditItem<number | null>
                      input={{
                        type: 'number',
                        value: statusItem.conditional_value
                      }}
                      onSubmit={(value) => {
                        handleUpdate({ conditional_value: value })
                      }}
                      renderValue={(value) => value?.toString() ?? ''}
                      unit="dagar"
                    />
                  ) : null}

              {/*-- value --*/}
              <SettingsEditItem<number | string | TagStatusDateValue>
                input={{
                  options:
                    typeof statusItem.value === 'object'
                      ? options.map((option) => ({
                        label:
                            statusItem.type === DatasetType.DATE
                              ? translateStatusDateValue[
                                  option as TagStatusDateValue
                              ]
                              : option,
                        value: option
                      }))
                      : undefined,
                  type:
                    statusItem.type === DatasetType.DATE ||
                    statusItem.type === DatasetType.ENUM
                      ? 'select'
                      : 'text',
                  value: getValue()
                }}
                loading={loading}
                onSubmit={(value) => handleUpdate({ value: value })}
                renderValue={(value) => {
                  if (statusItem.type === DatasetType.DATE) {
                    return translateStatusDateValue[value as TagStatusDateValue]
                  } else {
                    return value?.toString() ?? ''
                  }
                }}
              />
            </SettingsGroup>

            {/*-- severity --*/}
            <SettingsGroup helperText="Välj vilken indikator som ska användas då ett villkor är uppfyllt.">
              <SettingsItem>
                <Grid container={true} columns={3}>
                  {Object.values(TagStatusSeverity).map((severity) => (
                    <Grid item={true} key={severity} xs={1}>
                      <SettingsToggleOption
                        onClick={() => {
                          if (severity !== statusItem.severity.selected) {
                            handleUpdate({ severity: severity })
                          }
                        }}
                        selected={severity === statusItem.severity.selected}
                        title={translateSeverity[severity]}
                      >
                        <Box
                          sx={{
                            width: 32,
                            height: 32,
                            borderRadius: 16,
                            bgcolor: `${severity}.main`,
                            border: '1px solid',
                            borderColor: 'divider',
                            transition: 'all 240ms ease 0s',
                            '&:hover': {
                              borderColor: 'action.focus'
                            }
                          }}
                        />
                      </SettingsToggleOption>
                    </Grid>
                  ))}
                </Grid>
              </SettingsItem>
            </SettingsGroup>

            <SettingsGroup>
              <SettingsItem
                color="error"
                onClick={handleDelete}
                title="Radera"
                variant="action"
              />
            </SettingsGroup>
          </>
        ) : null}
      </SettingsDrawer>
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  TagOptionStore: state.TagOptionStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(WidgetTagStatusActions, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> & EditProps

export default connector(Edit)
