import { IconNames } from 'components_new/atoms/Icon'
import { TNavigationItem } from 'components_new/molecules/NavigationItem'
import { UserType } from 'components_new/organisms/Dashboard/utils'
import {
  AccountBody,
  AccountReducerType,
  AccountType
} from 'redux/reducers/Accounts'
import { DashboardBody, DashboardGroup } from 'redux/reducers/Dashboards'
import { DashboardFolder } from 'types/GlobalDashboardFolder'
import { AccountRole, User } from 'types/GlobalUser'

export const getEmptyState = (showFavorites: boolean) => {
  if (showFavorites) {
    return {
      title: 'Inga favoriter här',
      body: 'Favoritmarkera dashboards så hamnar de här.',
      src: '/assets/illustrations/star_dashboard.svg',
      routeToAll: true
    }
  }

  return {
    title: 'Inga dashboards här',
    body: 'Det finns inga dashboards att visa från din organisation.',
    src: '/assets/illustrations/find_dashboard.svg',
    routeToAll: false
  }
}

export const NO_FOLDER = 'NO_FOLDER'
export const FAVORITES = 'FAVORITES'

export const groupDashboards = (
  groups: DashboardGroup[],
  folders: DashboardFolder[]
) => {
  const groupedDashboards: { [key: string]: DashboardGroup[] } = {
    [NO_FOLDER]: groups.filter(
      (group) => !folders.some((folder) => folder.id === group.folder_id)
    ),
    [FAVORITES]: groups.filter((group) => group.favorite.active)
  }

  folders.forEach((folder) => {
    groupedDashboards[folder.id] = groups.filter(
      (group) => group.folder_id === folder.id
    )
  })

  return groupedDashboards
}

export const getSelectedGroups = (
  groupedDashboards: { [key: string]: DashboardGroup[] },
  showFavorites: boolean,
  folderId?: string | null
) => {
  if (showFavorites) {
    return groupedDashboards[FAVORITES] || []
  }

  if (folderId) {
    return groupedDashboards[folderId] || []
  }

  return groupedDashboards[NO_FOLDER] || []
}

export const getFolderTree = (
  groupedDashboards: { [key: string]: DashboardGroup[] },
  folders: DashboardFolder[]
): TNavigationItem[] => {
  return [
    {
      icon: 'GridViewOutlined',
      href: '/dashboards',
      title: 'Alla dashboards',
      subItems: [
        ...folders.map((folder) => {
          return {
            icon: 'FolderOutlined' as IconNames,
            href: `/dashboards/folders/${folder.id}`,
            title: folder.name,
            subItems: (groupedDashboards[folder.id] || [])
              .sort((a, b) => a.title.localeCompare(b.title, 'sv'))
              .map((group) => {
                return {
                  icon: 'AnalyticsOutlined' as IconNames,
                  href: `/dashboards/${group.dashboards[0]}`,
                  title: group.title
                }
              })
          }
        }),
        // without folder
        ...(groupedDashboards[NO_FOLDER] || [])
          .sort((a, b) => a.title.localeCompare(b.title, 'sv'))
          .map((group) => {
            return {
              icon: 'AnalyticsOutlined' as IconNames,
              href: `/dashboards/${group.dashboards[0]}`,
              title: group.title
            }
          })
      ]
    },
    {
      divider: true,
      icon: 'StarOutlined',
      href: '/dashboards/favorites',
      title: 'Favoriter',
      subItems: (groupedDashboards[FAVORITES] || [])
        .sort((a, b) => a.title.localeCompare(b.title, 'sv'))
        .map((group) => {
          return {
            icon: 'AnalyticsOutlined',
            href: `/dashboards/${group.dashboards[0]}`,
            title: group.title,
            neverActive: true
          }
        })
    },
    {
      icon: 'RestoreOutlined',
      href: '/dashboards/recent',
      title: 'Senaste'
    }
  ]
}

export const getPermissions = (
  AccountStore: AccountReducerType,
  dashboardData: DashboardBody,
  id: string,
  signedInUser: User | null,
  ownerId: string | null
): UserType[] => {
  if (dashboardData[id]?.share_settings && AccountStore.data && signedInUser) {
    const owner = getOwner(AccountStore.data, ownerId)

    const admins = Object.values(AccountStore.data)
      .filter(
        (account) =>
          account.role === AccountRole.ADMIN && account.id !== ownerId
      )
      .map((account) => formatUser(account))

    const viewers = dashboardData[id]?.share_settings.permissions
      .filter(
        (permission) =>
          permission.account_id && AccountStore.data[permission.account_id]
      )
      .map((permission) => {
        const account = AccountStore.data[permission.account_id as string]

        return formatUser(account, account.id !== signedInUser.id)
      })

    return [...(owner ? [owner] : []), ...admins, ...viewers]
  }

  return []
}

export function getOwner(
  accounts: AccountBody,
  ownerId: string | null
): UserType | null {
  if (!ownerId || !accounts[ownerId]) {
    return null
  }

  return formatUser(accounts[ownerId], false, true)
}

const formatUser = (
  account: AccountType,
  allowRevoke = false,
  owner = false
): UserType => {
  return {
    accountId: account.id,
    companyGroupId: account.company_group_id,
    name:
      account.first_name || account.last_name
        ? `${account.first_name} ${account.last_name}`
        : 'Nytt konto',
    email: account.email,
    role: account.role,
    allowRevoke,
    owner
  }
}

export enum SortByMethod {
  LAST_VIEWED = 'LAST_VIEWED',
  ALPHABETICAL = 'ALPHABETICAL',
  UPDATED_AT = 'UPDATED_AT'
}
