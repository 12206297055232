import { formatNumber, convertLocaleToNumber } from 'utils/numbers'

export interface IRealEstate {
  id: string
  name: string
  atemp: number | null
  boa: number | null
  loa: number | null
}

export interface IParsedRealEstateForAllocationKeys {
  boa: number
  loa: number
  atemp: number
  rentable: number
  percentOfTotalBoa: string
  percentOfTotalLoa: string
  percentOfTotalRentable: string
  percentOfTotalAtemp: string
  id: string
  name: string
}

// Adjust rounding to ensure the total is exactly 100%
function adjustPercentages(percentages: number[]): string[] {
  const total =
    percentages.reduce((sum, p) => sum + Math.floor(p * 100), 0) / 100
  const roundedPercentages = percentages.map((p) => Math.floor(p * 100) / 100)

  // round difference to 2 decimals
  let difference = Number((100 - total).toFixed(2))

  // Distribute the rounding difference
  while (difference > 0) {
    for (let i = 0; i < roundedPercentages.length && difference > 0; i++) {
      // round to 2 decimals, since +=0.01 can cause float point issues
      roundedPercentages[i] = Number((roundedPercentages[i] + 0.01).toFixed(2))
      difference = Number((difference - 0.01).toFixed(2))
    }
  }

  return roundedPercentages.map((nbr) => formatNumber(nbr, 2))
}

function calculatePercentage(part: number, total: number): number {
  return total === 0 ? 0 : (part / total) * 100
}

// Used in allocation keys.
export function parseRealEstatesForAllocationKeys(
  realEstates: IRealEstate[]
): IParsedRealEstateForAllocationKeys[] {
  const totalBoa = realEstates.reduce(
    (total, realEstate) => total + (realEstate.boa ?? 0),
    0
  )
  const totalLoa = realEstates.reduce(
    (total, realEstate) => total + (realEstate.loa ?? 0),
    0
  )
  const totalAtemp = realEstates.reduce(
    (total, realEstate) => total + (realEstate.atemp ?? 0),
    0
  )
  const totalRentable = totalBoa + totalLoa

  const boaPercentages: number[] = []
  const loaPercentages: number[] = []
  const rentablePercentages: number[] = []
  const atempPercentages: number[] = []

  const parsedRealEstates = realEstates.map((realEstate) => {
    const boa = realEstate.boa ?? 0
    const loa = realEstate.loa ?? 0
    const atemp = realEstate.atemp ?? 0
    const rentable = boa + loa

    // Calculate unrounded percentages
    const percentOfTotalBoa = calculatePercentage(boa, totalBoa)
    const percentOfTotalLoa = calculatePercentage(loa, totalLoa)
    const percentOfTotalRentable = calculatePercentage(rentable, totalRentable)
    const percentOfTotalAtemp = calculatePercentage(atemp, totalAtemp)

    // Store unrounded percentages for later adjustment
    boaPercentages.push(percentOfTotalBoa)
    loaPercentages.push(percentOfTotalLoa)
    rentablePercentages.push(percentOfTotalRentable)
    atempPercentages.push(percentOfTotalAtemp)

    return {
      ...realEstate,
      boa,
      loa,
      atemp,
      rentable,
      percentOfTotalBoa,
      percentOfTotalLoa,
      percentOfTotalRentable,
      percentOfTotalAtemp
    }
  })

  // Adjust percentages to ensure they sum to 100%
  const adjustedBoa = adjustPercentages(boaPercentages)
  const adjustedLoa = adjustPercentages(loaPercentages)
  const adjustedRentable = adjustPercentages(rentablePercentages)
  const adjustedAtemp = adjustPercentages(atempPercentages)

  // Assign adjusted values back to the parsed real estate objects
  return parsedRealEstates.map((realEstate, index) => ({
    ...realEstate,
    percentOfTotalBoa: adjustedBoa[index],
    percentOfTotalLoa: adjustedLoa[index],
    percentOfTotalRentable: adjustedRentable[index],
    percentOfTotalAtemp: adjustedAtemp[index]
  }))
}

export function parseAllocationBody(realEstateId: string, share: string) {
  return {
    real_estate_id: realEstateId,
    // convert to decimal with precision 4.
    share: Number((convertLocaleToNumber(share) / 100).toFixed(4))
  }
}
