import React, { useMemo, useState } from 'react'

import { WidgetObject } from 'types/GlobalWidget'
import { WidgetTagObject } from 'types/GlobalWidgetTag'

import Box from 'components_new/atoms/Box'

import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'

import {
  getStatusPreviewValue,
  translateStatusCondition,
  translateStatusValueLabel
} from './utils'

import Add from './add'
import Edit from './edit'

interface StatusSectionProps {
  onClose: () => void
  tag: WidgetTagObject
  widget: WidgetObject
}

const StatusSection = (props: StatusSectionProps) => {
  const { onClose, tag, widget } = props

  /*-- status item to update/delete --*/
  const [statusItemId, setStatusItemId] = useState<string | null>(null)

  /*-- add --*/
  const [showAdd, setShowAdd] = useState<boolean>(false)

  const statusItem = useMemo(() => {
    if (statusItemId) {
      return tag.settings.status.items.find((item) => item.id === statusItemId)
    }

    return null
  }, [tag.settings.status.items, statusItemId])

  return (
    <>
      <SettingsGroup
        title="Indikation"
        helperText="Om flera indikationer med överlappande villkor finns, kommer den översta indikationens villkor att prioriteras. Säkerställ att prioritetsordningen är korrekt genom att placera den viktigaste indikationen högst upp."
      >
        {tag.settings.status.items.map((item) => (
          <SettingsItem
            actions={
              <Box
                sx={{
                  width: 12,
                  height: 12,
                  borderRadius: 6,
                  bgcolor: `${item.severity.selected}.main`
                }}
              />
            }
            key={item.id}
            onClick={() => setStatusItemId(item.id)}
            title={`${translateStatusValueLabel[tag.type]} ${
              translateStatusCondition[item.condition.selected][tag.type]
            }`}
            value={getStatusPreviewValue(item)}
          />
        ))}

        <SettingsItem
          color="success"
          onClick={() => setShowAdd(true)}
          title="Lägg till"
          variant="add"
        />
      </SettingsGroup>

      {statusItem ? (
        <Edit
          onBack={() => setStatusItemId(null)}
          onClose={onClose}
          open={!!statusItem}
          statusItem={statusItem}
          tag={tag}
          widget={widget}
        />
      ) : null}

      <Add
        onCancel={() => setShowAdd(false)}
        onClose={onClose}
        open={showAdd}
        tag={tag}
        widget={widget}
      />
    </>
  )
}

export default StatusSection
