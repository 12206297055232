import { combineReducers } from 'redux'

import AuthStore from 'redux/reducers/Authentication'
import AlertStore from 'redux/reducers/Alert'
import LockedModeStore from 'redux/reducers/LockedMode'
import NavigationStore from 'redux/reducers/Navigation'
import SnackbarStore from 'redux/reducers/Snackbar'

// Data platform
import CmdbTableStore from 'redux/reducers/DataPlatform/cmdb/Tables'
import CmdbRowStore from 'redux/reducers/DataPlatform/cmdb/Rows'
import CmdbApiStore from 'redux/reducers/DataPlatform/cmdb/Apis'
import DatasetStore from 'redux/reducers/DataPlatform/shapes/v2/Datasets'
import SourceStore from 'redux/reducers/DataPlatform/connections/Sources'
import SourceTableStore from 'redux/reducers/DataPlatform/connections/SourceTable'
import LogStore from 'redux/reducers/DataPlatform/connections/Logs'
import TagStore from 'redux/reducers/DataPlatform/Tags'
import DataProductStore from 'redux/reducers/DataPlatform/DataProducts'
import DatasetPreviewStore from 'redux/reducers/DataPlatform/shapes/v2/DatasetPreview'
import RuleStore from 'redux/reducers/DataPlatform/dataQuality/Rules'
import FormatStore from 'redux/reducers/DataPlatform/dataQuality/Formats'
import FlawStore from 'redux/reducers/DataPlatform/dataQuality/Flaws'
import QualityStore from 'redux/reducers/DataPlatform/dataQuality/Quality'
import VitecViewsStore from 'redux/reducers/DataPlatform/connections/VitecViews'

// Admin
import UserStore from 'redux/reducers/Admin/Users'
import RoleStore from 'redux/reducers/Admin/Roles'
import PermissionStore from 'redux/reducers/Admin/Permissions'
import SimRenamingStore from 'redux/reducers/SimRenamings'
import CompanyStore from 'redux/reducers/Companies'
import CompanyGroupStore from 'redux/reducers/CompanyGroups'
import EconomySetStore from 'redux/reducers/EconomySets'
import RealEstateStore from 'redux/reducers/RealEstates'
import AllocationKeyStore from 'redux/reducers/AllocationKeys'

// Applications
import OntologyStore from 'redux/reducers/Applications/Ontology'
import AppsDataProductStore from 'redux/reducers/Applications/DataProducts'
import AppsDatasetStore from 'redux/reducers/Applications/Datasets'
import DashboardStore from 'redux/reducers/Applications/Dashboard'
import DashboardTabStore from 'redux/reducers/Applications/DashboardTabs'
import DashboardWidgetStore from 'redux/reducers/Applications/DashboardWidgets'
import LibraryStore from 'redux/reducers/Applications/Library'

// Dashboards
import AccountStore from 'redux/reducers/Accounts'
import KPIDashboardStore from 'redux/reducers/Dashboards'
import KPITemplateStore from 'redux/reducers/KPITemplates'
import SIMStore from 'redux/reducers/SIM'
import DashboardFilterOptionsStore from 'redux/reducers/DashboardFilterOptions'
import KpiVariableStore from 'redux/reducers/KpiVariables'
import KpiVariableOptionStore from 'redux/reducers/KpiVariableOptions'
import DashboardFolderStore from 'redux/reducers/DashboardFolders'
import DashboardExampleStore from 'redux/reducers/DashboardExamples'
import TagOptionStore from 'redux/reducers/TagOptions'
import ApiKeyStore from 'redux/reducers/ApiKeys'

// Tracking
import TrackingStore from 'redux/reducers/Tracking'

// Config
import ConfigStore from 'redux/reducers/Configs'

// Status banner
import StatusBannerStore from 'redux/reducers/StatusBanner'

// Customization
import CustomizationStore from 'redux/reducers/Customization'

// reducers for internal use
import CustomersAndLicensesStore from 'redux/reducers/internal/OurCustomers'
import InternalStatusBannerStore from 'redux/reducers/internal/StatusBanner'
import InternalSimFlawStore from 'redux/reducers/internal/SimFlaws'
import InviteMessageStore from 'redux/reducers/internal/InviteMessages'
import SurveyStore from 'redux/reducers/internal/Surveys'

import NavigationMenuStore from 'redux/reducers/NavigationMenu'

/* Fake feature for sales and review */
import WidgetNotifcationStore from 'redux/reducers/WidgetNotifications'

export default combineReducers({
  AllocationKeyStore,
  AuthStore,
  ApiKeyStore,
  CmdbTableStore,
  CmdbRowStore,
  CmdbApiStore,
  CompanyStore,
  CompanyGroupStore,
  CustomizationStore,
  DataProductStore,
  EconomySetStore,
  SourceStore,
  SourceTableStore,
  AlertStore,
  LockedModeStore,
  LogStore,
  TagStore,
  DatasetStore,
  DatasetPreviewStore,
  RealEstateStore,
  RuleStore,
  FormatStore,
  QualityStore,
  FlawStore,
  NavigationStore,
  UserStore,
  RoleStore,
  PermissionStore,
  OntologyStore,
  AppsDataProductStore,
  AppsDatasetStore,
  DashboardStore,
  DashboardTabStore,
  DashboardWidgetStore,
  LibraryStore,
  VitecViewsStore,
  KPIDashboardStore,
  InternalSimFlawStore,
  InternalStatusBannerStore,
  InviteMessageStore,
  AccountStore,
  SnackbarStore,
  KPITemplateStore,
  SIMStore,
  StatusBannerStore,
  TagOptionStore,
  TrackingStore,
  ConfigStore,
  CustomersAndLicensesStore,
  DashboardFilterOptionsStore,
  SimRenamingStore,
  StatusBannerStore,
  KpiVariableStore,
  KpiVariableOptionStore,
  SurveyStore,
  DashboardFolderStore,
  DashboardExampleStore,
  NavigationMenuStore,
  WidgetNotifcationStore
})
