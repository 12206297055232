import React, { useEffect, useMemo, useState } from 'react'

import AdvancedDialog from 'components_new/molecules/AdvancedDialog'
import LoadingSection from 'components_new/molecules/LoadingSection'
import VirtualizedTable from 'components_new/molecules/VirtualizedTable'
import Button from 'components_new/atoms/Button'
import { FileExtension, FilterOption, Filters, Sort } from 'utils/types'
import Icon from 'components_new/atoms/Icon'
import {
  CustomPeriodFilter,
  ParsedSegmentPath,
  SegmentPeriod,
  WidgetObject
} from 'types/GlobalWidget'
import TabSwitch from 'components_new/molecules/TabSwitch'
import { DashboardReducerType } from 'redux/reducers/Dashboards'
import { getFilterFromSegmentPaths } from 'utils/functions'

interface DetailsProps {
  open: boolean
  onClose: () => void
  details: DashboardReducerType['details']
  getDetails: (
    kpiOptionId: string,
    offset: number,
    sort: Sort | null,
    filters: Filters,
    customPeriodFilter?: CustomPeriodFilter
  ) => void
  exportDetails: (
    kpiOptionId: string,
    extension: FileExtension,
    sort: Sort | null,
    filters: Filters,
    customPeriodFilter?: CustomPeriodFilter
  ) => void
  filterValueOptions: {
    [attribute_option_id: string]: FilterOption
  }
  segmentPaths: ParsedSegmentPath[]
  widget: WidgetObject
}

const Details = (props: DetailsProps) => {
  const {
    open,
    onClose,
    details,
    getDetails,
    exportDetails,
    filterValueOptions,
    segmentPaths,
    widget
  } = props

  const kpis = useMemo(() => {
    return widget.settings.kpi_options.map((item) => ({
      id: item.id,
      index: item.index,
      title: item.title,
      disabled: !item.can_show_details
    }))
  }, [widget.settings.kpi_options])

  const [selectedTab, setSelectedTab] = useState<string>(
    kpis.find((kpi) => !kpi.disabled)?.id || ''
  )

  useEffect(() => {
    setSelectedTab(kpis.find((kpi) => !kpi.disabled)?.id || '')
  }, [kpis])

  const segmentFilters = useMemo(() => {
    if (selectedTab) {
      return getFilterFromSegmentPaths(widget, segmentPaths, selectedTab)
    }

    return {}
  }, [segmentPaths, selectedTab])

  const customPeriodFilter = useMemo(() => {
    const pathWithPeriod = segmentPaths.find((item) => item.period)

    if (!pathWithPeriod) {
      return undefined
    }

    const period = pathWithPeriod.period as SegmentPeriod

    return {
      from: period.from_date,
      to: period.to_date
    }
  }, [segmentPaths, selectedTab])

  useEffect(() => {
    if (open) {
      kpis.forEach((kpi) => {
        if (!kpi.disabled) {
          getDetails(kpi.id, 0, null, segmentFilters, customPeriodFilter)
        }
      })
    }
  }, [open])

  const data = details[selectedTab]

  const [sort, setSort] = useState<Sort | null>(null)

  // Reset sorts when changing tab.
  useEffect(() => {
    setSort(null)
  }, [selectedTab])

  const showTable = Boolean(data) && !data.loading

  return (
    <AdvancedDialog
      data-openreplay-obscured
      open={open}
      onClose={() => {
        setSort(null)

        onClose()
      }}
      fullWidth
      fullHeight
      maxWidth={false}
      actions={
        <>
          <Button
            startIcon={<Icon name="FileDownloadOutlined" />}
            onClick={() =>
              exportDetails(
                selectedTab,
                FileExtension.CSV,
                sort,
                segmentFilters,
                customPeriodFilter
              )
            }
            loading={showTable && data.exporting}
            loadingTitle={'Bearbetar'}
            disabled={!showTable}
            variant="text"
          >
            Exportera som .csv
          </Button>
          <Button
            startIcon={<Icon name="FileDownloadOutlined" />}
            onClick={() =>
              exportDetails(
                selectedTab,
                FileExtension.XLSX,
                sort,
                segmentFilters,
                customPeriodFilter
              )
            }
            loading={showTable && data.exporting}
            loadingTitle={'Bearbetar'}
            disabled={!showTable}
            variant="text"
          >
            Exportera som .xlsx
          </Button>
          <Button onClick={onClose}>Stäng</Button>
        </>
      }
    >
      {kpis.length > 1 ? (
        <TabSwitch
          options={kpis.map((item) => ({
            label: item.title,
            value: item.id,
            disabled: item.disabled,
            disabledTooltip: item.disabled
              ? 'Det går inte att se underliggande data på detta nyckeltalet'
              : undefined
          }))}
          value={selectedTab}
          variant={'fullWidth'}
          onChange={(value) => setSelectedTab(value)}
          color={'secondary'}
        />
      ) : null}
      {showTable ? (
        <VirtualizedTable
          data={data.data}
          loading={data.loading}
          columns={data.headers.map((row) => ({
            id: row.id,
            dataKey: row.name,
            label: row.name,
            width: 'auto',
            filter: row.attribute_option_id
              ? filterValueOptions[row.attribute_option_id]
              : null
          }))}
          endReached={(offset) => {
            if (data.data.length % 25 === 0) {
              getDetails(
                selectedTab,
                offset,
                sort,
                segmentFilters,
                customPeriodFilter
              )
            }
          }}
          sort={sort}
          onSort={(newSort) => {
            setSort(newSort)

            getDetails(
              selectedTab,
              0,
              newSort,
              segmentFilters,
              customPeriodFilter
            )
          }}
          filters={{}}
          setFilters={() => {}}
        />
      ) : (
        <LoadingSection loading titles={['Hämtar bakomliggande data']} />
      )}
    </AdvancedDialog>
  )
}

export default Details
