import React, { ElementType, ReactNode } from 'react'
import { Select as MUISelect, SelectChangeEvent } from '@mui/material'

interface SelectProps {
  children?: ReactNode
  component?: ElementType<any>
  defaultValue?: any
  disabled?: boolean
  displayEmpty?: boolean
  elevation?: number
  label?: ReactNode
  labelId?: string
  MenuProps?: object
  multiple?: boolean
  onChange?: (event: SelectChangeEvent) => void
  onClose?: () => void
  open?: boolean
  renderValue?: (value: any) => ReactNode
  sx?: object
  value?: any
  variant?: 'filled' | 'outlined' | 'standard'
}

/**
 * The Select component is used for collecting
 * user provided information from a list of
 * options.
 */

const Select = React.forwardRef((props: SelectProps, ref: any) => (
  <MUISelect
    data-openreplay-obscured
    {...props}
    ref={ref}
    MenuProps={{ ...props.MenuProps, sx: { zIndex: 1600 } }}
  >
    {props.children}
  </MUISelect>
))

Select.displayName = 'Select'
export default Select
