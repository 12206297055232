import { createAction, createReducer } from '@reduxjs/toolkit'
import * as Types from 'redux/Types'

import { cloneDeep } from 'lodash'

import { arrayToObject } from 'helpers/Functions'
import { ApiKey } from 'types/GlobalApiKey'

export type ApiKeyBody = {
  [key: string]: ApiKey
}

export interface ApiKeyReducerType {
  data: ApiKeyBody
  fetched: boolean
  fetching: boolean
}

// Initial state
const INITIAL_STATE: ApiKeyReducerType = {
  data: {},
  fetched: false,
  fetching: false
}

// Actions
const getAllAction = createAction(Types.GET_ALL_API_KEYS)
const getAllActionSuccess = createAction<{ data: ApiKey[] }>(
  Types.GET_ALL_API_KEYS_SUCCESS
)
const getAllActionFailed = createAction(Types.GET_ALL_API_KEYS_FAILED)
const createApiKeyAction = createAction<{ data: ApiKey }>(
  Types.CREATE_IFRAME_API_KEY_SUCCESS
)
const deleteApiKeyAction = createAction<string>(Types.DELETE_API_KEY_SUCCESS)

const signOutAction = createAction(Types.SIGN_OUT)

// Reducer
const accountReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getAllAction, (state) => {
      return {
        ...state,
        fetching: true
      }
    })
    .addCase(getAllActionSuccess, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: arrayToObject(payload.data) as ApiKeyBody,
        fetched: true,
        fetching: false
      }
    })
    .addCase(getAllActionFailed, (state) => {
      return {
        ...state,
        fetching: false
      }
    })
    .addCase(createApiKeyAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.data.id]: payload.data
        }
      }
    })
    .addCase(deleteApiKeyAction, (state, action) => {
      const { payload } = action

      const newData = cloneDeep(state.data)

      if (payload in newData) {
        delete newData[payload]
      }

      return {
        ...state,
        data: newData
      }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export default accountReducer
