import React from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker'
import { pickersLayoutClasses } from '@mui/x-date-pickers/PickersLayout'
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'

import Dayjs from 'dayjs'
import 'dayjs/locale/sv'
import { SxProps } from '@mui/material'

interface DatePickerProps {
  clearable?: boolean
  label?: string
  maxDate?: string
  minDate?: string
  onChange: (value: string | null) => void
  value: string | null
  views: Array<'day' | 'month' | 'year'>
  size?: 'small' | 'medium'
  sx?: SxProps
  popperSx?: SxProps
}

function ActionBar(props: PickersActionBarProps) {
  const { className, onClear, ref } = props

  return (
    <Box className={className} ref={ref}>
      <Button variant="text" onClick={onClear}>
        Rensa
      </Button>
    </Box>
  )
}

const DatePicker = React.forwardRef((props: DatePickerProps) => {
  const {
    clearable = false,
    label,
    maxDate,
    minDate,
    onChange,
    value,
    size = 'small',
    popperSx,
    ...rest
  } = props

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'sv'}>
      <MUIDatePicker
        {...rest}
        label={label}
        onChange={onChange}
        // TODO: weird typing
        value={value ? (Dayjs(value) as unknown as string) : null}
        minDate={minDate ? (Dayjs(minDate) as unknown as string) : undefined}
        maxDate={maxDate ? (Dayjs(maxDate) as unknown as string) : undefined}
        slotProps={{
          popper: {
            sx: popperSx
          },
          textField: { size },
          layout: {
            sx: {
              [`.${pickersLayoutClasses.actionBar}`]: {
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'end',
                mb: 1
              }
            }
          }
        }}
        slots={
          clearable
            ? {
                actionBar: (slotsProps) => (
                  <ActionBar {...slotsProps} onClear={() => onChange(null)} />
                )
              }
            : undefined
        }
      />
    </LocalizationProvider>
  )
})

DatePicker.displayName = 'DatePicker'
export default DatePicker
