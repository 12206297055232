import React, { useState } from 'react'
import { cloneDeep } from 'lodash'

import {
  Condition,
  FilterConditionFormatBody,
  FilterPatchBody,
  FilterType
} from 'types/GlobalKpiOption'
import { KpiTemplateFilterOptionsBody } from 'types/GlobalKpiTemplates'

import { translateFilterCondition } from 'utils/enumTranslator'

import { Option } from 'components_new/molecules/SelectSearch'
import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'
import SettingsSelectDialog from 'components_new/molecules/SettingsSelectDialog'

import {
  formatUpdateFilterBody,
  getFilterBody,
  getFilterTitle,
  getFilterValue,
  getFilterValueOptions,
  parseSingleFilterLabel,
  parseSingleFilterValue
} from '../utils'
import SettingsEditDialog from 'components_new/molecules/SettingsEditDialog'

interface FilterEditItemProps {
  allFilters: FilterConditionFormatBody[] // all filters in order to add,
  // delete or edit.
  filter: FilterConditionFormatBody
  filterOptions: KpiTemplateFilterOptionsBody | null
  noHelperText?: boolean
  updateFilters: (filters: FilterPatchBody[]) => void
}

const FilterEditItem = (props: FilterEditItemProps) => {
  const { allFilters, filter, filterOptions, noHelperText, updateFilters } =
    props

  const filterAttributeOptions = filter.attribute_option.options
  const filterValueOptions = getFilterValueOptions(filterOptions, allFilters)

  const attribute_option =
    filterAttributeOptions.find(
      (option) => filter?.attribute_option.selected === option.id
    ) ?? null

  if (!attribute_option) return null

  /*-- dialogs --*/
  const [dialog, setDialog] = useState<{
    open: boolean
    title: string
    data: {
      options: Option[]
      multiValue: Option[]
      numericValue: string | null
    }
  }>({
    open: false,
    title: '',
    data: {
      options: [],
      multiValue: [],
      numericValue: null
    }
  })

  const handleCloseDialog = () => {
    setDialog((prevState) => ({ ...prevState, open: false }))
  }

  const handleOpenDialog = () => {
    const value = filter.value.selected
    let multiValue: Option[] = []
    let numericValue = null

    if (!filter.attribute_option.selected) return

    const options = cloneDeep(
      filterValueOptions[filter.attribute_option.selected]?.values || []
    ).map((option) => {
      return {
        id: parseSingleFilterValue(option),
        label: parseSingleFilterLabel(option)
      }
    })

    if (value && typeof value === 'object' && value.length > 0) {
      multiValue = value.map((option) => {
        return {
          id: parseSingleFilterValue(option),
          label: parseSingleFilterLabel(option)
        }
      })
    }

    if (value === null) {
      multiValue = [
        {
          id: parseSingleFilterValue(value),
          label: parseSingleFilterLabel(value)
        }
      ]
    }

    if (value && typeof value === 'number') {
      numericValue = value?.toString() ?? ''
      multiValue = []
    }

    setDialog({
      open: true,
      title: '',
      data: {
        options: options,
        multiValue: multiValue,
        numericValue: numericValue
      }
    })
  }

  const handleEditMultiValueDialogValue = (options: Option[]) => {
    setDialog((prevState) => ({
      ...prevState,
      data: { ...prevState.data, multiValue: options }
    }))
  }

  const handleEditNumericDialogValue = (value: string) => {
    setDialog((prevState) => ({
      ...prevState,
      data: { ...prevState.data, numericValue: value }
    }))
  }

  const handleSubmitDialog = () => {
    const editedFilters: FilterPatchBody[] = []

    if (dialog.data.multiValue && dialog.data.multiValue.length > 0) {
      dialog.data.multiValue?.forEach((v) =>
        editedFilters.push({
          filter_group_id: filter.filter_group_id,
          attribute_option_id: filter.attribute_option.selected as string,
          condition: filter.condition.selected as Condition,
          value: v.id,
          type: filter.type.selected as FilterType
        })
      )
    }

    if (dialog.data.numericValue !== null) {
      editedFilters.push({
        filter_group_id: filter.filter_group_id,
        attribute_option_id: filter.attribute_option.selected as string,
        condition: filter.condition.selected as Condition,
        value: dialog.data.numericValue,
        type: filter.type.selected as FilterType,
        from_value: filter.from_value?.selected ?? '',
        to_value: filter.to_value?.selected ?? ''
      })
    }

    const currentFilters = allFilters.filter(
      (f) => f.attribute_option.selected !== filter.attribute_option.selected
    )

    updateFilters([...formatUpdateFilterBody(currentFilters), ...editedFilters])
  }

  return (
    <>
      <SettingsGroup
        helperText={
          noHelperText
            ? undefined
            : 'Du kan redigera värdena för ett filter. Ifall du däremot vill ändra attribut eller villkor behöver du radera filtret och skapa ett nytt.'
        }
      >
        <SettingsItem
          sx={{ cursor: 'not-allowed' }}
          body={getFilterBody(filter) ?? undefined}
          title={getFilterTitle(filter)}
          value={
            filter.condition.selected
              ? translateFilterCondition[filter.condition.selected].title
              : ''
          }
        />
        {filter.condition.selected &&
        [Condition.EQ, Condition.NE, Condition.GT, Condition.LT].includes(
          filter.condition.selected
        ) ? (
              <SettingsItem
                onClick={handleOpenDialog}
                title={getFilterValue(filter)}
                variant="edit"
              />
            ) : null}
      </SettingsGroup>

      {/* if multi value */}
      <SettingsSelectDialog
        onChange={(params) => handleEditMultiValueDialogValue(params)}
        onClose={handleCloseDialog}
        onSubmit={handleSubmitDialog}
        open={!!(dialog.open && dialog.data.multiValue)}
        options={dialog.data.options}
        title={dialog.title}
        value={dialog.data.multiValue}
      />

      {/* if numeric value */}
      <SettingsEditDialog
        onChange={handleEditNumericDialogValue}
        onClose={handleCloseDialog}
        onSubmit={handleSubmitDialog}
        open={dialog.open && dialog.data.numericValue !== null}
        required={true}
        title={dialog.title}
        type="number"
        value={dialog.data.numericValue as string}
      />
    </>
  )
}

export default FilterEditItem
