import React, { FC, useEffect } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'

import LoadingSettings from 'components_new/organisms/pageLoaders/LoadingSettings'

import ApiKeyTemplate from 'components_new/templates/settings/ApiKeys'

import * as ApiKeyActions from 'redux/actions/ApiKeys'

import { ApplicationState } from 'redux/Stores/types'
import SettingsTemplate from 'components_new/templates/SettingsTemplate'

const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  ApiKeyStore: state.ApiKeyStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...ApiKeyActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ApiKeyPageProps = ConnectedProps<typeof connector>

const ApiKeyPage: FC<ApiKeyPageProps> = (props: ApiKeyPageProps) => {
  const { ApiKeyStore, tryGetAllApiKeys, tryCreateApiKey, tryDeleteApiKey } =
    props

  useEffect(() => {
    if (!ApiKeyStore.fetched && !ApiKeyStore.fetching) {
      tryGetAllApiKeys()
    }
  }, [])

  return (
    <SettingsTemplate>
      {ApiKeyStore.fetched ? (
        <ApiKeyTemplate
          apiKeys={Object.values(ApiKeyStore.data)}
          createApiKey={tryCreateApiKey}
          deleteApiKey={tryDeleteApiKey}
        />
      ) : (
        <LoadingSettings
          titles={['Hämtar din organisations nycklar', 'Vässar pennan']}
        />
      )}
    </SettingsTemplate>
  )
}

export default connector(ApiKeyPage)
