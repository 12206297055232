import { Theme } from '@mui/material'
import { ChartOptions } from 'chart.js'
import {
  formatAxisValue,
  getBorderDash,
  getBorderWidth,
  getColor,
  getColorIndexAndAlpha,
  getLegendStyles,
  getThresholdLines,
  getTooltipLabel,
  isForecast
} from '../utils'

import { FormattedWidgetData, WidgetObject } from 'types/GlobalWidget'

type LineChartOptions = ChartOptions<'line'> & {
  plugins: {
    horizontalLines: { lines: { value: number; color: string }[] }
  }
}

export const getOptions = (
  widget: WidgetObject,
  hasBreakdown: boolean,
  theme: Theme,
  formattedData: FormattedWidgetData,
  scaleFactor: number,
  allowOnClick: boolean
): LineChartOptions => {
  // Widget settings
  const invertYAxis = widget.settings.invert_y_axis
  const maxValue = widget.settings.max_value ?? undefined
  const minValue = widget.settings.min_value ?? undefined
  const labelChars = widget.settings.label_chars

  // Main dataset settings
  const mainDataset = formattedData.datasets.find(
    (item) => item.settingIndex === 0
  )
  const mainDatasetUnit = mainDataset?.unit || null

  return {
    onHover: (event, elements) => {
      const element = event.native?.target as HTMLElement

      element.style.cursor =
        elements.length === 1 && allowOnClick ? 'pointer' : 'default'
    },
    plugins: {
      legend: {
        display: formattedData.datasets.length > 1,
        ...getLegendStyles(scaleFactor, theme, 'line')
      },
      horizontalLines: getThresholdLines(theme, widget),
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (item) => getTooltipLabel(item, hasBreakdown)
        }
      }
    },
    elements: {
      line: {
        borderWidth() {
          return getBorderWidth(scaleFactor)
        }
      },
      point: {
        radius: 0,
        hoverRadius() {
          return 7 * scaleFactor
        },
        hoverBorderWidth: 0
      }
    },
    interaction: {
      intersect: false,
      mode: 'index', // nearest?
      axis: 'x'
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        border: {
          display: false
        },
        ticks: {
          callback: function (this, value): string {
            const label =
              typeof value === 'number' ? this.getLabelForValue(value) : ''

            if (!!labelChars && label.length > labelChars) {
              return `${label.substring(0, labelChars)}...`
            }

            return label
          },
          font: {
            size() {
              return 16 * scaleFactor
            }
          },
          color: theme.palette.text.secondary
        }
      },
      y: {
        reverse: invertYAxis,
        display: true,
        grid: {
          color: theme.palette.divider
        },
        border: {
          display: false
        },
        max: maxValue ?? undefined,
        min: minValue ?? undefined,
        ticks: {
          callback: function (value, index, ticks) {
            if (index === ticks.length - 1 && mainDatasetUnit) {
              return mainDatasetUnit
            } else {
              return formatAxisValue(Number(value), null)
            }
          },
          font: {
            size() {
              return 16 * scaleFactor
            }
          },
          color: theme.palette.text.secondary
        }
      }
    },
    maintainAspectRatio: false,
    responsive: true
  }
}

export const getLines = (
  theme: Theme,
  formattedData: FormattedWidgetData,
  scaleFactor: number
) => {
  return {
    labels: formattedData.labels.map((item) => item.display_label),
    dataLabels: formattedData.labels,
    datasets: formattedData.datasets.map((item, i) => {
      const getColors = (): string[] => {
        return item.data.map((value) => {
          const colorSpecs = getColorIndexAndAlpha(
            item.showComparativeValue,
            item.isComparativeData,
            i,
            item.settingIndex
          )

          return getColor(
            value as number,
            colorSpecs.index,
            theme,
            item.increaseIsPositive,
            item.upperBoundThreshold,
            item.lowerBoundThreshold,
            colorSpecs.alpha
          )
        })
      }

      return {
        label: item.label,
        data: item.data,
        prettyData: item.prettyData,
        increaseIsPositive: item.increaseIsPositive,
        unit: item.unit,
        segment: {
          borderDash: (ctx: any) => {
            const forecast = isForecast(
              formattedData.labels,
              ctx.p1DataIndex,
              true,
              item.isComparativeData
            )

            if (forecast) {
              return getBorderDash(scaleFactor)
            }

            return undefined
          }
        },
        borderColor: getColors(),
        backgroundColor: getColors()
      }
    })
  }
}
