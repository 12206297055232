import React, { useEffect, useMemo, useState } from 'react'

import {
  CustomPeriodFilter,
  DatasetType,
  FormattedWidgetData,
  ParsedSegmentPath,
  PeriodFilter,
  WidgetObject
} from 'types/GlobalWidget'
import { suppressResizeObserver } from '../../types/Table/utils'

import {
  ScrollerProps,
  TableBodyProps,
  TableComponents,
  TableVirtuoso
} from 'react-virtuoso'

import Box from 'components_new/atoms/Box'
import Breadcrumbs from 'components_new/atoms/Breadcrumbs'
import Button from 'components_new/atoms/Button'
import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Link from 'components_new/atoms/Link'
import Table from 'components_new/atoms/Table'
import TableBody from 'components_new/atoms/Table/TableBody'
import TableContainer from 'components_new/atoms/Table/TableContainer'
import TableHead from 'components_new/atoms/Table/TableHead'
import TableRow from 'components_new/atoms/Table/TableRow'
import Text from 'components_new/atoms/Text'

import LoadingSection from 'components_new/molecules/LoadingSection'
import AdvancedDialog from 'components_new/molecules/AdvancedDialog'

import {
  formatDataset,
  parseLabel,
  parseWidgetDataLabel
} from 'components_new/organisms/Dashboard/utils'

import DateTag from 'components_new/molecules/DateTag'

import BodyCell from './BodyCell'
import HeadCell from './HeadCell'
import SegmentCell from './SegmentCell'

import { useTheme } from '@mui/material'

import {
  ISegmentData,
  getDateSpan,
  getHierarchyForSegment,
  parseExploreQueryParams
} from './utils'
import { getOneFromHierarchy } from 'redux/api/Widgets'
import { DashboardFilter } from 'types/GlobalDashboardFilter'
import { Hierarchy, KpiOptionObject } from 'types/GlobalKpiOption'
import { INITIAL_EXPLORE_DIALOG } from '../..'
import { LOADING_WIDGET_TITLES } from 'utils/loadingTitles'
import { getExploreLabel } from 'utils/texts'

export type ExploreDialogType = {
  customPeriod?: {
    fromDate: string
    toDate: string
  }
  segments: ParsedSegmentPath[]
  open: boolean
  hierarchy: Hierarchy[]
  breakdownFilterValue?: string | number | null
  breakdownRelationKey?: string
  level: number
  kpiOptionId: string | null
  showAll?: boolean
}

interface ExploreDialogProps {
  dashboardFilter: DashboardFilter
  exploreDialog: ExploreDialogType
  periodFilter: PeriodFilter | CustomPeriodFilter | null
  setExploreDialog: React.Dispatch<React.SetStateAction<ExploreDialogType>>
  showAs: string | null
  widget: WidgetObject
}

const getUniqueDataKey = (exploreDialog: ExploreDialogType) => {
  const segmentPart = exploreDialog.segments
    .map((segment) => `${segment.label}`)
    .join('')

  return `${exploreDialog.kpiOptionId}-${segmentPart}-${exploreDialog.breakdownFilterValue}`
}

const ExploreDialog = (props: ExploreDialogProps) => {
  const {
    dashboardFilter,
    exploreDialog,
    periodFilter,
    setExploreDialog,
    showAs,
    widget
  } = props

  const [data, setData] = useState<{
    [key: string]: FormattedWidgetData
  }>({})
  const [loading, setLoading] = useState(false)
  const theme = useTheme()

  const key = getUniqueDataKey(exploreDialog)

  const kpiOption = useMemo(() => {
    if (exploreDialog.open) {
      const kpiOption = widget.settings.kpi_options.find(
        (ko) => ko.id === exploreDialog.kpiOptionId
      ) as KpiOptionObject

      return kpiOption
    }

    return null
  }, [exploreDialog.open])

  const currentLevel =
    exploreDialog.hierarchy.length > 0 ? exploreDialog.hierarchy[0] : null
  const dateSpan = getDateSpan(exploreDialog, widget)

  const isDeepestLevel = useMemo(() => {
    if (currentLevel?.attribute_option_id) {
      return (
        currentLevel.attribute_option_id ===
        exploreDialog.hierarchy[exploreDialog.hierarchy.length - 1]
          .attribute_option_id
      )
    }

    return false
  }, [currentLevel?.attribute_option_id])

  useEffect(() => {
    const uniqueKey = getUniqueDataKey(exploreDialog)

    if (!exploreDialog.open) {
      setData({})
    }

    if (exploreDialog.open && !(uniqueKey in data)) {
      const queryParams = parseExploreQueryParams(
        exploreDialog,
        dashboardFilter,
        periodFilter,
        widget,
        showAs
      )

      setLoading(true)

      getOneFromHierarchy(
        widget.id,
        exploreDialog.kpiOptionId as string,
        currentLevel?.attribute_option_id as string,
        queryParams
      ).then((response) => {
        const datasets = response.data.data.data.map((dataset) =>
          formatDataset(dataset, widget, null)
        )

        setLoading(false)

        setData({
          ...data,
          [uniqueKey]: {
            datasets: datasets,
            labels: response.data.data.labels.map(parseWidgetDataLabel),
            links: response.data.data.external_links,
            coordinates: []
          }
        })
      })
    }
  }, [exploreDialog])

  useEffect(() => {
    window.addEventListener('error', suppressResizeObserver)
  }, [])

  return (
    <AdvancedDialog
      data-openreplay-obscured
      noGutter={true}
      disableOverflow={true}
      open={exploreDialog.open}
      onClose={() => {
        setExploreDialog(INITIAL_EXPLORE_DIALOG)
      }}
      fullWidth={true}
      fullHeight={true}
      maxWidth="xl"
      title={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {getExploreLabel(kpiOption?.title)}
          <Chip label={'Beta'} color={'info'} sx={{ ml: 1 }} />
        </Box>
      }
      actions={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            fontSize: 20
          }}
        >
          {dateSpan.from ? (
            <DateTag fromDate={dateSpan.from} toDate={dateSpan.to} />
          ) : null}
          <Button onClick={() => setExploreDialog(INITIAL_EXPLORE_DIALOG)}>
            Stäng
          </Button>
        </Box>
      }
      hero={
        <Box sx={{ width: '100%', px: 3, mb: 2 }}>
          <Breadcrumbs
            separator={<Icon fontSize="small" name="NavigateNextOutlined" />}
            sx={{
              '.MuiBreadcrumbs-separator': {
                mx: 0.5
              }
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {exploreDialog.showAll && exploreDialog.segments.length > 0 ? (
                <IconButton
                  size="small"
                  onClick={() => {
                    setExploreDialog({
                      open: true,
                      segments: [],
                      hierarchy: (kpiOption as KpiOptionObject).hierarchy,
                      breakdownFilterValue: exploreDialog.breakdownFilterValue,
                      breakdownRelationKey: exploreDialog.breakdownRelationKey,
                      level: 0,
                      kpiOptionId: exploreDialog.kpiOptionId,
                      showAll: exploreDialog.showAll,
                      customPeriod: exploreDialog.customPeriod
                    })
                  }}
                >
                  <Icon fontSize="small" name="SearchOutlined" />
                </IconButton>
              ) : (
                <Icon fontSize="small" name="SearchOutlined" />
              )}
            </Box>
            {exploreDialog.segments.map((item, i) =>
              exploreDialog.segments.length > i + 1 ? (
                <Link
                  underline="hover"
                  key={i}
                  color="inherit"
                  onClick={() => {
                    const newSegments = exploreDialog.segments.slice(0, i + 1)
                    const segment = newSegments[newSegments.length - 1]

                    // get attribute option id of exploring kpi option
                    const attributeOptionId =
                      segment.segment_options[
                        exploreDialog.kpiOptionId as string
                      ]

                    const newHierarchy = getHierarchyForSegment(
                      kpiOption as KpiOptionObject,
                      attributeOptionId
                    )

                    setExploreDialog({
                      open: true,
                      segments: newSegments,
                      hierarchy: newHierarchy,
                      breakdownFilterValue: exploreDialog.breakdownFilterValue,
                      breakdownRelationKey: exploreDialog.breakdownRelationKey,
                      level: i,
                      kpiOptionId: exploreDialog.kpiOptionId,
                      showAll: exploreDialog.showAll,
                      customPeriod: exploreDialog.customPeriod
                    })
                  }}
                >
                  {item.label}
                </Link>
              ) : (
                <Text key={i} color="text.primary">
                  {item.label}
                </Text>
              )
            )}
          </Breadcrumbs>
        </Box>
      }
    >
      {key in data ? (
        <TableVirtuoso
          data={data[key].labels.map((label) => ({
            label: label.display_label,
            id: label.display_label,
            technical_name: label.technical_name,
            type: label.type
          }))}
          components={VirtuosoTableComponents}
          fixedHeaderContent={() => (
            <TableRow
              sx={{
                bgcolor: theme.palette.background.widget,
                backgroundImage: `linear-gradient(${theme.palette.background.overlay}, ${theme.palette.background.overlay})`
              }}
            >
              <HeadCell
                label={currentLevel?.label ?? ''}
                type={DatasetType.STRING}
              />
              {data[key].datasets.map((dataset) => (
                <HeadCell
                  key={dataset.id}
                  label={dataset.label}
                  type={dataset.type}
                />
              ))}
            </TableRow>
          )}
          itemContent={(row, segment) => (
            <>
              <SegmentCell
                href={
                  isDeepestLevel && data[key].links[row]
                    ? data[key].links[row] ?? undefined
                    : undefined
                }
                label={segment.label}
                onClick={
                  isDeepestLevel
                    ? undefined
                    : () => {
                        const attributeOptionId =
                          currentLevel?.attribute_option_id as string
                        const kpiOptionId = exploreDialog.kpiOptionId as string

                        setExploreDialog({
                          open: true,
                          segments: [
                            ...exploreDialog.segments,
                            {
                              attribute_option_id: attributeOptionId,
                              label: segment.label,
                              segment_options: {
                                [kpiOptionId]: attributeOptionId
                              },
                              period: null,
                              type: segment.type,
                              technical_name: segment.technical_name,
                              display_label: parseLabel(segment)
                            }
                          ],
                          hierarchy: exploreDialog.hierarchy.slice(1),
                          breakdownFilterValue:
                            exploreDialog.breakdownFilterValue,
                          breakdownRelationKey:
                            exploreDialog.breakdownRelationKey,
                          level: exploreDialog.level + 1,
                          kpiOptionId: exploreDialog.kpiOptionId,
                          showAll: exploreDialog.showAll,
                          customPeriod: exploreDialog.customPeriod
                        })
                      }
                }
              />
              {data[key].datasets.map((dataset) => {
                const type = dataset.type
                const unit =
                  dataset.prettyData[row] !== null ? dataset.unit : ''
                const prettyValue = dataset.prettyData[row] ?? ''

                const label =
                  type === DatasetType.NUMBER
                    ? `${prettyValue + ' ' + unit}`
                    : prettyValue

                return <BodyCell key={dataset.id} label={label} type={type} />
              })}
            </>
          )}
        />
      ) : (
        <LoadingSection titles={LOADING_WIDGET_TITLES} loading={loading} />
      )}
    </AdvancedDialog>
  )
}

export default ExploreDialog

/* Virtuoso Table Components */

const VirtuosoTableComponents: TableComponents<ISegmentData> = {
  Scroller: React.forwardRef((props, ref) => {
    return (
      <TableContainer
        {...props}
        sx={{
          maxHeight: '100%',
          overflowX: 'auto',
          overflowAnchor: 'none' // Fix for scrolling when horizontal scroll is active.
        }}
        ref={ref}
      />
    )
  }) as React.ComponentType<
    ScrollerProps & {
      context?: any
    }
  >,
  Table: (props) => (
    <Table
      {...props}
      size="small"
      sx={{
        borderCollapse: 'separate',
        tableLayout: 'fixed',
        width: 'auto' // For auto sized, fit content cells.
      }}
    />
  ),
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )) as React.ComponentType<
    TableBodyProps & {
      context?: any
    }
  >,
  TableHead: ({ ref, ...props }) => <TableHead {...props} ref={ref} />,
  TableRow: React.forwardRef((props, ref) => (
    <TableRow {...props} hover={true} lastRowHasBorder={true} ref={ref} />
  ))
}
