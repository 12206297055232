import React from 'react'

import { Color } from 'themes'

import Box from 'components_new/atoms/Box'
import Card from 'components_new/atoms/Card'
import CardActionArea from 'components_new/atoms/Card/CardActionArea'
import Icon, { IconNames } from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'

interface CardButtonProps {
  body: string
  color?:
    | Color
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'brand'
    | 'black'
    | 'white'
    | 'neutral'
  iconName: IconNames
  onClick: () => void
  sx?: object
  title: string
  variant?: 'elevation' | 'outlined'
}

const CardButton = (props: CardButtonProps) => {
  const { body, color, iconName, onClick, sx, title, variant } = props

  return (
    <Card
      sx={{
        bgcolor: variant === 'outlined' ? 'transparent' : `${color}.main`,
        color: variant === 'outlined' ? undefined : `${color}.contrastText`,
        '&:hover': {
          '& .ArrowForward': {
            transform: 'translateX(4px)'
          }
        },
        ...sx
      }}
      variant={variant}
    >
      <CardActionArea onClick={onClick} sx={{ p: 2 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            mb: 0.75
          }}
        >
          <Icon name={iconName} sx={{ color: 'inherit' }} />
          <Text color="inherit" variant="h6">
            {title}
          </Text>
          <Icon
            className="ArrowForward"
            name="ArrowForward"
            sx={{
              color: 'inherit',
              transform: 'translateX(0px)',
              transition: 'all 0.15s ease-in-out'
            }}
          />
        </Box>
        <Text color="inherit" component="div" variant="body2">
          {body}
        </Text>
      </CardActionArea>
    </Card>
  )
}

export default CardButton
