import React from 'react'

import Box from 'components_new/atoms/Box'
import Text from 'components_new/atoms/Text'

import UserListItem from 'components_new/molecules/UserListItem'

interface AuthorProps {
  createdAt: string
  sx?: object
}

const Author = (props: AuthorProps) => {
  const { createdAt, sx } = props

  return (
    <Box sx={sx}>
      <UserListItem
        title="Skapad av Homepal"
        body={new Date(createdAt).toLocaleDateString('sv')}
        type="homepal"
      />

      <Text color="text.secondary" variant="body2">
        Den här dashboarden är skapad och anpassad av oss på Homepal med vår
        påhittade demodata. Konceptet och idén kommer från en av våra kunder.
      </Text>
    </Box>
  )
}

export default Author
