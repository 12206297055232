import React, { useState } from 'react'

import {
  InnerWidgetPatchBody,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'

import Switch from 'components_new/atoms/Switch'

import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'
import SettingsDrawer from 'components_new/molecules/SettingsDrawer'
import SettingsEditDialog from 'components_new/molecules/SettingsEditDialog'

interface TitleSectionProps {
  onClose: () => void
  updateWidget: (arg0: string, arg1: InnerWidgetPatchBody) => void
  widget: WidgetObject
}

const LEGAL_TYPES = [
  WidgetType.BAR_CHART,
  WidgetType.BUBBLE_CHART,
  WidgetType.COMBO_CHART,
  WidgetType.FUNNEL,
  WidgetType.KEY_FIGURE,
  WidgetType.LINE_CHART,
  WidgetType.LIST,
  WidgetType.MAP,
  WidgetType.PIE_CHART
]

const TitleSection = (props: TitleSectionProps) => {
  const { onClose, updateWidget, widget } = props

  if (!LEGAL_TYPES.includes(widget.settings.type.selected)) {
    return null
  }

  /*-- drawer --*/
  const [drawer, setDrawer] = useState<{
    open: boolean
    title: string
    data: {
      property: 'title' | 'subtitle'
      text: string
      visible: boolean
    }
  }>({
    open: false,
    title: '',
    data: { property: 'title', text: '', visible: true }
  })

  const handleBackDrawer = () => {
    setDrawer((prevState) => ({ ...prevState, open: false }))
  }

  const handleCloseDrawer = () => {
    handleBackDrawer()
    onClose()
  }

  const handleOpenDrawer = (property: 'subtitle' | 'title') => {
    const title = property === 'title' ? 'Rubrik' : 'Underrubrik'
    const text = property === 'title' ? widget.title : widget.settings.subtitle
    const visible =
      property === 'title'
        ? widget.settings.show_title
        : widget.settings.show_subtitle

    setDrawer({
      open: true,
      title: title,
      data: { property: property, text: text, visible: visible }
    })
  }

  /*-- toggle --*/
  const handleToggle = (property: 'subtitle' | 'title') => {
    if (property === 'title') {
      updateWidget(widget.id, {
        show_title: !widget.settings.show_title
      })
    } else {
      updateWidget(widget.id, {
        show_subtitle: !widget.settings.show_subtitle
      })
    }
  }

  /*-- dialog --*/
  const [dialog, setDialog] = useState<{
    open: boolean
    title: string
    data: {
      property: 'title' | 'subtitle'
      value: string
    }
  }>({
    open: false,
    title: '',
    data: { property: 'title', value: '' }
  })

  const handleOpenDialog = (property: 'subtitle' | 'title') => {
    const title = property === 'title' ? 'Rubrik' : 'Underrubrik'
    const text = property === 'title' ? widget.title : widget.settings.subtitle

    setDialog({
      open: true,
      title: title,
      data: { property: property, value: text }
    })
  }

  const handleCloseDialog = () => {
    setDialog((prevState) => ({ ...prevState, open: false }))
  }

  const handleEditDialogValue = (value: string) => {
    setDialog((prevState) => ({
      ...prevState,
      data: { ...prevState.data, value }
    }))
  }

  const handleSubmitDialog = () => {
    updateWidget(widget.id, { [dialog.data.property]: dialog.data.value })
    handleCloseDialog()
  }

  return (
    <>
      <SettingsGroup title="Rubriker">
        <SettingsItem
          onClick={() => handleOpenDrawer('title')}
          title="Rubrik"
          value={widget.title}
        />
        <SettingsItem
          onClick={() => handleOpenDrawer('subtitle')}
          title="Underrubrik"
          value={widget.settings.subtitle}
        />
      </SettingsGroup>

      {/*-- drawer --*/}
      <SettingsDrawer
        open={drawer.open}
        onBack={handleBackDrawer}
        onClose={handleCloseDrawer}
        title={drawer.title}
      >
        <SettingsGroup>
          <SettingsItem
            actions={
              <Switch
                checked={
                  drawer.data.property === 'title'
                    ? widget.settings.show_title
                    : widget.settings.show_subtitle
                }
                onChange={() => handleToggle(drawer.data.property)}
                size="small"
              />
            }
            title="Visa"
          />
          <SettingsItem
            onClick={() => handleOpenDialog(drawer.data.property)}
            title="Text"
            value={
              drawer.data.property === 'title'
                ? widget.title
                : widget.settings.subtitle
            }
            variant="edit"
          />
        </SettingsGroup>
      </SettingsDrawer>

      {/*-- dialog --*/}
      <SettingsEditDialog
        onChange={handleEditDialogValue}
        onClose={handleCloseDialog}
        onSubmit={handleSubmitDialog}
        open={dialog.open}
        title={dialog.title}
        type={'text'}
        value={dialog.data.value}
      />
    </>
  )
}

export default TitleSection
