import React, { ReactNode, useEffect, useRef, useState } from 'react'

import Grid from 'components_new/atoms/Grid'
import Box from 'components_new/atoms/Box'

interface ThumbnailListProps {
  children: ReactNode[]
  columns?: number
  itemSize?: {
    xs?: number
    md?: number
    lg?: number
    xl?: number
  }
  sx?: object
}

/**
 * The ThumbnailList component is used
 * to display several thumbnails on a
 * responsive way.
 */

const ThumbnailList = (props: ThumbnailListProps) => {
  const { children, columns, itemSize, sx } = props

  const ref = useRef<HTMLElement>(null)

  const [scrollTop, setScrollTop] = useState(true)

  useEffect(() => {
    const element = ref.current
    const handleScroll = () => {
      if (element && element.scrollTop === 0) {
        setScrollTop(true)
      } else {
        setScrollTop(false)
      }
    }

    if (element) {
      element.addEventListener('scroll', handleScroll)

      // Clean-up
      return () => {
        element.removeEventListener('scroll', handleScroll)
      }
    }

    return
  }, [])

  return (
    <>
      <Box
        ref={ref}
        sx={{
          width: '100%',
          overflowY: 'auto',
          borderTop: scrollTop ? undefined : '1px solid',
          borderTopColor: 'divider',
          pt: 2,
          ...sx
        }}
      >
        <Grid container columns={columns} spacing={2}>
          {children.map((thumbnail, i) => (
            <Grid
              item
              xs={itemSize?.xs ?? 6}
              md={itemSize?.md ?? 4}
              lg={itemSize?.lg ?? 3}
              xl={itemSize?.xl ?? 2}
              key={i}
            >
              {thumbnail}
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  )
}

export default ThumbnailList
