import { createAction, createReducer } from '@reduxjs/toolkit'

import * as Types from 'redux/Types'
import { IDashboardExample } from 'types/GlobalDashboardExample'

interface DashboardBody {
  [dashboardId: string]: IDashboardExample
}

export interface DashboardExampleReducerType {
  data: DashboardBody
  fetching: boolean
  fetched: boolean
}

// Initial state
const INITIAL_STATE: DashboardExampleReducerType = {
  data: {},
  fetching: false,
  fetched: false
}

// Actions
const getAllAction = createAction(Types.GET_DASHBOARD_EXAMPLES)
const getAllSuccessAction = createAction<{
  data: IDashboardExample[]
}>(Types.GET_DASHBOARD_EXAMPLES_SUCCESS)
const getAllFailedAction = createAction(Types.GET_DASHBOARD_EXAMPLES_FAILED)

const signOutAction = createAction(Types.SIGN_OUT)

// Reducer
const dashboardReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getAllAction, (state) => {
      return {
        ...state,
        fetching: true
      }
    })
    .addCase(getAllSuccessAction, (state, action) => {
      const { payload } = action

      const data: DashboardBody = {}

      payload.data.forEach((row) => {
        data[row.fields.id] = row
      })

      return {
        data,
        fetching: false,
        fetched: true
      }
    })
    .addCase(getAllFailedAction, (state) => {
      return {
        ...state,
        fetching: false
      }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export default dashboardReducer
