import React, { ReactNode } from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Drawer from 'components_new/atoms/Drawer'
import Icon from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'

interface SettingsDrawerProps {
  backdrop?: boolean
  backLabel?: string
  children: ReactNode
  onBack: () => void
  onClose: () => void
  open: boolean
  title: string
}

// const since this is used elsewhere
export const SETTINGS_DRAWER_SX = {
  maxWidth: 500,
  minWidth: 400,
  width: '25vw',
  zIndex: 1301 // for fullSize
}

const SettingsDrawer = (props: SettingsDrawerProps) => {
  const { backdrop, backLabel, children, onBack, onClose, open, title } = props

  const theme = getTheme('light')

  return (
    <ThemeProvider theme={theme}>
      <Drawer
        anchor="right"
        elevation={0}
        open={open}
        onClose={onClose}
        onMouseDown={(event) => event.stopPropagation()}
        sx={{
          zIndex: SETTINGS_DRAWER_SX.zIndex, // for fullSize
          ...(!backdrop
            ? {
                '.MuiModal-backdrop': {
                  background: 'none'
                }
              }
            : undefined)
        }}
      >
        <Box
          sx={{
            maxWidth: SETTINGS_DRAWER_SX.maxWidth,
            minWidth: SETTINGS_DRAWER_SX.minWidth,
            width: SETTINGS_DRAWER_SX.width,
            minHeight: '100vh',
            overflowY: 'auto',
            position: 'relative',
            bgcolor:
              theme.palette.mode === 'dark' ? 'background.default' : 'grey.100',
            borderLeft: '1px solid',
            borderColor: 'divider'
          }}
        >
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              display: 'flex',
              gap: 0.5,
              justifyContent: 'center',
              bgcolor: 'background.paper',
              p: 1,
              zIndex: 100,
              borderBottom: '1px solid',
              borderColor: 'divider',
              minHeight: 45
            }}
          >
            <Box sx={{ flex: '1 0 auto' }}>
              <Button
                color="brand"
                onClick={onBack}
                size="small"
                startIcon={<Icon name="KeyboardArrowLeft" />}
                sx={{
                  position: 'absolute',
                  left: '8px',
                  '& .MuiButton-startIcon': { mr: 0 }
                }}
                variant="text"
              >
                {backLabel ?? 'Tillbaka'}
              </Button>
            </Box>
            <Box sx={{ width: 'calc(100% - 160px)', minWidth: 0 }}>
              <Text align="center" noWrap={true} variant="subtitle1">
                {title}
              </Text>
            </Box>
            <Box sx={{ flex: '1 0 auto' }} />
          </Box>
          <Box sx={{ p: 1 }}>{children}</Box>
        </Box>
      </Drawer>
    </ThemeProvider>
  )
}

export default SettingsDrawer
