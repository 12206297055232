import React, { useState, useEffect, useRef } from 'react'

import { cloneDeep } from 'lodash'

import LoadingSection from 'components_new/molecules/LoadingSection'
import Table from 'components_new/atoms/Table'
import TableBody from 'components_new/atoms/Table/TableBody'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableHead from 'components_new/atoms/Table/TableHead'
import TableRow from 'components_new/atoms/Table/TableRow'
import TableSortLabel from 'components_new/atoms/Table/TableSortLabel'
import TableFooter from 'components_new/atoms/Table/TableFooter'
import Box from 'components_new/atoms/Box'
import Badge from 'components_new/atoms/Badge'

import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'

import { TableVirtuoso, TableComponents, TableBodyProps } from 'react-virtuoso'
import { Order, Sort, FilterOption, Filters } from 'utils/types'
import FilterCascadeMenu from 'components_new/organisms/FilterCascadeMenu'
import Stack from 'components_new/atoms/Stack'
import { Condition } from 'types/GlobalKpiOption'

interface Data {
  [key: string]: string | number | null
}

type OnSort = (sort: Sort) => void

export interface ColumnData {
  id: string
  dataKey: string
  label: string
  numeric?: boolean
  width: 'auto' | number
  filter?: FilterOption | null
  activeFilter?: boolean
}

const VirtuosoTableComponents: TableComponents<Data> = {
  Table: (props: any) => (
    <Table {...props} sx={{ borderCollapse: 'separate' }} size={'small'} />
  ),
  TableHead: ({ ref, ...props }) => <TableHead {...props} ref={ref} />,
  TableRow: (props: any) => <TableRow {...props} />,
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )) as React.ComponentType<
    TableBodyProps & {
      context?: any
    }
  >,
  TableFoot: ({ ref, ...props }) => (
    <TableFooter {...props} ref={ref} style={{ position: 'relative' }} />
  )
}

/**
 * Calculate width of column based on options and char length
 */
const calculateWidth = (column: ColumnData) => {
  const charactersWidth = column.label.length * 6
  const sortIconWidth = 24
  const filterIconWidth = column.filter ? 36 : 0

  return charactersWidth + sortIconWidth + filterIconWidth
}

interface TableHeaderProps {
  column: ColumnData
  active: boolean
  direction: Order
  onSort?: (column: string) => void
  setFilters: (filters: Filters) => void
  filters: Filters
}

const TableHeader: React.FC<TableHeaderProps> = (props: TableHeaderProps) => {
  const { column, active, direction, onSort, setFilters, filters } = props

  const filterCascadeMenuRef = useRef(null)
  const [filterOpen, setFilterOpen] = useState(false)

  const handleClick = (event: any) => {
    event.stopPropagation()
    event.preventDefault()
    setFilterOpen(true)
  }

  return (
    <TableCell
      key={column.dataKey}
      variant={'head'}
      align={column.numeric || false ? 'right' : 'left'}
      sx={{
        backgroundColor: 'background.paper',
        minWidth:
          column.width === 'auto' ? calculateWidth(column) : column.width
      }}
    >
      <Stack direction="row">
        {onSort ? (
          <TableSortLabel
            onClick={() => onSort(column.dataKey)}
            active={active}
            direction={direction}
            sx={{
              // put sort arrow on left side
              flexDirection: 'row-reverse',
              whiteSpace: 'nowrap'
            }}
          >
            {column.label}
          </TableSortLabel>
        ) : (
          column.label
        )}
        {column.filter ? (
          <IconButton onClick={handleClick} ref={filterCascadeMenuRef}>
            <Badge
              color="primary"
              variant="dot"
              invisible={!column.activeFilter}
            >
              <Icon fontSize="small" name="FilterAltOutlined" />
            </Badge>
          </IconButton>
        ) : null}

        <FilterCascadeMenu
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          anchorEl={filterCascadeMenuRef.current}
          filterConditionOptions={[
            Condition.IS_NULL,
            Condition.IS_NOT_NULL,
            Condition.EQ,
            Condition.NE
          ]}
          filter={filters[column.id] ?? null}
          options={column.filter ? column.filter.values : undefined}
          setFilter={(filter) => {
            const newFilters = cloneDeep(filters)

            if (!filter) {
              delete newFilters[column.id]
            } else {
              newFilters[column.id] = {
                attributeId: column.id,
                ...filter
              }
            }
            setFilters(newFilters)
          }}
          attributeName={column.label}
        />
      </Stack>
    </TableCell>
  )
}

function fixedHeaderContent(
  columns: ColumnData[],
  sort: Sort | null,
  filters: Filters,
  setFilters: (filters: Filters) => void,
  onSort?: (column: string) => void
) {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableHeader
          key={column.dataKey}
          column={column}
          active={column.dataKey === sort?.attribute}
          direction={
            sort?.attribute === column.dataKey ? sort.direction : 'asc'
          }
          onSort={onSort}
          filters={filters}
          setFilters={setFilters}
        />
      ))}
    </TableRow>
  )
}

function rowContent(_index: number, row: Data, columns: ColumnData[]) {
  return (
    <React.Fragment>
      {columns.map((column) => {
        let value: any = row[column.dataKey]

        if (value === true) {
          value = 'Ja'
        }
        if (value === false) {
          value = 'Nej'
        }

        return (
          <TableCell
            key={column.dataKey}
            align={column.numeric || false ? 'right' : 'left'}
            sx={{
              // padding to align with title instead of sort arrow
              paddingLeft: '42px'
            }}
          >
            {value}
          </TableCell>
        )
      })}
    </React.Fragment>
  )
}

interface VirtualizedTableProps {
  data: Data[]
  columns: ColumnData[]
  endReached: (index: number) => void
  loading: boolean
  sort?: Sort | null
  onSort?: OnSort

  // required when ColumnData has filter prop
  filters: Filters
  setFilters: (filters: Filters) => void
}

function suppressResizeObserver(e: ErrorEvent) {
  if (
    e.message === 'ResizeObserver loop limit exceeded' ||
    e.message ===
      'ResizeObserver loop completed with undelivered notifications.'
  ) {
    const resizeObserverErrDiv = document.getElementById(
      'webpack-dev-server-client-overlay-div'
    )
    const resizeObserverErr = document.getElementById(
      'webpack-dev-server-client-overlay'
    )

    if (resizeObserverErr) {
      resizeObserverErr.setAttribute('style', 'display: none')
    }
    if (resizeObserverErrDiv) {
      resizeObserverErrDiv.setAttribute('style', 'display: none')
    }
  }
}

const VirtualizedTable = (props: VirtualizedTableProps) => {
  const {
    data,
    columns,
    endReached,
    loading,
    sort,
    onSort,
    filters,
    setFilters
  } = props

  const orderBy = sort?.attribute || null
  const order = sort?.direction ?? 'asc'

  function sortHandler(dataKey: string) {
    if (!onSort || !dataKey || loading) {
      return
    }
    let newOrder: Order = 'asc'
    let newOrderBy = !orderBy ? dataKey : orderBy

    if (orderBy === dataKey) {
      // order changed
      newOrder = order === 'asc' ? 'desc' : 'asc'
    } else {
      newOrderBy = dataKey
    }

    onSort({
      attribute: newOrderBy,
      direction: newOrder
    })
  }

  // This is used to suppress warning from resizeObserver.
  useEffect(() => {
    window.addEventListener('error', suppressResizeObserver)
  }, [])

  return (
    <TableVirtuoso
      data={data}
      components={VirtuosoTableComponents}
      // TODO: add better loader for when sort is changed.
      fixedFooterContent={() =>
        loading ? (
          <TableRow>
            <TableCell colSpan={columns.length}>
              <Box
                sx={{
                  width: '100%',
                  height: '10rem'
                }}
              >
                <LoadingSection
                  loading
                  titles={['Hämtar bakomliggande data']}
                />
              </Box>
            </TableCell>
          </TableRow>
        ) : null
      }
      fixedHeaderContent={() =>
        fixedHeaderContent(
          columns,
          sort ?? null,
          filters,
          setFilters,
          sortHandler
        )
      }
      itemContent={(index: number, row: Data) =>
        rowContent(index, row, columns)
      }
      overscan={200}
      endReached={(index: number) => endReached(index + 1)}
    />
  )
}

VirtualizedTable.displayName = 'VirtualizedTable'
export default VirtualizedTable
