import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'

import { cloneDeep } from 'lodash'

import Alert from 'components_new/atoms/Alert'
import Chip from 'components_new/atoms/Chip'
import InputAdornment from 'components_new/atoms/InputAdornment'
import Table from 'components_new/atoms/Table'
import TableBody from 'components_new/atoms/Table/TableBody'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableHead from 'components_new/atoms/Table/TableHead'
import TableRow from 'components_new/atoms/Table/TableRow'
import TextField from 'components_new/atoms/TextField'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

import { translateFilterCondition } from 'utils/enumTranslator'
import {
  AttributeValue,
  TargetCondition,
  TargetValue
} from 'types/GlobalConfig'
import { sortAlphabeticalAsc } from 'utils/sortHelper'
import Button from 'components_new/atoms/Button'
import SimpleDialog from 'components_new/molecules/SimpleDialog'

interface TargetEditDialogProps {
  description: string
  attributeValues: AttributeValue[]
  attribute: string
  onClose: () => void
  onUpdate: (values: TargetValue[]) => void
  open: boolean
  condition: TargetCondition
  title: string
  unit: string
}

type TargetRow = {
  id: string
  name: string
  target_value: { id: string; value: string | null } | null
}

const TargetEditDialog = React.forwardRef(
  (props: TargetEditDialogProps, ref: any) => {
    const {
      description,
      attributeValues,
      attribute,
      onClose,
      onUpdate,
      open,
      condition,
      title,
      unit
    } = props

    const defaultTargetRows = useMemo(
      () =>
        attributeValues.map((item) => ({
          ...item,
          target_value: item.target_value
            ? {
                id: item.target_value.id,
                value: item.target_value.value?.toLocaleString('sv') ?? null
              }
            : null
        })),
      [attributeValues]
    )

    const [rows, setRows] = useState<TargetRow[]>([])

    // set rows by sorting it first
    const setAttributeValueRows = (attrValues: TargetRow[]) => {
      setRows(
        cloneDeep(attrValues).sort((value1, value2) =>
          sortAlphabeticalAsc(value1.name, value2.name)
        )
      )
    }

    useEffect(() => {
      setAttributeValueRows(defaultTargetRows)
    }, [])

    const handleUpdate = () => {
      const toUpdate = rows
        .map((row) =>
          row.target_value
            ? {
                id: row.target_value.id,
                value:
                  row.target_value.value !== null
                    ? // replace commas with points
                    parseFloat(row.target_value.value.replace(',', '.'))
                    : null
              }
            : null
        )
        .filter(Boolean) as TargetValue[]

      onUpdate(toUpdate)
    }

    const handleCancel = () => {
      setAttributeValueRows(defaultTargetRows)
      onClose()
    }

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>, i: number) => {
      const tempRows = [...rows]

      tempRows[i].target_value = {
        id: tempRows[i].id,
        value: e.target.value === '' ? null : e.target.value
      }

      setAttributeValueRows(tempRows)
    }

    return (
      <SimpleDialog
        actions={
          <>
            <Button onClick={handleCancel} variant="text">
              Avbryt
            </Button>
            <Button onClick={handleUpdate}>Spara</Button>
          </>
        }
        title={title ? `Redigera ${title}` : null}
        onClose={onClose}
        open={open}
        ref={ref}
      >
        {description ? (
          <Alert severity="info" sx={{ mt: 1 }}>
            {description}
          </Alert>
        ) : null}
        <Table size="small">
          {attribute ? (
            <TableHead>
              <TableRow>
                <TableCell>
                  <Text color="text.secondary" variant="subtitle2">
                    {attribute}
                  </Text>
                </TableCell>
                <TableCell>{null}</TableCell>
                <TableCell>{null}</TableCell>
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {rows.map((value, i) => (
              <TableRow key={i}>
                <TableCell>
                  <Tooltip title={value.name}>
                    <Chip
                      label={value.name}
                      sx={{ maxWidth: 400, minWidth: 0 }}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Text color="text.secondary">
                    {translateFilterCondition[condition].title}
                  </Text>
                </TableCell>
                <TableCell>
                  <TextField
                    InputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                      endAdornment: (
                        <InputAdornment position="end">{unit}</InputAdornment>
                      )
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(e, i)
                    }
                    size="small"
                    sx={{ m: 0, p: 0 }}
                    value={value.target_value?.value ?? ''}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </SimpleDialog>
    )
  }
)

TargetEditDialog.displayName = 'TargetEditDialog'
export default TargetEditDialog
