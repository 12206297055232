import { getAll, create, destroy } from 'redux/api/ApiKeys'
import * as Types from 'redux/Types'

import { Dispatch } from 'redux'

export function tryGetAllApiKeys() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_ALL_API_KEYS
    })

    getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_API_KEYS_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_API_KEYS_FAILED
        })
      })
  }
}

export function tryCreateApiKey(name: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.CREATE_IFRAME_API_KEY
    })

    create(name)
      .then((response) => {
        dispatch({
          type: Types.CREATE_IFRAME_API_KEY_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'API-nyckel skapad' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_IFRAME_API_KEY_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'API-nyckeln kunde inte skapas' }
        })
      })
  }
}

export function tryDeleteApiKey(id: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.DELETE_API_KEY
    })

    destroy(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_API_KEY_SUCCESS,
          payload: id
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'API-nyckel borttagen' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_API_KEY_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'API-nyckeln kunde inte tas bort' }
        })
      })
  }
}
