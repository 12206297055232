import React, { ReactNode } from 'react'

import { Layout } from 'react-grid-layout'
import { DashboardFilter } from 'types/GlobalDashboardFilter'
import { CustomPeriodFilter, PeriodFilter } from 'types/GlobalWidget'

import PageContainer from 'components_new/organisms/PageContainer'

import DashboardHeader from 'components_new/organisms/DashboardHeader'
import DashboardUserFilter from 'components_new/organisms/DashboardUserFilter'
import FinderNavigation from 'components_new/organisms/FinderNavigation'
import GlobalNavigation from 'components_new/organisms/GlobalNavigation'
import NewButton from 'components_new/organisms/NewButton'
import SelectCustomer from 'components_new/organisms/SelectCustomer'
import ShareDashboardButton from 'components_new/organisms/ShareDashboardButton'

interface DashboardTemplateProps {
  availableSpace: Layout | false
  children?: ReactNode
  dashboardFilter: DashboardFilter
  periodFilter: PeriodFilter | CustomPeriodFilter | null
  gridRef: any | null
  setDashboardFilter: (value: DashboardFilter) => void
  setPeriodFilter: (value: PeriodFilter | CustomPeriodFilter | null) => void
}

const DashboardTemplate = (props: DashboardTemplateProps) => {
  const {
    availableSpace,
    children,
    dashboardFilter,
    gridRef,
    periodFilter,
    setDashboardFilter,
    setPeriodFilter
  } = props

  return (
    <PageContainer
      navContent={
        <>
          <SelectCustomer sx={{ mb: 2 }} />
          <NewButton sx={{ mb: 1 }} />
        </>
      }
      navFooter={<GlobalNavigation />}
      navMenu={<FinderNavigation />}
      rightSidebar={
        <DashboardUserFilter
          dashboardFilter={dashboardFilter}
          periodFilter={periodFilter}
          setDashboardFilter={setDashboardFilter}
          setPeriodFilter={setPeriodFilter}
        />
      }
      rightSidebarTooltip={'Filter'}
      topBar={
        <DashboardHeader availableSpace={availableSpace} gridRef={gridRef} />
      }
      topBarActions={<ShareDashboardButton />}
      topBarNoGutter={true}
    >
      {children}
    </PageContainer>
  )
}

export default DashboardTemplate
