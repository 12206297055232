import React, { ReactNode, useMemo } from 'react'
import { useResizeDetector } from 'react-resize-detector'

import { AspectRatio } from 'redux/reducers/Dashboards'

import Box from 'components_new/atoms/Box'

import AspectRatioContainer from 'components_new/molecules/AspectRatioContainer'

import DashboardFooter from 'components_new/organisms/DashboardFooter'

import { Responsive as ResponsiveGridLayout, Layout } from 'react-grid-layout'

import '../../../../node_modules/react-grid-layout/css/styles.css'
import '../../../../node_modules/react-resizable/css/styles.css'
import './index.css'

import { IGNORE_ON_TRANSPARENT_EXPORT } from '../dialogs/ExportDialog'

interface DashboardGridProps {
  children: (props: { scaleFactor: number }) => ReactNode
  colors?: any
  editable: boolean
  embedded: boolean
  hideFooter?: boolean
  gridRef: any | null
  layout: Layout[]
  ratio: AspectRatio
  title: string
  updateLayout: (layout: Layout[]) => void
}

const DashboardGrid = (props: DashboardGridProps) => {
  const {
    colors,
    children,
    editable,
    embedded,
    hideFooter = false,
    gridRef,
    layout,
    ratio,
    title,
    updateLayout
  } = props

  const layoutMapper = useMemo(() => {
    const mapper: { [key: string]: Layout } = {}

    layout.forEach((l) => (mapper[l.i] = l))

    return mapper
  }, [layout])

  const { width, height, ref } = useResizeDetector()

  const scaleFactor =
    ratio === AspectRatio['16_9']
      ? (width as number) / 1920
      : (height as number) / 1080

  const padding = scaleFactor * 1

  const gridHeight =
    ratio === AspectRatio['16_9']
      ? (height as number) * 0.9 - padding * 8 * 2
      : ratio === AspectRatio['AUTO'] && embedded
        ? (height as number) * 0.95 - padding * 8 * 2
        : (height as number) * 1.0 - padding * 8 * 2
  const gridWidth = (width as number) - padding * 8 * 2

  return (
    <AspectRatioContainer ratio={ratio === AspectRatio['16_9'] ? 16 / 9 : 0}>
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'relative'
        }}
        ref={(el: HTMLDivElement) => {
          if (gridRef) {
            gridRef.current = el
          }

          ref.current = el
        }}
      >
        {/* {topBar ? <Box id={IGNORE_ON_ALL_EXPORT}>{topBar}</Box> : null} */}
        <Box
          id={IGNORE_ON_TRANSPARENT_EXPORT}
          sx={{
            position: 'absolute',
            zIndex: 0,
            width: '100%',
            height: '100%',
            bgcolor: colors?.background_color ?? 'background.paper'
          }}
        />
        <Box
          sx={{
            position: 'relative',
            zIndex: 1,
            height: '100%',
            width: '100%',
            p: padding
          }}
        >
          <ResponsiveGridLayout
            className="layout"
            layouts={{ lg: layout }}
            onLayoutChange={(l: Layout[]) => {
              const layoutChanged = l.some((item) => {
                const match = layoutMapper[item.i]

                if (!match) {
                  return true
                }

                return (
                  item.h !== match.h ||
                  item.w !== match.w ||
                  item.x !== match.x ||
                  item.y !== match.y
                )
              })

              if (layoutChanged) {
                updateLayout(l)
              }
            }}
            rowHeight={gridHeight / 12}
            maxRows={12}
            breakpoints={{
              lg: 1200,
              md: 996,
              sm: 768,
              xs: 480,
              xxs: 0
            }}
            cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
            width={gridWidth}
            margin={[0, 0]}
            compactType={null}
            preventCollision={true}
            useCSSTransforms={true}
            isDraggable={editable}
            isResizable={editable}
          >
            {children({ scaleFactor })}
          </ResponsiveGridLayout>
          {(ratio === AspectRatio['16_9'] ||
            (ratio === AspectRatio['AUTO'] && embedded)) &&
          !hideFooter ? (
                <Box
                  sx={{
                    height: ratio === AspectRatio['16_9'] ? '10%' : '5%',
                    width: '100%',
                    position: 'absolute',
                    bottom: 0,
                    left: 0
                  }}
                >
                  <DashboardFooter
                    colors={colors}
                    dashboardRatio={ratio}
                    logotype={colors?.logotype}
                    scaleFactor={scaleFactor}
                    title={title}
                  />
                </Box>
              ) : null}
        </Box>
      </div>
    </AspectRatioContainer>
  )
}

export default DashboardGrid

// https://github.com/react-grid-layout/react-grid-layout/issues/1104
