import axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/tag-options`

interface TagOption {
  id: string
  values: string[]
}

export async function getAllTagOptions() {
  const response = await axios.get<{
    data: TagOption[]
    redirected_to?: string
  }>(URL, parseAuthenticationHeader())

  if (response.data.redirected_to) {
    const redirectedResponse = await axios.get(response.data.redirected_to)

    return { data: redirectedResponse }
  }

  return response
}
