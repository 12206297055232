import { Dispatch } from 'redux'
import * as Types from 'redux/Types'

import { create, destroy, update } from 'redux/api/WidgetTagStatuses'

import {
  WidgetTagStatusPatchAttributes,
  WidgetTagStatusPostAttributes
} from 'types/GlobalWidgetTag'

export function tryCreateWidgetTagStatus(
  body: WidgetTagStatusPostAttributes,
  dashboardId: string,
  widgetId: string,
  tagId: string
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.CREATE_WIDGET_TAG_STATUS
    })

    create(body)
      .then((response) => {
        dispatch({
          type: Types.CREATE_WIDGET_TAG_STATUS_SUCCESS,
          payload: {
            dashboardId,
            statusItem: response.data.data,
            tagId,
            widgetId
          }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Attributet har uppdaterats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_WIDGET_TAG_STATUS_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Attributet kunde inte uppdateras' }
        })
      })
  }
}

export function tryUpdateWidgetTagStatus(
  id: string,
  body: WidgetTagStatusPatchAttributes,
  dashboardId: string,
  widgetId: string,
  tagId: string
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_WIDGET_TAG_STATUS
    })

    update(id, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_WIDGET_TAG_STATUS_SUCCESS,
          payload: {
            dashboardId: dashboardId,
            statusItem: response.data.data,
            tagId: tagId,
            widgetId: widgetId
          }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Attributet har uppdaterats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_WIDGET_TAG_STATUS_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Attributet kunde inte uppdateras' }
        })
      })
  }
}

export function tryDeleteWidgetTagStatus(
  id: string,
  dashboardId: string,
  widgetId: string,
  tagId: string
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.DELETE_WIDGET_TAG_STATUS
    })

    destroy(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_WIDGET_TAG_STATUS_SUCCESS,
          payload: {
            dashboardId,
            id,
            widgetId,
            tagId
          }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Attributet har uppdaterats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_WIDGET_TAG_STATUS_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Attributet kunde inte uppdateras' }
        })
      })
  }
}
