import React, { useState } from 'react'

import Button from 'components_new/atoms/Button'

import AdvancedDialog from 'components_new/molecules/AdvancedDialog'

import { EconomyAccountMap } from './utils'
import AccountTree from 'components_new/molecules/AccountTree'

interface EditVariableAccountsDialogProps {
  accountsMap: EconomyAccountMap
  economySetId: string
  limitToAccounts?: string[]
  onClose: () => void
  onSave: (accounts: string[], callback: () => void) => void
  open: boolean
  selectedAccounts: string[]
}

const EditVariableAccountsDialog = (props: EditVariableAccountsDialogProps) => {
  const {
    accountsMap,
    economySetId,
    limitToAccounts,
    onClose,
    onSave,
    open,
    selectedAccounts
  } = props

  const [selected, setSelected] = useState<string[]>([...selectedAccounts])
  const [loading, setLoading] = useState(false)

  const parsedOnSave = (selected: string[]) => {
    setLoading(true)
    onSave(selected, () => setLoading(false))
  }

  return (
    <AdvancedDialog
      title="Redigera kontoreferenser"
      fullHeight={true}
      open={open}
      onClose={onClose}
      actions={
        <>
          <Button variant="text" onClick={onClose}>
            Avbryt
          </Button>
          <Button
            loading={loading}
            onClick={() => parsedOnSave(selected)}
            variant="contained"
          >
            Spara
          </Button>
        </>
      }
    >
      <AccountTree
        mappedAccounts={accountsMap[economySetId]}
        selected={selected}
        setSelected={setSelected}
        limitToAccounts={limitToAccounts}
      />
    </AdvancedDialog>
  )
}

export default EditVariableAccountsDialog
