/** For resizable columns:
 *  https://codesandbox.io/embed/resize-columns-table-material-ui-ei0pqd?fontsize=14&hidenavigation=1&theme=dark
 */

import React from 'react'
import Tooltip from 'components_new/atoms/Tooltip'

import {
  DatasetType,
  FormattedWidgetDataDataset,
  ListDatasetData,
  ParsedWidgetDataLabel,
  StatusData
} from 'types/GlobalWidget'
import { RowData } from '.'
import { sortAlphabeticalAsc, sortAlphabeticalDesc } from 'utils/sortHelper'
import _ from 'lodash'

export const ORDER_BY_SEGMENT_BY = 'ORDER_BY_SEGMENT_BY'

export enum OrderByAlgoritmType {
  STRING = 'string',
  NUMBER = 'number'
}

export function suppressResizeObserver(e: ErrorEvent) {
  if (
    e.message === 'ResizeObserver loop limit exceeded' ||
    e.message ===
      'ResizeObserver loop completed with undelivered notifications.'
  ) {
    const resizeObserverErrDiv = document.getElementById(
      'webpack-dev-server-client-overlay-div'
    )
    const resizeObserverErr = document.getElementById(
      'webpack-dev-server-client-overlay'
    )

    if (resizeObserverErr) {
      resizeObserverErr.setAttribute('style', 'display: none')
    }
    if (resizeObserverErrDiv) {
      resizeObserverErrDiv.setAttribute('style', 'display: none')
    }
  }
}

export const getCellAlignment = (type: DatasetType) => {
  switch (type) {
  case DatasetType.ENUM: {
    return 'center'
  }
  case DatasetType.NUMBER: {
    return 'right'
  }
  case DatasetType.DATE: {
    return 'right'
  }
  case DatasetType.NUMBER_TAG: {
    return 'right'
  }
  default: {
    return 'left'
  }
  }
}

export const ellipseLabel = (
  label: string | number | null,
  n: number | null
) => {
  const stringLabel = label?.toString()

  if (n !== null && stringLabel) {
    return stringLabel.length < n ? (
      stringLabel
    ) : (
      <Tooltip title={label}>
        <span>{stringLabel.slice(0, n)}&#8230;</span>
      </Tooltip>
    )
  } else {
    return label
  }
}

export const getCellScaleSx = (scaleFactor: number) => {
  return {
    fontSize: 16 * scaleFactor,
    lineHeight: '1.5em',
    px: '1.25em',
    py: '0.5em'
  }
}

export const getStatus = (
  value: number | null,
  upperThresholdValue: number | null,
  lowerThresholdValue: number | null,
  increaseIsPositive: boolean
) => {
  if (upperThresholdValue === null || value === null) {
    return 'inherit'
  }

  const success = increaseIsPositive
    ? value >= upperThresholdValue
    : value <= upperThresholdValue

  if (success) {
    return 'success'
  }

  const warning =
    lowerThresholdValue !== null &&
    (increaseIsPositive
      ? value > lowerThresholdValue
      : value < lowerThresholdValue)

  if (warning) {
    return 'warning'
  }

  return 'error'
}

function orderByRefValue(
  list: { sortingValue: number | string | null; value: any }[],
  order: 'asc' | 'desc',
  algoritmType: OrderByAlgoritmType
) {
  const sortedList = _.cloneDeep(list)

  if (algoritmType === OrderByAlgoritmType.NUMBER) {
    sortedList.sort((a, b) => {
      if (a.sortingValue === null && b.sortingValue === null) {
        return 0
      }

      if (a.sortingValue === null) {
        return 1
      }

      if (b.sortingValue === null) {
        return -1
      }

      const aValue = a.sortingValue as number
      const bValue = b.sortingValue as number

      return order === 'asc' ? aValue - bValue : bValue - aValue
    })
  } else {
    sortedList.sort((a, b) =>
      order == 'asc'
        ? sortAlphabeticalAsc(a.sortingValue, b.sortingValue)
        : sortAlphabeticalDesc(a.sortingValue, b.sortingValue)
    )
  }

  return sortedList.map((item) => item.value)
}

export function orderLabels(
  labels: RowData[],
  sortingOpt: {
    values: (number | string | null)[]
    algoritmType: OrderByAlgoritmType
  } | null,
  order: 'asc' | 'desc'
) {
  if (!sortingOpt) {
    return labels
  }

  const labelsWithSortingValue = labels.map((value, index) => ({
    sortingValue: sortingOpt.values[index],
    value
  }))

  return orderByRefValue(labelsWithSortingValue, order, sortingOpt.algoritmType)
}

export function orderDataset(
  dataset: FormattedWidgetDataDataset,
  sortingOpt: {
    values: (number | string | null)[]
    algoritmType: OrderByAlgoritmType
  } | null,
  order: 'asc' | 'desc'
) {
  if (!sortingOpt) {
    return dataset
  }

  const data = orderByRefValue(
    dataset.data.map((value, index) => ({
      sortingValue: sortingOpt.values[index],
      value
    })),
    order,
    sortingOpt.algoritmType
  ) as ListDatasetData

  const prettyData = orderByRefValue(
    dataset.prettyData.map((value, index) => ({
      sortingValue: sortingOpt.values[index],
      value
    })),
    order,
    sortingOpt.algoritmType
  ) as (string | number | string[] | null)[]

  const statuses = orderByRefValue(
    dataset.statuses.map((value, index) => ({
      sortingValue: sortingOpt.values[index],
      value
    })),
    order,
    sortingOpt.algoritmType
  ) as StatusData[]

  let percentages: (number | null)[] | undefined = undefined

  if (dataset.percentages) {
    percentages = orderByRefValue(
      dataset.percentages.map((value, index) => ({
        sortingValue: sortingOpt.values[index],
        value
      })),
      order,
      sortingOpt.algoritmType
    )
  }

  return {
    ...dataset,
    data,
    prettyData,
    percentages,
    statuses
  } as FormattedWidgetDataDataset
}

export function getOrderByArray(
  orderBy: { id: string | null; index: number | null },
  labels: ParsedWidgetDataLabel[],
  datasets: FormattedWidgetDataDataset[]
) {
  if (!orderBy.id) {
    return null
  }

  if (orderBy.id === ORDER_BY_SEGMENT_BY) {
    return {
      values: labels.map((item) => item.display_label),
      algoritmType: OrderByAlgoritmType.STRING
    }
  }

  const dataset = datasets.find(
    (dataset) => dataset.id === orderBy.id && dataset.index === orderBy.index
  )

  if (!dataset) {
    return null
  }

  if (
    [DatasetType.ENUM, DatasetType.STRING, DatasetType.DATE].includes(
      dataset.type
    )
  ) {
    return {
      values: dataset.data.map((value) => (value as string[])[0] || null),
      algoritmType: OrderByAlgoritmType.STRING
    }
  }
  if (dataset.type === DatasetType.NUMBER_TAG) {
    return {
      values: dataset.data.map((value) => (value as number[])[0] ?? null),
      algoritmType: OrderByAlgoritmType.NUMBER
    }
  }

  return {
    values: dataset.data as (number | string | null)[],
    algoritmType: OrderByAlgoritmType.NUMBER
  }
}

/**
 * Sort according to column settings and filter hidden datasets.
 */
export function sortFormattedDatasets(datasets: FormattedWidgetDataDataset[]) {
  return [...datasets]
    .sort((a, b) => {
      if (a.settingIndex === b.settingIndex) {
        return a.index - b.index
      }

      return a.settingIndex - b.settingIndex
    })
    .filter((dataset) => !dataset.hidden)
}
