import React, { FC, useEffect } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'

import LoadingSettings from 'components_new/organisms/pageLoaders/LoadingSettings'

import ConfigsTemplate from 'components_new/templates/settings/Configs'
import SettingsTemplate from 'components_new/templates/SettingsTemplate'

import * as ConfigActions from 'redux/actions/Configs'

import { ApplicationState } from 'redux/Stores/types'

const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  ConfigStore: state.ConfigStore,
  NavigationMenuStore: state.NavigationMenuStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...ConfigActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ConfigurationPageProps = ConnectedProps<typeof connector>

const ConfigurationPage: FC<ConfigurationPageProps> = (
  props: ConfigurationPageProps
) => {
  const { ConfigStore, tryGetAllTargets } = props

  useEffect(() => {
    if (!ConfigStore.fetched) {
      tryGetAllTargets()
    }
  }, [])

  const sections = Object.values(ConfigStore.data)

  return (
    <SettingsTemplate>
      {ConfigStore.fetched && sections.length > 0 ? (
        <ConfigsTemplate />
      ) : (
        <LoadingSettings
          titles={[
            'Hämtar information om dina mål',
            'Sätter upp dina målvärden'
          ]}
        />
      )}
    </SettingsTemplate>
  )
}

export default connector(ConfigurationPage)
