import React, { useState } from 'react'

import Alert from 'components_new/atoms/Alert'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import Link from 'components_new/atoms/Link'
import List from 'components_new/atoms/List'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemText from 'components_new/atoms/List/ListItemText'

import CollapsibleSection from 'components_new/molecules/CollapsibleSection'
import SimpleDialog from 'components_new/molecules/SimpleDialog'

import CreateAllocationKeyDialog from 'components_new/organisms/dialogs/CreateAllocationKeyDialog'
import EditAllocationKeyDialog from 'components_new/organisms/dialogs/EditAllocationKeyDialog'

import { IAllocationKey } from 'types/GlobalAllocationKey'
import { ApplicationState } from 'redux/Stores/types'
import { connect, ConnectedProps } from 'react-redux'

import * as AllocationKeyActions from 'redux/actions/AllocationKeys'

const AllocationKeysTemplate = (props: ComponentProps) => {
  const [allocationKeyToDelete, setAllocationKeyToDelete] =
    useState<IAllocationKey | null>(null)
  const [createOpen, setCreateOpen] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [allocationKeyToUpdate, setAllocationKeyToUpdate] = useState<
    string | null
  >(null)

  const handleOpenCreate = () => {
    setCreateOpen(true)
  }

  const handleCloseCreate = () => {
    setCreateOpen(false)
  }

  const handleCloseUpdate = () => {
    setAllocationKeyToUpdate(null)
  }

  const handleCloseDelete = () => {
    setAllocationKeyToDelete(null)
  }

  return (
    <>
      <CollapsibleSection
        title="Fördelningsnycklar"
        sx={{ flexGrow: 1, maxWidth: 'section' }}
        actions={
          <Button
            onClick={handleOpenCreate}
            startIcon={<Icon name="AddCircleOutlineOutlined" />}
          >
            Lägg till
          </Button>
        }
      >
        <Alert severity="info" icon={<Icon name="InfoOutlined" />}>
          <strong>
            Fördelningsnycklar kan användas för att fördela centrala kostnader
            mellan fastigheter enligt specifika kriterier
          </strong>
          , som till exempel uthyrningsbar yta eller annan procentuell
          uppdelning. Genom att använda fördelningsnycklar får ni en mer
          rättvisande bild av kostnadsfördelningen och kan enklare följa upp
          ekonomin på fastighetsnivå.{' '}
          <strong>
            Uppdateringar i fördelningsnycklar slår igenom vid nästa inläsning
            från källsystem.
          </strong>{' '}
          Om du behöver hjälp kontakta supporten på{' '}
          <Link href={'mailto:support@homepal.se'}>support@homepal.se</Link>{' '}
          eller skriv ett meddelande i chatten.
        </Alert>

        <List>
          {Object.values(props.AllocationKeyStore.data).map((allocationKey) => (
            <ListItem
              key={allocationKey.id}
              secondaryAction={
                <>
                  <Button
                    onClick={() => setAllocationKeyToUpdate(allocationKey.id)}
                    variant="text"
                  >
                    Redigera
                  </Button>
                  <Button
                    onClick={() => setAllocationKeyToDelete(allocationKey)}
                    variant="text"
                  >
                    Ta bort
                  </Button>
                </>
              }
            >
              <Icon color="disabled" name="VpnKeyOutlined" />
              <ListItemText
                sx={{ ml: 3 }}
                primary={allocationKey.name}
                primaryTypographyProps={{ fontWeight: 500 }}
                secondary={`Fördelar ${allocationKey.accounts.length} ${
                  allocationKey.accounts.length === 1 ? 'konto' : 'konton'
                }`}
              />
            </ListItem>
          ))}
        </List>
      </CollapsibleSection>
      <SimpleDialog
        title={'Radera fördelningsnyckel?'}
        open={Boolean(allocationKeyToDelete)}
        onClose={handleCloseDelete}
        actions={
          <>
            <Button variant="text" onClick={handleCloseDelete}>
              Stäng
            </Button>
            <Button
              color="error"
              loading={isDeleting}
              onClick={() => {
                setIsDeleting(true)

                props.tryDeleteAllocationKey(
                  (allocationKeyToDelete as IAllocationKey).id,
                  (err) => {
                    if (!err) {
                      handleCloseDelete()
                    }

                    setIsDeleting(false)
                  }
                )
              }}
              variant="contained"
            >
              Radera
            </Button>
          </>
        }
        contentText={
          <>
            Fördelningsnyckeln <code>{allocationKeyToDelete?.name}</code> kommer
            att raderas permanent.{' '}
            <b>
              Utfall utan koppling till en fastighet via konstadsställen kommer
              att försvinna från konton som fördelas via nyckeln.
            </b>{' '}
            Är du säker?
          </>
        }
      />
      <CreateAllocationKeyDialog
        onClose={handleCloseCreate}
        open={createOpen}
      />
      <EditAllocationKeyDialog
        allocationKeyId={allocationKeyToUpdate}
        onClose={handleCloseUpdate}
      />
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AllocationKeyStore: state.AllocationKeyStore
})

const connector = connect(mapStateToProps, { ...AllocationKeyActions })

type ComponentProps = ConnectedProps<typeof connector>

export default connector(AllocationKeysTemplate)
