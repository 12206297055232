import axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'

import {
  TagStatusItem,
  WidgetTagStatusPatchAttributes,
  WidgetTagStatusPostAttributes
} from 'types/GlobalWidgetTag'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/widget-tag-statuses`

export async function create(body: WidgetTagStatusPostAttributes) {
  return axios.post<{ data: TagStatusItem }>(
    URL,
    body,
    parseAuthenticationHeader()
  )
}

export async function update(id: string, body: WidgetTagStatusPatchAttributes) {
  return axios.patch<{ data: TagStatusItem }>(
    `${URL}/${id}`,
    body,
    parseAuthenticationHeader()
  )
}

export async function destroy(id: string) {
  return axios.delete(`${URL}/${id}`, parseAuthenticationHeader())
}
