import { Dispatch } from 'redux'
import * as Types from 'redux/Types'

import { create, destroy, update } from 'redux/api/WidgetTags'

import {
  WidgetTagPatchAttributes,
  WidgetTagPostAttributes
} from 'types/GlobalWidgetTag'

export function tryCreateWidgetTag(
  body: WidgetTagPostAttributes,
  dashboardId: string,
  widgetId: string
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.CREATE_WIDGET_TAG
    })

    create(body)
      .then((response) => {
        dispatch({
          type: Types.CREATE_WIDGET_TAG_SUCCESS,
          payload: {
            dashboardId: dashboardId,
            tag: response.data.data,
            widgetId: widgetId
          }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Attributet har lagts till' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_WIDGET_TAG_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Attributet kunde inte läggas till' }
        })
      })
  }
}

export function tryUpdateWidgetTag(
  id: string,
  body: WidgetTagPatchAttributes,
  dashboardId: string,
  widgetId: string
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_WIDGET_TAG
    })

    update(id, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_WIDGET_TAG_SUCCESS,
          payload: {
            dashboardId: dashboardId,
            tag: response.data.data,
            skipFetchData: false,
            widgetId: widgetId
          }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Attributet har uppdaterats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_WIDGET_TAG_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Attributet kunde inte uppdateras' }
        })
      })
  }
}

export function tryDeleteWidgetTag(
  id: string,
  widgetId: string,
  dashboardId: string
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.DELETE_WIDGET_TAG
    })

    destroy(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_WIDGET_TAG_SUCCESS,
          payload: {
            dashboardId: dashboardId,
            id,
            widgetId: widgetId
          }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Attributet har tagits bort' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_WIDGET_TAG_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Attributet kunde inte tas bort' }
        })
      })
  }
}
