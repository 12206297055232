import { Dispatch } from 'redux'
import * as Types from 'redux/Types'

import { create, destroy, getAll, update } from 'redux/api/AllocationKeys'

import {
  IPatchAllocationKey,
  IPostAllocationKey
} from 'types/GlobalAllocationKey'

export function tryCreateAllocationKey(
  data: IPostAllocationKey,
  callback: (err?: boolean) => void
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.CREATE_ALLOCATION_KEY
    })

    create({ data })
      .then((response) => {
        dispatch({
          type: Types.CREATE_ALLOCATION_KEY_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Fördelningsnyckel skapad' }
        })

        callback()
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_ALLOCATION_KEY_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Fördelningsnyckeln kunde inte skapas' }
        })

        callback(true)
      })
  }
}

export function tryUpdateAllocationKey(
  id: string,
  data: IPatchAllocationKey,
  callback: (err?: boolean) => void
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_ALLOCATION_KEY
    })

    update(id, { data })
      .then((response) => {
        dispatch({
          type: Types.UPDATE_ALLOCATION_KEY_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Fördelningsnyckel uppdaterad' }
        })

        callback()
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_ALLOCATION_KEY_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Fördelningsnyckeln kunde inte uppdateras' }
        })

        callback(true)
      })
  }
}

export function tryGetAllAllocationKeys() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_ALL_ALLOCATION_KEYS
    })

    getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_ALLOCATION_KEYS_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_ALLOCATION_KEYS_FAILED
        })
      })
  }
}

export function tryDeleteAllocationKey(
  id: string,
  callback: (err?: boolean) => void
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.DELETE_ALLOCATION_KEY
    })

    destroy(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_ALLOCATION_KEY_SUCCESS,
          payload: id
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Fördelningsnyckel borttagen' }
        })

        callback()
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_ALLOCATION_KEY_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Fördelningsnyckeln kunde inte tas bort' }
        })

        callback(true)
      })
  }
}
