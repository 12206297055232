import React, { FC } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import ExamplePage from 'components_new/pages/ExamplePage'
import ExplorePage from 'components_new/pages/ExplorePage'

import AuthRoute from './AuthRoute'

import { AuthenticationState } from 'types/GlobalAuthentication'

const Routes: FC<{
  AuthStore: AuthenticationState
}> = ({ AuthStore }) => {
  return (
    <Switch>
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={() => <ExamplePage />}
        path={'/explore/:id'}
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={() => <ExplorePage />}
        path={'/explore'}
      />
      <Route path={'/explore/*'}>
        <Redirect to="/explore" />
      </Route>
    </Switch>
  )
}

export default Routes
