import React, { ReactNode } from 'react'

import { ApplicationState } from 'redux/Stores/types'
import { Dispatch, bindActionCreators } from 'redux'
import { ConnectedProps, connect } from 'react-redux'

import * as AuthStoreActions from 'redux/actions/Authentication'
import * as NavigationMenuStoreActions from 'redux/actions/NavigationMenu'

import CustomerPreview from 'components_new/organisms/CustomerPreview'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'

interface NavBarProps {
  content?: ReactNode
  footer?: ReactNode
  locked?: boolean
  menu?: ReactNode
}

const NavBar = (props: ComponentProps) => {
  const {
    content,
    footer,
    locked,
    menu,
    // redux stores
    NavigationMenuStore
  } = props

  const open = NavigationMenuStore?.open

  return (
    <Box
      sx={{
        height: '100%',
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
        minHeight: 0
      }}
    >
      {content ? (
        <Box
          sx={{
            p: open || locked ? 2 : '7px',
            pt: open || locked ? 1 : 1.5,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {content}
        </Box>
      ) : null}

      <Box
        sx={{
          flex: 1,
          px: open || locked ? 2 : '13px',
          overflowY: 'auto'
        }}
      >
        {menu ?? null}
      </Box>

      <Box
        sx={{
          p: open || locked ? 2 : 1,
          pt: open || locked ? 1 : undefined,
          pb: open || locked ? undefined : 2,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {footer ? (
          <Box
            sx={{
              px: 0.5,
              display: 'flex',
              flexDirection: 'column',
              gap: 0.25
            }}
          >
            {footer}
          </Box>
        ) : null}
        <Divider flexItem={true} sx={{ my: 2 }} />
        <CustomerPreview />
      </Box>
    </Box>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  NavigationMenuStore: state.NavigationMenuStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AuthStoreActions,
      ...NavigationMenuStoreActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> & NavBarProps

export default connector(NavBar)
