import React, { MouseEvent, ReactNode } from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import Divider from 'components_new/atoms/Divider'
import Icon, { IconNames } from 'components_new/atoms/Icon'
import Menu from 'components_new/atoms/Menu/'

import NestedOptionsMenuItem from 'components_new/molecules/NestedOptionsMenuItem'
import OptionsMenuItem from 'components_new/molecules/OptionsMenuItem'

export interface OptionProps {
  disabled?: boolean
  divider?: boolean
  iconName?: IconNames
  iconRotation?: string
  infoTip?: ReactNode
  onClick: () => void
  nestedOptions?: OptionProps[]
  title: string
  tooltip?: string
}

interface OptionsMenuProps {
  anchorEl: HTMLElement | null
  disableScrollLock?: boolean
  onClose: (event: MouseEvent<HTMLElement>) => void
  open: boolean
  options: OptionProps[]
  sx?: object
}

/**
 * The OptionMenu component is used for
 * displaying one or more options (Icon
 * + Text) in a list when clicking an
 * HTML element.
 */

const OptionsMenu = React.forwardRef((props: OptionsMenuProps, ref: any) => {
  const { anchorEl, disableScrollLock, onClose, open, options, sx } = props

  return (
    <>
      <ThemeProvider theme={getTheme('light')}>
        <Menu
          anchorEl={anchorEl}
          disableScrollLock={disableScrollLock}
          onClose={onClose}
          open={open}
          ref={ref}
          sx={sx}
        >
          {options.map((option: OptionProps, i: number) => [
            option.nestedOptions ? (
              <NestedOptionsMenuItem
                closeParentMenu={onClose}
                divider={option.divider}
                icon={
                  option.iconName ? (
                    <Icon name={option.iconName} fontSize="small" />
                  ) : undefined
                }
                key={i}
                parentMenuOpen={open}
                title={option.title}
                MenuSx={sx}
              >
                {option.nestedOptions.map(
                  (nestedOption: OptionProps, i: number) => [
                    <OptionsMenuItem
                      iconName={nestedOption.iconName}
                      iconRotation={nestedOption.iconRotation}
                      infoTip={nestedOption.infoTip}
                      key={`nested-${i}`}
                      onClick={(event) => {
                        onClose(event)
                        nestedOption.onClick()
                      }}
                      title={nestedOption.title}
                    />,
                    nestedOption.divider ? (
                      <Divider key={`divider-${i}`} />
                    ) : null
                  ]
                )}
              </NestedOptionsMenuItem>
            ) : (
              <OptionsMenuItem
                disabled={option.disabled}
                iconName={option.iconName}
                iconRotation={option.iconRotation}
                infoTip={option.infoTip}
                key={i}
                onClick={(event) => {
                  onClose(event)
                  option.onClick()
                }}
                title={option.title}
                tooltip={option.tooltip}
              />
            ),
            option.divider ? <Divider /> : null
          ])}
        </Menu>
      </ThemeProvider>
    </>
  )
})

OptionsMenu.displayName = 'OptionsMenu'
export default OptionsMenu
