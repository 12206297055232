import { DatasetType } from './GlobalWidget'

export type WidgetTagPostAttributes = {
  data: {
    widget_id: string
    tag_option_id: string
  }
}

export type WidgetTagPatchAttributes = {
  data: {
    title?: string
  }
}

export interface WidgetTagStatusPostAttributes {
  data: {
    widget_tag_id: string
    condition: TagStatusCondition
    value: number | string | TagStatusDateValue
    severity: TagStatusSeverity
    conditional_value: number | null
  }
}

export interface WidgetTagStatusPatchAttributes {
  data: {
    condition?: TagStatusCondition
    value?: number | string | TagStatusDateValue
    severity?: TagStatusSeverity
    conditional_value?: number | null
  }
}

export enum TagStatusCondition {
  EQ = '=',
  NE = '!=',
  LT = '<',
  GT = '>'
}

export type TagType = Exclude<DatasetType, DatasetType.NUMBER>

export enum TagStatusDateValue {
  DAYS_FROM_NOW = 'DAYS_FROM_NOW',
  DAYS_SINCE_NOW = 'DAYS_SINCE_NOW',
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW',
  YESTERDAY = 'YESTERDAY'
}

export enum TagStatusSeverity {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success'
}

export type TagStatusItem = {
  id: string
  index: number
  type: TagType
  condition: {
    options: TagStatusCondition[]
    selected: TagStatusCondition
  }
  value:
    | number
    | string
    | {
        selected: string | TagStatusDateValue
      }
  severity: {
    options: TagStatusSeverity[]
    selected: TagStatusSeverity
  }
  conditional_value: number | null // for eg. dates
}

export interface WidgetTagObject {
  id: string
  index: number
  tag_option_id: string
  title: string
  type: TagType
  settings: {
    status: {
      condition_options: TagStatusCondition[]
      items: TagStatusItem[]
    }
  }
}

export type WidgetTagReduxPayload = {
  tag: WidgetTagObject
  dashboardId: string
  widgetId: string
  skipFetchData?: boolean
}

export type WidgetTagStatusItemReduxPayload = {
  statusItem: TagStatusItem
  dashboardId: string
  tagId: string
  widgetId: string
}
