import React, { useMemo } from 'react'

import { useTheme } from '@mui/material'

import { Dispatch, bindActionCreators } from 'redux'
import { ApplicationState } from 'redux/Stores/types'
import { ConnectedProps, connect } from 'react-redux'

import * as KPIDashboardStoreActions from 'redux/actions/Dashboards'

import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'
import Paper from 'components_new/atoms/Paper'
import Text from 'components_new/atoms/Text'

import ThumbnailList from 'components_new/molecules/ThumbnailList'

import { getQueryObject, updateQueryParam } from 'utils/queryHandler'
import { useHistory, useLocation } from 'react-router-dom'
import { getUniqueItemsFromField } from 'utils/cmsFunctions'
import { sortAlphabeticalAsc } from 'utils/sortHelper'

import DashboardExampleThumbnail from '../DashboardExampleThumbnail'

import Loading from './loading'

import { getScrollbarSx } from 'themes'

interface DashboardExampleExplorerProps {
  sx?: object
}

const DashboardExampleExplorer = (props: ComponentProps) => {
  const { AuthStore, DashboardExampleStore, sx } = props

  const history = useHistory()
  const location = useLocation()
  const theme = useTheme()

  const filteredDashboards = useMemo(() => {
    const query = getQueryObject()
    const dashboards = Object.values(DashboardExampleStore.data)
      // exclude hidden and sort by title asc
      .filter((dashboard) => !dashboard.fields.hidden)
      .sort((a, b) => sortAlphabeticalAsc(a.fields.title, b.fields.title))

    if (Object.keys(query).length === 0) return dashboards

    return dashboards.filter((dashboard) => {
      // filter by category
      const category = query['category']
        ? query['category'].includes(
          dashboard.fields.category?.fields.slug || ''
        )
        : true

      // filter by segment
      const segment = query['segment']
        ? query['segment'].some((segment) =>
          dashboard.fields.segments?.some(
            (item: any) => item.fields.slug === segment
          )
        )
        : true

      return category && segment
    })
  }, [DashboardExampleStore.data, location.search])

  /*-- categories --*/
  const categoryOptions = useMemo(
    () =>
      getUniqueItemsFromField(
        Object.values(DashboardExampleStore.data),
        'category',
        'slug'
      ),
    [DashboardExampleStore.data]
  )

  const selectedCategories = useMemo(() => {
    const slugs = getQueryObject()['category'] ?? []

    if (categoryOptions.length > 0) {
      return slugs.map((slug) =>
        categoryOptions.find((category) => slug === category.fields.slug)
      )
    } else {
      return []
    }
  }, [location.search])

  /*-- segments --*/
  const segmentOptions = useMemo(
    () =>
      getUniqueItemsFromField(
        Object.values(DashboardExampleStore.data),
        'segments',
        'slug'
      ),
    [DashboardExampleStore.data]
  )

  const selectedSegments = useMemo(() => {
    const slugs = getQueryObject()['segment'] ?? []

    if (segmentOptions.length > 0) {
      return slugs.map((slug) =>
        segmentOptions.find((segment) => slug === segment.fields.slug)
      )
    } else {
      return []
    }
  }, [location.search])

  // loading
  if (!DashboardExampleStore.fetched) return <Loading />

  // success
  return (
    <>
      <Paper
        sx={{
          height: '100%',
          width: '100%',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          ...getScrollbarSx(theme),
          ...sx
        }}
      >
        <Box sx={{ pt: 4 }}>
          <Text align="center" variant="h2">
            Bli inspirerad
          </Text>
          <Text align="center" variant="body1">
            Utforska exempel på dina branschkollegors mest älskade dashboards.
          </Text>
        </Box>

        <Paper
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 2,
            py: 1
          }}
        >
          {selectedCategories.length > 0 || selectedSegments.length > 0 ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                gap: 0.5,
                my: 1
              }}
            >
              {selectedCategories.map((category) => (
                <Chip
                  key={category.fields.slug}
                  label={category.fields.title}
                  onDelete={() =>
                    updateQueryParam({
                      history: history,
                      key: 'category',
                      value: category.fields.slug
                    })
                  }
                />
              ))}
              {selectedSegments.map((segment) => (
                <Chip
                  key={segment.fields.slug}
                  label={segment.fields.name}
                  onDelete={() =>
                    updateQueryParam({
                      history: history,
                      key: 'segment',
                      value: segment.fields.slug
                    })
                  }
                />
              ))}
            </Box>
          ) : null}
          <Text align="center" component="p" variant="caption">
            {filteredDashboards.length} dashboards
          </Text>
        </Paper>

        <Box
          sx={{
            p: 2,
            pt: 0
          }}
        >
          <ThumbnailList itemSize={{ xs: 4, md: 6, lg: 4, xl: 3 }}>
            {filteredDashboards.map((dashboard) => (
              <DashboardExampleThumbnail
                category={dashboard.fields.category?.fields.title || ''}
                createdAt={dashboard.sys.createdAt}
                disableHistory={true}
                key={dashboard.fields.id}
                href={`/explore/${dashboard.fields.id}`}
                search={history.location.search}
                id={dashboard.fields.id}
                systems={dashboard.fields.systems || []}
                title={dashboard.fields.title}
                totalVisits={dashboard.data.visits.total}
                updatedAt={dashboard.sys.updatedAt}
                user={AuthStore.user}
              />
            ))}
          </ThumbnailList>
        </Box>
      </Paper>
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  DashboardExampleStore: state.DashboardExampleStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...KPIDashboardStoreActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> &
  DashboardExampleExplorerProps

export default connector(DashboardExampleExplorer)
