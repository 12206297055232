import React, { useMemo } from 'react'
import { TreeView } from '@mui/x-tree-view'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'

import AccountTreeItem from '../AccountTreeItem'

import { getRangeGroups, getRangeSelectionStatus, handleToggle } from './utils'

interface AccountTreeProps {
  disabledAccounts?: string[]
  limitToAccounts?: string[]
  mappedAccounts: { [number: string]: string }
  selected: string[]
  setSelected: (accounts: string[]) => void
}

const AccountTree = (props: AccountTreeProps) => {
  const rangeGroups = useMemo(
    () =>
      getRangeGroups(
        props.mappedAccounts,
        props.limitToAccounts,
        props.disabledAccounts
      ),
    [props.mappedAccounts, props.limitToAccounts]
  )

  return (
    <Box sx={{ position: 'relative', flexGrow: 1 }}>
      <TreeView
        defaultCollapseIcon={<Icon name="ExpandMore" />}
        defaultExpandIcon={<Icon name="ChevronRight" />}
        multiSelect={true}
      >
        {rangeGroups.options.map((i) => (
          <AccountTreeItem
            disabled={i.disabled}
            key={i.range.start}
            nodeId={i.range.label}
            onToggle={() => {
              const newSelected = handleToggle(props.selected, i.accounts)

              props.setSelected(newSelected)
            }}
            label={i.range.label}
            selected={getRangeSelectionStatus(props.selected, i.accounts)}
          >
            {i.options.map((j) => (
              <AccountTreeItem
                disabled={j.disabled}
                key={j.range.label}
                nodeId={j.range.label}
                onToggle={() => {
                  const newSelected = handleToggle(props.selected, j.accounts)

                  props.setSelected(newSelected)
                }}
                label={j.range.label}
                selected={getRangeSelectionStatus(props.selected, j.accounts)}
              >
                {j.options.map((k) => (
                  <AccountTreeItem
                    disabled={k.disabled}
                    key={k.range.label}
                    nodeId={k.range.label}
                    onToggle={() => {
                      const newSelected = handleToggle(
                        props.selected,
                        k.accounts
                      )

                      props.setSelected(newSelected)
                    }}
                    label={k.range.label}
                    selected={getRangeSelectionStatus(
                      props.selected,
                      k.accounts
                    )}
                  >
                    {k.accounts.map((fAccount) => (
                      <AccountTreeItem
                        disabled={fAccount.disabled}
                        key={fAccount.number}
                        nodeId={fAccount.number}
                        onToggle={() => {
                          const newSelected = handleToggle(props.selected, [
                            fAccount
                          ])

                          props.setSelected(newSelected)
                        }}
                        label={
                          <Box component="span" sx={{ fontWeight: 500 }}>
                            {fAccount.number}
                            {fAccount.name ? ` - ${fAccount.name}` : ''}
                          </Box>
                        }
                        selected={getRangeSelectionStatus(props.selected, [
                          fAccount
                        ])}
                      />
                    ))}
                  </AccountTreeItem>
                ))}
              </AccountTreeItem>
            ))}
          </AccountTreeItem>
        ))}
      </TreeView>
    </Box>
  )
}

export default AccountTree
