import React, { useState, useMemo } from 'react'

import { AccountType, RoleReducerType } from 'redux/reducers/Accounts'
import { CompanyGroupBody } from 'redux/reducers/CompanyGroups'
import { AccountRole, PatchUserBody, PostUserBody } from 'types/GlobalUser'

import Avatar from 'components_new/atoms/Avatar'
import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'

import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'
import SimpleDialog from 'components_new/molecules/SimpleDialog'

import AddAccount from 'components_new/organisms/AddAccount'
import EditUserDialog from 'components_new/organisms/dialogs/EditUserDialog'
import DeleteDialog from 'components_new/organisms/dialogs/DeleteDialog'

import { TRANSLATE_ROLES } from 'utils/enumTranslator'
import { DELETE_ICON, EDIT_ICON } from 'utils/icons'

interface UsersProps {
  companyGroups: CompanyGroupBody
  createUser: (body: PostUserBody) => void
  currentUser?: string
  deleteUser: (id: string) => void
  isHomepal?: boolean
  isManagementCompany: boolean
  roles: RoleReducerType
  updateUser: (id: string, body: PatchUserBody) => void
  users: AccountType[]
}

const getUserIcon = (user: AccountType) => {
  if (user.company_group_id) {
    return 'AssignmentIndOutlined'
  }

  return user.role === AccountRole.ADMIN
    ? 'ManageAccountsOutlined'
    : 'PersonOutlineOutlined'
}

const UsersTemplate = (props: UsersProps) => {
  const {
    companyGroups,
    createUser,
    currentUser,
    deleteUser,
    isHomepal,
    isManagementCompany,
    roles,
    updateUser,
    users
  } = props

  const [editUserId, setEditUserId] = useState<string | null>(null)
  const [deleteUserId, setDeleteUserId] = useState<string | null>(null)
  const [inviteUserDialogOpen, setInviteUserDialogOpen] =
    useState<boolean>(false)

  const userToDelete = useMemo(() => {
    if (deleteUserId) {
      const user = users.find((u) => u.id === deleteUserId)

      return `${user?.first_name || ''} ${user?.last_name || ''}`
    }

    return ''
  }, [deleteUserId])

  const userToEdit = useMemo(() => {
    if (editUserId) {
      return users.find((u) => u.id === editUserId)
    }

    return null
  }, [editUserId])

  return (
    <>
      <Box sx={{ maxWidth: 'content' }}>
        <SettingsGroup
          actions={
            <Button onClick={() => setInviteUserDialogOpen(true)}>
              Lägg till
            </Button>
          }
          title="Användare"
        >
          {users
            .sort((a, b) =>
              `${a.first_name} ${a.last_name}`.localeCompare(
                `${b.first_name} ${b.last_name}`
              )
            )
            .map((user) => (
              <SettingsItem
                actions={
                  <>
                    <IconButton
                      disabled={user.id === currentUser}
                      onClick={() => setEditUserId(user.id)}
                      size="small"
                    >
                      <Icon fontSize="small" name={EDIT_ICON} />
                    </IconButton>
                    <IconButton
                      disabled={user.id === currentUser}
                      onClick={() => setDeleteUserId(user.id)}
                      size="small"
                    >
                      <Icon fontSize="small" name={DELETE_ICON} />
                    </IconButton>
                  </>
                }
                alignItems="center"
                body={user.email}
                icon={
                  <Avatar
                    alt={
                      user.first_name && user.last_name
                        ? user.first_name + ' ' + user.last_name
                        : 'Namnlös'
                    }
                    sx={{
                      width: 32,
                      height: 32
                    }}
                  />
                }
                key={user.id}
                title={
                  user.first_name && user.last_name
                    ? user.first_name + ' ' + user.last_name
                    : 'Namnlös'
                }
                value={
                  <Chip
                    icon={<Icon name={getUserIcon(user)} />}
                    label={
                      user.company_group_id
                        ? companyGroups[user.company_group_id]?.name ||
                          'Koncern borttagen'
                        : TRANSLATE_ROLES[user.role]
                    }
                    size="small"
                  />
                }
              />
            ))}
          <SettingsItem
            color="success"
            onClick={() => setInviteUserDialogOpen(true)}
            title="Lägg till"
            variant="add"
          />
        </SettingsGroup>
      </Box>

      {/* DIALOGS */}
      <EditUserDialog
        email={userToEdit?.email ?? null}
        firstName={userToEdit?.first_name ?? null}
        isHomepal={isHomepal}
        lastName={userToEdit?.last_name ?? null}
        role={userToEdit?.role ?? null}
        onClose={() => setEditUserId(null)}
        updateUser={(body) => {
          if (editUserId) {
            updateUser(editUserId, body)
          }
        }}
        roles={roles}
        open={Boolean(editUserId)}
      />
      <SimpleDialog
        actions={
          <Button variant="text" onClick={() => setInviteUserDialogOpen(false)}>
            Stäng
          </Button>
        }
        onClose={() => setInviteUserDialogOpen(false)}
        open={inviteUserDialogOpen}
        title={'Bjud in en kollega'}
      >
        <AddAccount
          companyGroups={Object.values(companyGroups)}
          onInvite={(
            email: string,
            message: string,
            firstName: string,
            lastName: string,
            role: AccountRole,
            companyGroupId?: string
          ) => {
            createUser({
              data: {
                email,
                first_name: firstName,
                last_name: lastName,
                message,
                role: roles[role].id,
                company_group_id: companyGroupId
              }
            })
          }}
          showInviteCompanyGroup={isManagementCompany}
        />
      </SimpleDialog>
      <DeleteDialog
        open={deleteUserId !== null}
        handleClose={() => setDeleteUserId(null)}
        handleDelete={() => {
          if (deleteUserId) {
            deleteUser(deleteUserId)
          }

          setDeleteUserId(null)
        }}
        type={'användare'}
        title={userToDelete}
      />
    </>
  )
}

export default UsersTemplate
