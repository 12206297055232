import React from 'react'

import Button from 'components_new/atoms/Button'
import {
  calculateTotalAllocation,
  validateTotalAllocation
} from 'types/GlobalAllocationKey'
import Tooltip from 'components_new/atoms/Tooltip'
import Box from 'components_new/atoms/Box'

interface IActionsProps {
  allocations: { [key: string]: string }
  nameInput: string
  isCreating: boolean
  onClose: () => void
  onCreate: () => void
  selectedAccounts: string[]
  step: number
  setStep: (step: number) => void
}

const Actions = (props: IActionsProps) => {
  const validContinue = validateContinue(
    props.step,
    props.nameInput,
    props.selectedAccounts,
    props.allocations
  )

  return (
    <>
      <Button
        variant="text"
        disabled={props.isCreating}
        onClick={
          props.step === 1
            ? () => props.onClose()
            : () => props.setStep(props.step - 1)
        }
        size="large"
        sx={{ mr: 1 }}
      >
        {props.step === 1 ? 'Stäng' : 'Föregående'}
      </Button>
      <Tooltip title={validContinue.tooltip}>
        <Box>
          <Button
            disabled={!validContinue.valid}
            loading={props.isCreating}
            onClick={
              props.step === 3
                ? () => props.onCreate()
                : () => props.setStep(props.step + 1)
            }
            size="large"
          >
            {props.step === 3 ? 'Skapa' : 'Nästa'}
          </Button>
        </Box>
      </Tooltip>
    </>
  )
}

function validateContinue(
  step: number,
  nameInput: string,
  selectedAccounts: string[],
  allocations: { [key: string]: string }
) {
  if (step === 1) {
    const valid = Boolean(nameInput)

    return {
      valid,
      tooltip: valid ? undefined : 'Namnet får inte lämnas tomt.'
    }
  }

  if (step === 2) {
    const valid = selectedAccounts.length > 0

    return {
      valid,
      tooltip: valid ? undefined : 'Minst 1 konto måste väljas för fördelning.'
    }
  }

  const validAllocation = validateTotalAllocation(
    calculateTotalAllocation(allocations)
  )

  return {
    valid: validAllocation.validationPass,
    tooltip: validAllocation.tooltip
  }
}

export default Actions
