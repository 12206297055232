import Axios, { ResponseType } from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'
import { IRealEstate } from 'types/GlobalRealEstate'
import { FileExtension } from 'utils/types'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const URL = `${BASE_URL}v1/sim`

export async function getSIMTables() {
  return Axios.get(URL, parseAuthenticationHeader())
}

export async function exportTable(
  id: string,
  extension: FileExtension,
  queryString: string
) {
  const options = {
    ...parseAuthenticationHeader(),
    responseType: 'blob' as ResponseType
  }

  return Axios.get(`${URL}/${id}/export/${extension}?${queryString}`, options)
}

export async function getSIMData(id: string, queryString: string) {
  return Axios.get(`${URL}/${id}?${queryString}`, parseAuthenticationHeader())
}

export async function getRealEstates() {
  return Axios.get<{ data: IRealEstate[] }>(
    `${URL}/real-estates`,
    parseAuthenticationHeader()
  )
}
