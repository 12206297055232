import React, { useEffect } from 'react'

import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/material'

import { Dispatch, bindActionCreators } from 'redux'
import { ApplicationState } from 'redux/Stores/types'
import { ConnectedProps, connect } from 'react-redux'

import * as DashboardExampleActions from 'redux/actions/DashboardExamples'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'
import Paper from 'components_new/atoms/Paper'

import EmbeddedDashboard from 'components_new/organisms/EmbeddedDashboard'

import Actions from './Actions'
import Author from './Author'
import Content from './Content'
import Header from './Header'
import Systems from './Systems'
import Tags from './Tags'

import Loading from './loading'

import { getScrollbarSx } from 'themes'

interface DashboardExampleProps {
  sx?: object
}

const DashboardExample = (props: ComponentProps) => {
  const { AuthStore, DashboardExampleStore, sx, tryAddDashboardExampleVisit } =
    props

  const params = useParams<{ id: string }>()
  const theme = useTheme()

  const dashboard = DashboardExampleStore.data[params.id]

  useEffect(() => {
    if (dashboard) {
      tryAddDashboardExampleVisit(dashboard.fields.id)
    }
  }, [dashboard])

  if (!dashboard) return <Loading />

  return (
    <>
      <Paper
        sx={{
          height: '100%',
          width: '100%',
          overflowY: 'auto',
          position: 'relative',
          p: 4,
          display: 'flex',
          justifyContent: 'center',
          ...getScrollbarSx(theme),
          ...sx
        }}
      >
        <Box sx={{ height: 'fit-content', maxWidth: 'lg', mb: 4 }}>
          <Header
            createdAt={dashboard.sys.createdAt}
            id={dashboard.fields.id}
            title={dashboard.fields.title}
            totalVisits={dashboard.data.visits.total}
            uniqueVisits={dashboard.data.visits.unique}
            updatedAt={dashboard.sys.updatedAt}
            user={AuthStore.user}
          />

          <EmbeddedDashboard id={dashboard.fields.id} sx={{ mt: 2, mb: 4 }} />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 12
            }}
          >
            <Box sx={{ maxWidth: 'content' }}>
              <Content
                body={dashboard.fields.body}
                hint={dashboard.fields.hint}
                summary={dashboard.fields.summary}
              />
              <Systems systems={dashboard.fields.systems} />
            </Box>

            <Box sx={{ width: 324 }}>
              <Tags
                category={dashboard.fields.category}
                segments={dashboard.fields.segments}
                sx={{ mb: 2 }}
              />

              <Author createdAt={dashboard.sys.createdAt} />

              <Divider sx={{ my: 4 }} />

              <Actions dashboard={dashboard} />
            </Box>
          </Box>
        </Box>
      </Paper>
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  DashboardExampleStore: state.DashboardExampleStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(DashboardExampleActions, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> & DashboardExampleProps

export default connector(DashboardExample)
