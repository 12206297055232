import React, { useEffect, useMemo, useState } from 'react'
import { ApplicationState } from 'redux/Stores/types'
import { ConnectedProps, connect } from 'react-redux'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import TextField from 'components_new/atoms/TextField'

import AccountTree from 'components_new/molecules/AccountTree'
import SidebarDialog from 'components_new/molecules/SidebarDialog'
import SimpleDialog from 'components_new/molecules/SimpleDialog'
import TabSwitch from 'components_new/molecules/TabSwitch'

import {
  calculateTotalAllocation,
  IAllocationKey,
  validateTotalAllocation
} from 'types/GlobalAllocationKey'
import {
  IParsedRealEstateForAllocationKeys,
  parseAllocationBody,
  parseRealEstatesForAllocationKeys
} from 'types/GlobalRealEstate'

import * as AllocationKeyActions from 'redux/actions/AllocationKeys'
import SetAllocationPerRealEstate from 'components_new/molecules/SetAllocationPerRealEstate'
import { formatNumber } from 'utils/numbers'

export enum Tab {
  ALLOCATIONS = 'ALLOCATIONS',
  ACCOUNTS = 'ACCOUNTS'
}

enum Updating {
  NAME = 'NAME',
  ALLOCATIONS = 'ALLOCATIONS',
  ACCOUNTS = 'ACCOUNTS'
}

const translateTabValues: Record<Tab, string> = {
  [Tab.ACCOUNTS]: 'Konton',
  [Tab.ALLOCATIONS]: 'Fördelning'
}

interface IEditAllocationKeyDialogProps {
  onClose: () => void
  allocationKeyId: string | null
}

const EditAllocationKeyDialog = (props: ComponentProps) => {
  const [allocations, setAllocations] = useState<{
    [key: string]: string
  }>({})
  const [isUpdating, setIsUpdating] = useState<Updating | null>(null)
  const [editNameOpen, setEditNameOpen] = useState(false)
  const [nameInput, setNameInput] = useState<string>('')
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([])
  const [tab, setTab] = useState(Tab.ALLOCATIONS)

  const allocationKey = props.allocationKeyId
    ? props.AllocationKeyStore.data[props.allocationKeyId]
    : null

  const disabledAccounts = useMemo(() => {
    return Object.values(props.AllocationKeyStore.data)
      .filter((item) =>
        props.allocationKeyId ? item.id !== props.allocationKeyId : true
      )
      .map((item) => item.accounts)
      .flat()
  }, [props.allocationKeyId])

  useEffect(() => {
    if (allocationKey) {
      const initialAllocationKeys: { [key: string]: string } = {}

      Object.values(props.RealEstateStore.data).forEach((realEstate) => {
        const allocation = allocationKey.allocations.find(
          (item) => item.real_estate_id === realEstate.id
        )

        initialAllocationKeys[realEstate.id] = formatNumber(
          (allocation?.share ?? 0) * 100,
          2
        )
      })

      setAllocations(initialAllocationKeys)
      setSelectedAccounts(allocationKey.accounts)
      setNameInput(allocationKey.name)
    }
  }, [props.allocationKeyId])

  useEffect(() => {
    if (editNameOpen && allocationKey) {
      setNameInput((allocationKey as IAllocationKey).name)
    }
  }, [editNameOpen])

  const parsedRealEstates = useMemo(
    () =>
      parseRealEstatesForAllocationKeys(
        Object.values(props.RealEstateStore.data)
      ),
    [props.RealEstateStore.data]
  )

  const mappedAccounts = useMemo(() => {
    // A real estate company can only have one economy set.
    const map: { [accountNumber: string]: string } = {}
    const set = Object.values(props.EconomySetStore.data)[0]

    if (set) {
      set.accounts.forEach((acc) => {
        if (acc.name) {
          map[acc.number] = acc.name
        }
      })
    }

    return map
  }, [props.EconomySetStore.data])

  const handleOpenEditName = () => {
    setEditNameOpen(true)
  }

  const handleCloseEditName = () => {
    setEditNameOpen(false)
  }

  if (!allocationKey) {
    return null
  }

  return (
    <>
      <SidebarDialog
        actions={
          <Button
            variant="text"
            onClick={props.onClose}
            size="large"
            sx={{ mr: 1 }}
          >
            Stäng
          </Button>
        }
        open={Boolean(allocationKey)}
        title={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              component="span"
              onClick={handleOpenEditName}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                color: 'text.secondary',
                '&:hover': {
                  color: 'text.primary'
                },
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
            >
              <Box
                component="span"
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
              >
                {allocationKey.name}
              </Box>
              <Icon name="ModeEditOutlineOutlined" sx={{ ml: 0.5 }} />
            </Box>
          </Box>
        }
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: 'sm',
            transition: 'max-width 0.5s ease'
          }
        }}
      >
        <TabSwitch
          options={Object.values(Tab).map((value) => ({
            value,
            label: translateTabValues[value]
          }))}
          value={tab}
          onChange={(value) => setTab(value as Tab)}
          color={'primary'}
          sx={{ mt: -2, mb: 2 }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <Button
            disabled={
              isUpdating === Updating.NAME ||
              (tab === Tab.ACCOUNTS && selectedAccounts.length === 0) ||
              (tab === Tab.ALLOCATIONS &&
                !validateTotalAllocation(calculateTotalAllocation(allocations))
                  .validationPass)
            }
            loading={
              isUpdating === Updating.ACCOUNTS ||
              isUpdating === Updating.ALLOCATIONS
            }
            variant="outlined"
            onClick={() => {
              if (tab === Tab.ACCOUNTS) {
                setIsUpdating(Updating.ACCOUNTS)

                props.tryUpdateAllocationKey(
                  allocationKey.id,
                  {
                    accounts: selectedAccounts
                  },
                  () => setIsUpdating(null)
                )
              } else if (tab === Tab.ALLOCATIONS) {
                setIsUpdating(Updating.ALLOCATIONS)

                props.tryUpdateAllocationKey(
                  allocationKey.id,
                  {
                    allocations: Object.entries(allocations).map(
                      ([realEstateId, share]) =>
                        parseAllocationBody(realEstateId, share)
                    )
                  },
                  () => setIsUpdating(null)
                )
              }
            }}
            size="medium"
            startIcon={<Icon name="SaveOutlined" />}
          >
            {`Spara ${translateTabValues[tab].toLowerCase()}`}
          </Button>
        </Box>
        <Body
          allocations={allocations}
          disabledAccounts={disabledAccounts}
          setAllocations={setAllocations}
          mappedAccounts={mappedAccounts}
          tab={tab}
          realEstates={parsedRealEstates}
          selectedAccounts={selectedAccounts}
          setSelectedAccounts={setSelectedAccounts}
        />
      </SidebarDialog>

      {/* Edit account modal */}
      <SimpleDialog
        actions={
          <Button variant="text" onClick={handleCloseEditName}>
            Stäng
          </Button>
        }
        onClose={handleCloseEditName}
        open={editNameOpen}
        title={`Döp om fördelningsnyckel ${allocationKey.name}`}
      >
        <TextField
          fullWidth={true}
          label="Namn"
          autoComplete="off"
          value={nameInput}
          onChange={(event) => setNameInput(event.target.value)}
          sx={{ mt: 1, mb: 2 }}
          required
        />
        <Button
          disabled={nameInput.length === 0}
          fullWidth
          loading={isUpdating === Updating.NAME}
          variant="contained"
          size="large"
          onClick={() => {
            setIsUpdating(Updating.NAME)

            props.tryUpdateAllocationKey(
              allocationKey.id,
              {
                name: nameInput
              },
              (err) => {
                if (!err) {
                  handleCloseEditName()
                }

                setIsUpdating(null)
              }
            )
          }}
        >
          Spara
        </Button>
      </SimpleDialog>
    </>
  )
}

const Body = (props: {
  allocations: { [key: string]: string }
  disabledAccounts: string[]
  mappedAccounts: { [number: string]: string }
  setAllocations: (keys: { [key: string]: string }) => void
  realEstates: IParsedRealEstateForAllocationKeys[]
  selectedAccounts: string[]
  setSelectedAccounts: (values: string[]) => void
  tab: Tab
}) => {
  if (props.tab === Tab.ACCOUNTS) {
    return (
      <AccountTree
        disabledAccounts={props.disabledAccounts}
        mappedAccounts={props.mappedAccounts}
        selected={props.selectedAccounts}
        setSelected={props.setSelectedAccounts}
      />
    )
  }

  return (
    <SetAllocationPerRealEstate
      allocations={props.allocations}
      setAllocations={props.setAllocations}
      realEstates={props.realEstates}
    />
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AllocationKeyStore: state.AllocationKeyStore,
  EconomySetStore: state.EconomySetStore,
  RealEstateStore: state.RealEstateStore
})

const connector = connect(mapStateToProps, { ...AllocationKeyActions })

type ComponentProps = ConnectedProps<typeof connector> &
  IEditAllocationKeyDialogProps

export default connector(EditAllocationKeyDialog)
