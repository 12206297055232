import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useTheme } from '@mui/material'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'

import EmptyPlaceholder from 'components_new/molecules/EmptyPlaceholder'
import SectionHeader from 'components_new/molecules/SectionHeader'
import SelectSearch, { Option } from 'components_new/molecules/SelectSearch'

import EditUserFiltersDialog from './dialogs/EditUserFilterDialog'
import PeriodSelector from './PeriodSelector'

import { connect, ConnectedProps } from 'react-redux'
import { ApplicationState } from 'redux/Stores/types'
import { bindActionCreators, Dispatch } from 'redux'

import * as DashboardFilterOptionActions from 'redux/actions/DashboardFilterOptions'
import * as DashboardActions from 'redux/actions/Dashboards'

import { DashboardFilter } from 'types/GlobalDashboardFilter'
import { AccountRole } from 'types/GlobalUser'
import { CustomPeriodFilter, PeriodFilter } from 'types/GlobalWidget'

import { getPeriodFilterOptions } from './utils'

import Loading from './loading'
import { parseSingleFilterLabel } from '../Widget/WidgetSettings/FilterSection/utils'

interface DashboardUserFilterProps {
  dashboardFilter: DashboardFilter
  periodFilter: PeriodFilter | CustomPeriodFilter | null
  setDashboardFilter: (value: DashboardFilter) => void
  setPeriodFilter: (period: PeriodFilter | CustomPeriodFilter | null) => void
}

const DashboardUserFilter = (props: ComponentProps) => {
  const {
    dashboardFilter,
    periodFilter,
    setDashboardFilter,
    setPeriodFilter,
    // redux stores
    AuthStore,
    DashboardFilterOptionsStore,
    KPIDashboardStore,
    // redux actions
    tryPutDashboardFilterOptions
  } = props

  useEffect(() => {
    const { tryGetDashboardFilterOptions } = props

    if (
      !DashboardFilterOptionsStore.fetched &&
      !DashboardFilterOptionsStore.fetching
    ) {
      tryGetDashboardFilterOptions()
    }
  }, [])

  const theme = useTheme()
  const periodFilterOptions = useMemo(() => getPeriodFilterOptions(), [])

  const [editDashboardUserFilters, setEditDashboardUserFilters] =
    useState<boolean>(false)

  const params = useParams<{ id: string }>()
  const dashboard = KPIDashboardStore.data[params.id]

  const availableDashboardFilterOptions = DashboardFilterOptionsStore.options
  const dashboardFilterOptions = dashboard?.dashboard_filters.map((filter) => {
    const option = DashboardFilterOptionsStore.options.find(
      (opt) => opt.relation_key === filter.relation_key
    )

    return {
      attribute_id: filter.attribute_id,
      title: option?.title || '',
      relation_key: filter.relation_key,
      relation_name: option?.relation_name ?? null,
      index: filter.index,
      options: DashboardFilterOptionsStore.data[filter.relation_key]
    }
  })

  const emptyDashboardFilters = dashboardFilterOptions
    ? dashboardFilterOptions.length === 0
    : true

  const userIsCustomer =
    AuthStore?.user?.is_company_group === undefined
      ? true
      : AuthStore.user.is_company_group

  const group = KPIDashboardStore.groups[dashboard?.dashboard_group_id]
  const ownerId = group?.owner
  const user = AuthStore.user
  const isAdmin = user?.role === AccountRole.ADMIN
  const isHomepal = user?.is_homepal_user
  const isOwner = ownerId === user?.id

  const editable = isAdmin || isHomepal || isOwner

  const hideFilterSection =
    userIsCustomer || (!editable && emptyDashboardFilters)

  // loading
  if (
    !KPIDashboardStore.fetched ||
    !DashboardFilterOptionsStore.fetched ||
    dashboardFilterOptions.some((filter) => filter.options === undefined)
  ) {
    return <Loading />
  }

  // success
  return (
    <>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.leavingScreen
          })
        }}
      >
        {/*-- time period --*/}
        <Box sx={{ p: 2 }}>
          <PeriodSelector
            onSubmit={setPeriodFilter}
            periodFilter={periodFilter}
            periodFilterOptions={periodFilterOptions}
          />
        </Box>

        {/*-- filters --*/}
        {userIsCustomer || hideFilterSection ? null : (
          <Box
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              minHeight: '0px',
              flex: '1 1 auto',
              overflowY: 'auto'
            }}
          >
            {editable && emptyDashboardFilters ? (
              <EmptyPlaceholder
                actions={
                  <Button
                    variant="text"
                    onClick={() => setEditDashboardUserFilters(true)}
                  >
                    Lägg till filter
                  </Button>
                }
                background={true}
                empty={true}
                title="Det finns inga tillagda filter."
              />
            ) : (
              <>
                <SectionHeader
                  actions={
                    <>
                      <Button
                        onClick={() => setDashboardFilter([])}
                        size="small"
                        variant="outlined"
                      >
                        Rensa alla
                      </Button>
                      {editable ? (
                        <IconButton
                          onClick={() => setEditDashboardUserFilters(true)}
                          size="small"
                        >
                          <Icon fontSize="small" name="Edit" />
                        </IconButton>
                      ) : null}
                    </>
                  }
                  divider={true}
                  sx={{ mb: 2 }}
                  title="Filter"
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '0px',
                    flex: '1 1 auto',
                    overflowY: 'auto'
                  }}
                >
                  {dashboardFilterOptions
                    .sort((a, b) => a.index - b.index)
                    .map((filter) => (
                      <SelectSearch
                        key={filter.relation_key}
                        externalLabel={filter.title}
                        onChange={(options: Option[]) => {
                          const existingFilter = dashboardFilter.filter(
                            // eslint-disable-next-line max-len
                            (fil) => fil.relation_key !== filter.relation_key
                          )

                          setDashboardFilter([
                            ...existingFilter,
                            ...options.map((opt) => ({
                              attribute_option_id: null,
                              attribute: filter.attribute_id,
                              relation_key: filter.relation_key,
                              value: opt.id
                            }))
                          ])
                        }}
                        multiple={true}
                        // eslint-disable-next-line max-len
                        options={(filter.options as string[]).map((opt) => ({
                          id: opt,
                          label: parseSingleFilterLabel(opt)
                        }))}
                        selected={dashboardFilter
                          .filter(
                            // eslint-disable-next-line max-len
                            (item) => item.relation_key === filter.relation_key
                          )
                          // eslint-disable-next-line max-len
                          .map((item) => ({
                            id: item.value,
                            label: parseSingleFilterLabel(
                              item.value as string | null
                            )
                          }))}
                        sort={true}
                        sx={{ mb: 2 }}
                      />
                    ))}
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>

      {/*-- dialogs --*/}
      <EditUserFiltersDialog
        availableDashboardFilterOptions={availableDashboardFilterOptions}
        dashboardFilterOptions={dashboardFilterOptions}
        onClose={() => setEditDashboardUserFilters(false)}
        onSave={(options) => {
          tryPutDashboardFilterOptions(dashboard.id, options)
        }}
        open={editDashboardUserFilters}
      />
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  DashboardFilterOptionsStore: state.DashboardFilterOptionsStore,
  KPIDashboardStore: state.KPIDashboardStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...DashboardActions,
      ...DashboardFilterOptionActions
    },
    dispatch
  )
}

type ComponentProps = ConnectedProps<typeof connector> &
  DashboardUserFilterProps

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(DashboardUserFilter)
