import React, { ReactNode, useMemo, useState } from 'react'

import { useMediaQuery, useTheme } from '@mui/material'
import { useHistory } from 'react-router-dom'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'
import Icon, { IconNames } from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Tooltip from 'components_new/atoms/Tooltip'

import OptionsMenu, { OptionProps } from 'components_new/molecules/OptionsMenu'
import SplitButton from 'components_new/molecules/SplitButton'
import TabSwitch from 'components_new/molecules/TabSwitch'

interface DashboardTabsProps {
  dashboard: string // ID
  dashboards: {
    id: string
    title: string
    hasAccess: boolean
  }[]
  editable: boolean
  onAddTab: () => void
  onDelete: (id: string) => void
  onDuplicate: (id: string) => void
  onEditTitle: (id: string) => void
  onMove: (id: string, dir: number) => void
}

const DashboardTabs = (props: DashboardTabsProps) => {
  const {
    dashboard,
    dashboards,
    editable,
    onAddTab,
    onDelete,
    onDuplicate,
    onEditTitle,
    onMove
  } = props

  const history = useHistory()

  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))

  const tabs: {
    value: string
    label: string | ReactNode
    disabled: boolean
    disabledTooltip: string
    menuOptions: OptionProps[] | undefined
  }[] = dashboards.map((dashboard, i) => ({
    value: dashboard.id,
    label: !dashboard.hasAccess ? (
      <>
        <Icon name="VisibilityOffOutlined" sx={{ fontSize: '12px', mr: 1 }} />
        {dashboard.title}
      </>
    ) : (
      dashboard.title
    ),
    disabled: !dashboard.hasAccess,
    disabledTooltip: 'Du saknar tillgång till den här fliken.',
    menuOptions: editable
      ? [
          {
            iconName: 'MoveDownOutlined',
            iconRotation: lgUp ? '90deg' : '180deg',
            onClick: () => onMove(dashboard.id, -1),
            title: `Flytta ${lgUp ? 'vänster' : 'upp'}`,
            disabled: i === 0
          },
          {
            iconName: 'MoveUpOutlined',
            iconRotation: lgUp ? '90deg' : '180deg',
            onClick: () => onMove(dashboard.id, 1),
            title: `Flytta ${lgUp ? 'höger' : 'ner'}`,
            divider: true,
            disabled: i === dashboards.length - 1
          },
          {
            iconName: 'EditOutlined',
            onClick: () => onEditTitle(dashboard.id),
            title: 'Redigera namn'
          },
          {
            iconName: 'ContentCopyOutlined',
            onClick: () => onDuplicate(dashboard.id),
            title: 'Duplicera'
          },
          {
            iconName: 'DeleteOutlined',
            onClick: () => onDelete(dashboard.id),
            title: 'Radera',
            disabled: dashboards.length === 1
          }
        ]
      : undefined
  }))

  const tab = useMemo(() => {
    return tabs.find((tab) => tab.value === dashboard)
  }, [dashboard, tabs])

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  return (
    <Box sx={{ display: 'block' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Divider flexItem={true} orientation="vertical" sx={{ mx: 1 }} />
        {lgUp ? (
          <TabSwitch
            color="primary"
            options={tabs}
            value={dashboard}
            onChange={(id) => history.push(`/dashboards/${id}`)}
            noGutter={true}
            maxHeight="56px"
          />
        ) : (
          <>
            <SplitButton
              color="inherit"
              iconName="MoreVertOutlined"
              onClick={(event) => setAnchorEl(event.currentTarget)}
              options={tab?.menuOptions ?? []}
              size="small"
              title={tab?.label}
              variant="text"
              sx={{
                bgcolor: 'action.focus',
                borderRadius: 12,
                '& .MuiButton-root': {
                  '&:first-child': {
                    pl: 2,
                    pr: 1
                  },
                  '&:last-child': {
                    pl: 0
                  }
                }
              }}
            />

            <OptionsMenu
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              open={open}
              options={[
                ...tabs.map((tab, i) => {
                  return {
                    disabled: tab.disabled,
                    divider: i === tabs.length - 1,
                    onClick: () => history.push(`/dashboards/${tab.value}`),
                    title: tab.label as string
                  }
                }),
                ...(editable
                  ? [
                      {
                        iconName: 'AddOutlined' as IconNames,
                        onClick: () => onAddTab(),
                        title: 'Lägg till flik'
                      }
                    ]
                  : [])
              ]}
            />
          </>
        )}
        {editable && lgUp ? (
          <Box>
            <Tooltip title="Lägg till flik">
              <IconButton onClick={() => onAddTab()} size="small">
                <Icon name="AddCircleOutlineOutlined" />
              </IconButton>
            </Tooltip>
          </Box>
        ) : null}
        <Divider flexItem={true} orientation="vertical" sx={{ mx: 1 }} />
      </Box>
    </Box>
  )
}

export default DashboardTabs
