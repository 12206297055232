import React, { MouseEvent, useState } from 'react'

import Icon, { IconNames } from 'components_new/atoms/Icon'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Menu from 'components_new/atoms/Menu'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import TableCell from 'components_new/atoms/Table/TableCell'
import Text from 'components_new/atoms/Text'
import Link from 'components_new/atoms/Link'

import { ellipseLabel, getCellScaleSx } from '../utils'
import { ParsedSegmentPath } from 'types/GlobalWidget'

interface SegmentCellProps {
  divider: boolean
  label: string
  level: number
  link?: string | null
  maxChars: number | null
  onClick: {
    title: (segments: ParsedSegmentPath[], link?: string | null) => string
    onClick: (segments: ParsedSegmentPath[], link?: string | null) => void
    iconName: IconNames
  }[]
  scaleFactor: number
  segments: ParsedSegmentPath[]
  variant: 'body' | 'footer' | 'head'
}

const SegmentCell = (props: SegmentCellProps) => {
  const {
    divider,
    label,
    link,
    maxChars,
    onClick,
    scaleFactor,
    segments,
    variant
  } = props

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const allowOnClick = onClick.length > 0

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const segmentTextComponent = (
    <Text
      color="inherit"
      component="span"
      fontSize="inherit"
      fontWeight="medium"
      variant="body2"
      // TODO: add better styling for each level
      sx={{
        paddingLeft: (segments.length - 1) * 2
      }}
    >
      {ellipseLabel(label, maxChars)}
    </Text>
  )

  return (
    <>
      <TableCell
        onClick={allowOnClick ? handleClick : undefined}
        sx={{
          borderRight: divider ? '1px solid' : undefined,
          '&:last-child': { borderRight: 0 },
          borderRightColor: 'divider',
          cursor: allowOnClick ? 'pointer' : undefined,
          whiteSpace: 'nowrap',
          width: '1%', // For auto sized, fit content cells. (Hacker).
          '&:hover': allowOnClick
            ? {
                bgcolor: 'action.hover',
                color: 'text.solid',
                textDecoration: 'underline'
              }
            : undefined,
          ...getCellScaleSx(scaleFactor)
        }}
        variant={variant}
      >
        {link ? (
          <Link
            underline="none"
            newTab={true}
            color="inherit"
            sx={{ '&:hover': { color: 'inherit' } }}
          >
            {segmentTextComponent}
          </Link>
        ) : (
          segmentTextComponent
        )}
      </TableCell>

      {/* ----- Menu ----- */}
      <Menu
        anchorEl={anchorEl}
        disableScrollLock={true}
        onClose={handleClose}
        open={open}
      >
        {onClick.map((option, i) => (
          <MenuItem
            key={i}
            onClick={(event) => {
              handleClose(event)

              option.onClick(segments, link)
            }}
            onMouseDown={(event) => event.stopPropagation()}
          >
            <ListItemIcon>
              <Icon name={option.iconName} fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={option.title(segments, link)} />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default SegmentCell
