import React, { useMemo, useState } from 'react'

import SettingsItem, {
  SettingsItemProps
} from 'components_new/molecules/SettingsItem'

import SettingsEditDialog from 'components_new/molecules/SettingsEditDialog'
import { TInputType } from 'components_new/molecules/SettingsEditDialog/utils'

interface SettingsEditItemProps<T>
  extends Omit<SettingsItemProps, 'value' | 'variant'> {
  input: {
    options?: { label: string; value: any }[]
    type: TInputType
    value: T
  }
  loading?: boolean
  onSubmit: (value: T) => void
  renderValue?: (value: T) => string
  unit?: string
}

const SettingsEditItem = <T,>(props: SettingsEditItemProps<T>) => {
  const { input, loading = false, onSubmit, renderValue, ...rest } = props

  const [clearValue, setClearValue] = useState<string>('')
  const [editValue, setEditValue] = useState<string>('')
  const [open, setOpen] = useState<boolean>(false)

  const previewValue = useMemo(() => {
    if (input.value === null) {
      return null
    } else {
      if (renderValue) {
        return renderValue(input.value)
      }

      return input.value?.toString() ?? ''
    }
  }, [input.value])

  // close dialog
  const handleCloseDialog = () => {
    setOpen(false)
  }

  // open dialog
  const handleOpenDialog = () => {
    setOpen(true)

    switch (input.type) {
    case 'decimal':
      const decimalValue = input.value as number | null

      if (decimalValue === 0) {
        setEditValue('0')
      } else {
        setEditValue(decimalValue?.toString() ?? '')
      }

      setClearValue('0')
      break
    case 'number':
      const numberValue = input.value as number | null

      if (numberValue === 0) {
        setEditValue('0')
      } else {
        setEditValue(numberValue?.toString() ?? '')
      }

      setClearValue('0')
      break
    case 'text':
      const textValue = input.value as string | null

      setEditValue(textValue ?? '')
      break
    case 'select':
      const selectValue = input.value as string | null

      setEditValue(selectValue ?? '')
      break
    }
  }

  const handleEditDialogValue = (value: string) => {
    setEditValue(value)
  }

  const handleSubmitDialog = () => {
    switch (input.type) {
    case 'decimal':
      onSubmit(parseFloat(editValue) as T)
      break
    case 'number':
      onSubmit(parseInt(editValue) as T)
      break
    case 'text':
      onSubmit((editValue ?? null) as T)
      break
    case 'select':
      onSubmit((editValue ?? null) as T)
      break
    }
  }

  return (
    <>
      <SettingsItem
        {...rest}
        onClick={handleOpenDialog}
        value={previewValue}
        variant="edit"
      />

      {/*-- dialogs --*/}
      <SettingsEditDialog
        clearValue={clearValue}
        loading={loading}
        onChange={handleEditDialogValue}
        onClose={handleCloseDialog}
        onSubmit={handleSubmitDialog}
        open={open}
        options={input.options}
        title={''}
        type={input.type}
        // unit={unit}
        value={editValue}
        variant="edit"
      />
    </>
  )
}

export default SettingsEditItem
