import React, { useState } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import * as WidgetTagStatusActions from 'redux/actions/WidgetTagStatuses'

import Box from 'components_new/atoms/Box'
import Grid from 'components_new/atoms/Grid'

import SettingsEditItem from 'components_new/molecules/SettingsItem/SettingsEditItem'
import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'
import SettingsPopup from 'components_new/molecules/SettingsPopup'
import SettingsToggleOption from 'components_new/molecules/SettingsToggleOption'

import {
  DEFAULT_STATE,
  getTagOptions,
  isLoading,
  ITagStatusData,
  translateSeverity,
  translateStatusCondition,
  translateStatusDateValue,
  translateStatusValueLabel
} from './utils'

import {
  TagStatusCondition,
  TagStatusDateValue,
  TagStatusSeverity,
  WidgetTagObject
} from 'types/GlobalWidgetTag'
import { DatasetType, WidgetObject } from 'types/GlobalWidget'
import { ApplicationState } from 'redux/Stores/types'

interface AddProps {
  onCancel: () => void
  onClose: () => void
  open: boolean
  tag: WidgetTagObject
  widget: WidgetObject
}

const Add = (props: ComponentProps) => {
  const {
    onCancel,
    onClose,
    open,
    tag,
    TagOptionStore,
    tryCreateWidgetTagStatus,
    widget
  } = props

  const [data, setData] = useState<ITagStatusData>(DEFAULT_STATE)

  const handleCancel = () => {
    onCancel()
    setData(DEFAULT_STATE)
  }

  const handleUpdate = (body: Partial<ITagStatusData>) => {
    const updateBody = {
      ...body
    }

    if (
      body.value === TagStatusDateValue.DAYS_FROM_NOW ||
      body.value === TagStatusDateValue.DAYS_SINCE_NOW
    ) {
      // set default conditional value
      updateBody.conditional_value = data.conditional_value ?? 0
    }

    if (
      body.value === TagStatusDateValue.TODAY ||
      body.value === TagStatusDateValue.TOMORROW ||
      body.value === TagStatusDateValue.YESTERDAY
    ) {
      // remove conditional value
      updateBody.conditional_value = null
    }

    // default
    setData((prevState) => ({
      ...prevState,
      ...body
    }))
  }

  const handleSubmit = () => {
    tryCreateWidgetTagStatus(
      {
        data: {
          ...data,
          value: data.value as string,
          widget_tag_id: tag.id
        }
      },
      widget.dashboard_id,
      widget.id,
      tag.id
    )

    handleCancel()
  }

  const disabled =
    data.value === null ||
    ((data.value === TagStatusDateValue.DAYS_FROM_NOW ||
      data.value === TagStatusDateValue.DAYS_SINCE_NOW) &&
      data.conditional_value === null)

  const options = getTagOptions(tag, TagOptionStore)
  const loading = isLoading(tag, TagOptionStore)

  return (
    <>
      <SettingsPopup
        disabled={disabled}
        disabledTitle={'Du måste välja ett värde för att kunna lägga till.'}
        onCancel={handleCancel}
        onClose={onClose}
        onSubmit={handleSubmit}
        open={open}
        title="Ny indikation"
      >
        <>
          <SettingsGroup helperText="Ange ett villkor som ska uppfyllas.">
            {/*-- condition --*/}
            <SettingsEditItem<TagStatusCondition>
              input={{
                options: tag.settings.status.condition_options.map((option) => {
                  return {
                    label: translateStatusCondition[option][tag.type],
                    value: option
                  }
                }),
                type: 'select',
                value: data.condition
              }}
              onSubmit={(value) => handleUpdate({ condition: value })}
              title={translateStatusValueLabel[tag.type]}
              renderValue={(value) => translateStatusCondition[value][tag.type]}
            />

            {/*-- additional value --*/}
            {tag.type === DatasetType.DATE &&
            (data.value === TagStatusDateValue.DAYS_FROM_NOW ||
              data.value === TagStatusDateValue.DAYS_SINCE_NOW) ? (
                  <SettingsEditItem<number | null>
                    input={{
                      type: 'number',
                      value: data.conditional_value
                    }}
                    onSubmit={(value) => {
                      handleUpdate({ conditional_value: value })
                    }}
                    unit="dagar"
                  />
                ) : null}

            {/*-- value --*/}
            <SettingsEditItem<number | string | TagStatusDateValue | null>
              input={{
                options: options.map((option) => ({
                  label:
                    tag.type === DatasetType.DATE
                      ? translateStatusDateValue[option as TagStatusDateValue]
                      : option,
                  value: option
                })),
                type:
                  tag.type === DatasetType.DATE || tag.type === DatasetType.ENUM
                    ? 'select'
                    : 'text',
                value: data.value
              }}
              loading={loading}
              onSubmit={(value) => handleUpdate({ value: value })}
              renderValue={(value) => {
                if (tag.type === DatasetType.DATE) {
                  return translateStatusDateValue[value as TagStatusDateValue]
                } else {
                  return value?.toString() ?? ''
                }
              }}
            />
          </SettingsGroup>

          {/*-- severity --*/}
          <SettingsGroup helperText="Välj vilken indikator som ska användas då ett villkor är uppfyllt.">
            <SettingsItem>
              <Grid container={true} columns={3}>
                {Object.values(TagStatusSeverity).map((severity) => {
                  const selected = severity === data.severity

                  return (
                    <Grid item={true} key={severity} xs={1}>
                      <SettingsToggleOption
                        onClick={() => handleUpdate({ severity: severity })}
                        selected={selected}
                        title={translateSeverity[severity]}
                      >
                        <Box
                          sx={{
                            width: 32,
                            height: 32,
                            borderRadius: 16,
                            bgcolor: `${severity}.main`,
                            border: '1px solid',
                            borderColor: 'divider',
                            opacity: selected ? 1 : 0.5,
                            transition: 'all 240ms ease 0s',
                            '&:hover': {
                              borderColor: 'action.focus'
                            }
                          }}
                        />
                      </SettingsToggleOption>
                    </Grid>
                  )
                })}
              </Grid>
            </SettingsItem>
          </SettingsGroup>
        </>
      </SettingsPopup>
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  TagOptionStore: state.TagOptionStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(WidgetTagStatusActions, dispatch)
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> & AddProps

export default connector(Add)
