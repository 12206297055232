import { Dispatch } from 'redux'

import * as Types from 'redux/Types'
import * as Api from 'redux/api/AdditionalSegments'

export function tryCreateAdditionalSegmentBy(
  body: {
    widget_id: string
    attribute_option_id: string
  },
  dashboardId: string
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.CREATE_ADDITIONAL_SEGMENT
    })

    Api.create(body)
      .then((response) => {
        dispatch({
          type: Types.CREATE_ADDITIONAL_SEGMENT_SUCCESS,
          payload: { data: response.data.data, dashboardId }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_ADDITIONAL_SEGMENT_FAILED
        })
      })
  }
}

export function tryUpdateAdditionalSegmentBy(
  id: string,
  body: {
    attribute_option_id: string
  },
  dashboardId: string
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_ADDITIONAL_SEGMENT
    })

    Api.update(id, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_ADDITIONAL_SEGMENT_SUCCESS,
          payload: { data: response.data.data, dashboardId }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_ADDITIONAL_SEGMENT_FAILED
        })
      })
  }
}

export function tryDeleteAdditionalSegmentBy(
  id: string,
  widgetId: string,
  dashboardId: string
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.DELETE_ADDITIONAL_SEGMENT
    })

    Api.destroy(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_ADDITIONAL_SEGMENT_SUCCESS,
          payload: { id, widgetId, dashboardId }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_ADDITIONAL_SEGMENT_FAILED
        })
      })
  }
}
