import { createAction, createReducer } from '@reduxjs/toolkit'
import * as Types from 'redux/Types'

import { arrayToObject } from 'utils/functions'
import { IRealEstate } from 'types/GlobalRealEstate'

export type TRealEstateBody = {
  [key: string]: IRealEstate
}

export interface IRealEstateReducerType {
  data: TRealEstateBody
  fetched: boolean
}

// Initial state
const INITIAL_STATE: IRealEstateReducerType = {
  data: {},
  fetched: false
}

// Actions
const getAllSuccessAction = createAction<{ data: IRealEstate[] }>(
  Types.GET_ALL_REAL_ESTATES_SUCCESS
)

const signOutAction = createAction(Types.SIGN_OUT)

// Reducer
const realEstateReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getAllSuccessAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: arrayToObject<IRealEstate>(payload.data),
        fetched: true
      }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export default realEstateReducer
