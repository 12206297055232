import Axios from 'axios'

import { parseAuthenticationHeader } from 'helpers/Functions'

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

const DASHBOARD_URL = `${BASE_URL}v1/dashboards`

export async function getDashboardExamples() {
  return Axios.get(`${DASHBOARD_URL}/examples`, parseAuthenticationHeader())
}

const URL = `${BASE_URL}v1/example-dashboard-visits`

export async function addVisit(body: { data: { dashboard_id: string } }) {
  return Axios.post(URL, body, parseAuthenticationHeader())
}
