import React, { ChangeEvent } from 'react'

import TextField from 'components_new/atoms/TextField'

interface NumberFieldProps {
  label?: string
  onChange: (value: string) => void
  precision?: number
  size?: 'small' | 'medium'
  sx?: object
  type?: 'number' | 'decimal'
  value: string
}

const restrictInput = (
  value: string,
  type: 'number' | 'decimal',
  precision?: number
) => {
  if (type === 'number') {
    // Only allow digits for 'number' type
    return value.replace(/[^0-9]/g, '')
  }

  if (type === 'decimal') {
    // Allow numbers and a single comma (no period)
    const parsedValue = value
      .replace(/[^0-9,.]/g, '') // Remove any non-numeric and non-comma characters
      .replace(/\./g, ',') // replace . with ,
      .replace(/[,](?=.*[,])/g, '') // Prevent multiple commas

    if (precision !== undefined && parsedValue.includes(',')) {
      const [integerPart, decimalPart] = parsedValue.split(',')

      // Restrict the decimal part to the specified precision
      const restrictedDecimalPart = decimalPart.slice(0, precision)

      return `${integerPart},${restrictedDecimalPart}`
    }

    return parsedValue
  }

  return value
}

const NumberField = (props: NumberFieldProps) => {
  const {
    label,
    onChange,
    precision,
    size = 'medium',
    type = 'number',
    value
  } = props

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = restrictInput(e.target.value, type, precision)

    onChange(value)
  }

  return (
    <TextField
      autoFocus={true}
      label={label}
      onChange={handleChange}
      size={size}
      type={'text'}
      value={value}
    />
  )
}

export default NumberField
