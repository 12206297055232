import React, { useState, useRef, useEffect } from 'react'
import { Layout } from 'react-grid-layout'

import Dashboard from 'components_new/organisms/Dashboard'

import DashboardTemplate from 'components_new/templates/DashboardTemplate'

import { DashboardFilter } from 'types/GlobalDashboardFilter'

import * as AccountStoreActions from 'redux/actions/Accounts'
import * as DashboardFolderStoreActions from 'redux/actions/DashboardFolders'
import * as KPIDashboardStoreActions from 'redux/actions/Dashboards'
import * as WidgetActions from 'redux/actions/Widgets'
import * as CompanyGroupActions from 'redux/actions/CompanyGroups'

import { CustomPeriodFilter, PeriodFilter } from 'types/GlobalWidget'
import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { ApplicationState } from 'redux/Stores/types'
import { useParams } from 'react-router-dom'
import { getDataForWidgets } from 'components_new/organisms/Dashboard/utils'
import { usePrevious } from 'helpers/Functions'

const DashboardPage = (props: ComponentProps) => {
  const params = useParams<{ id: string }>()
  const dashboard = props.KPIDashboardStore.data[params.id]
  const previousShowAs = usePrevious(props.CompanyGroupStore.showAs)

  // states
  const [dashboardFilter, setDashboardFilter] = useState<DashboardFilter>([])
  const [periodFilter, setPeriodFilter] = useState<
    PeriodFilter | CustomPeriodFilter | null
  >(null)

  const [availableSpace, setAvailableSpace] = useState<Layout | false>(false)

  const gridRef = useRef(null)

  useEffect(() => {
    const {
      AccountStore,
      CompanyGroupStore,
      DashboardFolderStore,
      KPIDashboardStore,
      tryGetAllAccounts,
      tryGetAllCompanyGroups,
      tryGetAllDashboards,
      tryGetAllDashboardFolders
    } = props

    if (!CompanyGroupStore.fetched && !CompanyGroupStore.fetching) {
      tryGetAllCompanyGroups()
    }

    if (!AccountStore.fetched) {
      tryGetAllAccounts()
    }

    if (!DashboardFolderStore.fetched) {
      tryGetAllDashboardFolders()
    }

    if (!KPIDashboardStore.fetched && !KPIDashboardStore.fetching) {
      tryGetAllDashboards()
    }
  }, [])

  // If we change tab, open a dashboard or the dashboard
  // is fetched we update dashboard data and reset filters.
  useEffect(() => {
    if (dashboard) {
      setDashboardFilter([])
      setPeriodFilter(null)
      getDataForWidgets(
        props.tryGetOneWidget,
        dashboard,
        [],
        null,
        props.CompanyGroupStore.showAs,
        true
      )
    }
  }, [props.KPIDashboardStore.activeFetchOne])

  // If a dashboard is open and user change company group, update dashboard.
  useEffect(() => {
    const parsedPrevious = previousShowAs || null

    if (dashboard && parsedPrevious !== props.CompanyGroupStore.showAs) {
      getDataForWidgets(
        props.tryGetOneWidget,
        dashboard,
        dashboardFilter,
        periodFilter,
        props.CompanyGroupStore.showAs
      )
    }
  }, [props.CompanyGroupStore.showAs])

  const handleSetPeriodFilter = (
    period: PeriodFilter | CustomPeriodFilter | null
  ) => {
    setPeriodFilter(period)

    getDataForWidgets(
      props.tryGetOneWidget,
      dashboard,
      dashboardFilter,
      period,
      props.CompanyGroupStore.showAs,
      false,
      true
    )
  }

  const handleSetDashboardFilter = (
    newDashboardFilter: DashboardFilter,
    newPeriodFilter?: CustomPeriodFilter
  ) => {
    const period =
      newPeriodFilter === undefined ? periodFilter : newPeriodFilter

    getDataForWidgets(
      props.tryGetOneWidget,
      dashboard,
      newDashboardFilter,
      period,
      props.CompanyGroupStore.showAs
    )

    setDashboardFilter(newDashboardFilter)

    if (newPeriodFilter !== undefined) {
      setPeriodFilter(newPeriodFilter)
    }
  }

  return (
    <DashboardTemplate
      // @TODO: Remove template props in a later release:
      availableSpace={availableSpace}
      dashboardFilter={dashboardFilter}
      gridRef={gridRef}
      periodFilter={periodFilter}
      setDashboardFilter={handleSetDashboardFilter}
      setPeriodFilter={handleSetPeriodFilter}
    >
      <Dashboard
        availableSpace={availableSpace}
        dashboardFilter={dashboardFilter}
        gridRef={gridRef}
        periodFilter={periodFilter}
        setAvailableSpace={setAvailableSpace}
        setDashboardFilter={handleSetDashboardFilter}
        setPeriodFilter={handleSetPeriodFilter}
      />
    </DashboardTemplate>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AccountStore: state.AccountStore,
  DashboardFolderStore: state.DashboardFolderStore,
  CompanyGroupStore: state.CompanyGroupStore,
  KPIDashboardStore: state.KPIDashboardStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AccountStoreActions,
      ...CompanyGroupActions,
      ...DashboardFolderStoreActions,
      ...KPIDashboardStoreActions,
      ...WidgetActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type ComponentProps = ConnectedProps<typeof connector>

export default connector(DashboardPage)
