import { TNavigationItem } from 'components_new/molecules/NavigationItem'
import { AuthenticationState } from 'types/GlobalAuthentication'
import { AccountRole } from 'types/GlobalUser'

export const getNavigationItems = (
  AuthStore: AuthenticationState
): TNavigationItem[] => {
  return [
    {
      href: '/access-hub',
      icon: 'ApiOutlined',
      title: 'API',
      // hidden if access hub is not in license or user is not
      // admin/access-hub/homepal
      hidden:
        !AuthStore.customer?.allow_access.access_hub ||
        !(
          AuthStore.user?.role === AccountRole.ADMIN ||
          AuthStore.user?.role === AccountRole.ACCESS_HUB ||
          AuthStore.user?.is_homepal_user
        )
    },
    {
      activePaths: ['explore'],
      icon: 'TravelExploreOutlined',
      href: '/explore',
      title: 'Utforska'
    },
    {
      activePaths: ['settings'],
      divider: true,
      href: '/admin/settings/customization',
      icon: 'SettingsOutlined',
      title: 'Inställningar',
      // hidden if user is not admin
      hidden: AuthStore.user?.role !== AccountRole.ADMIN
    },
    {
      activePaths: ['data-platform'],
      href: '/data-platform/ontology/standard',
      icon: 'EngineeringOutlined',
      title: 'Admin',
      // hidden if user is not Homepal
      hidden: !AuthStore.user?.is_homepal_user
    }
  ]
}
