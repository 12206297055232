import React from 'react'

import { getStatus } from '../utils'

import BodyCell from '../BodyCell'
import SegmentCell from '../SegmentCell'

import { IconNames } from 'components_new/atoms/Icon'
import {
  DatasetType,
  DefaultDatasetData,
  FormattedWidgetDataDataset,
  ParsedSegmentPath,
  ParsedWidgetDataLabel,
  WidgetObject
} from 'types/GlobalWidget'
import { getCustomPeriod } from '../../utils'
import { getHierarchyForSegment } from 'components_new/organisms/Widget/dialogs/ExploreDialog/utils'
import { KpiOptionObject } from 'types/GlobalKpiOption'

interface BodyRowProps {
  datasets: FormattedWidgetDataDataset[]
  embedded: boolean
  isComparativeButIllegal: boolean
  label: ParsedWidgetDataLabel
  level?: number
  link: string | null
  row: number
  onClickSegment: {
    title: (segments: ParsedSegmentPath[]) => string
    onClick: (segments: ParsedSegmentPath[]) => void
    iconName: IconNames
  }[]
  onClickValue: {
    [kpiOptionId: string]:
      | ((
          dataLabel: ParsedWidgetDataLabel,
          path: ParsedSegmentPath[],
          breakdown: string | number | null,
          customPeriod?: { fromDate: string; toDate: string }
        ) => void)
      | null
  }
  scaleFactor: number
  segmentMaxChars: number | null
  verticalDividers: boolean
  widget: WidgetObject
}

const BodyRow = (props: BodyRowProps) => {
  const {
    datasets,
    embedded,
    isComparativeButIllegal,
    label,
    level = 0,
    link,
    onClickSegment,
    onClickValue,
    row,
    scaleFactor,
    segmentMaxChars,
    verticalDividers,
    widget
  } = props

  // add current segment label to path (used with filters)
  const segments: ParsedSegmentPath[] = [
    ...label.path,
    {
      ...label,
      attribute_option_id: label.attribute_option_id,
      label: label.label,
      period: label.period,
      segment_options: label.segment_options
    }
  ]

  return (
    <>
      <SegmentCell
        divider={verticalDividers}
        label={label.display_label}
        level={level}
        link={embedded ? undefined : link}
        onClick={onClickSegment}
        scaleFactor={scaleFactor}
        segments={segments}
        maxChars={segmentMaxChars}
        variant="body"
      />
      {datasets.map((dataset, column) => {
        const increaseIsPositive = dataset.increaseIsPositive
        const lowerThresholdValue = dataset.lowerBoundThreshold
        // @TODO: Make unit optional in next release (or move to head):
        const unit = dataset.prettyData[row] !== null ? dataset.unit : ''
        const upperThresholdValue = dataset.upperBoundThreshold
        const prettyValue = dataset.prettyData[row] ?? ''
        const showDiff = Boolean(dataset.comparativeDisplayTypeSelected)
        const percentageDiff = dataset.percentages
          ? dataset.percentages[row]
          : undefined
        const status = dataset.statuses[row]
        // const numberOfCharacters = dataset.numberOfCharacters

        const kpiOption = widget.settings.kpi_options.find(
          (ko) => ko.id === dataset.kpiOptionId
        )
        const segmentAttributeOption = kpiOption
          ? label.segment_options[kpiOption.id]
          : null

        const hierarchy = segmentAttributeOption
          ? getHierarchyForSegment(
              kpiOption as KpiOptionObject,
              segmentAttributeOption
          )
          : []

        const onClickFunc =
          dataset.kpiOptionId &&
          onClickValue[dataset.kpiOptionId] &&
          hierarchy.length > 0
            ? onClickValue[dataset.kpiOptionId]
            : null

        const customPeriod = getCustomPeriod(dataset, widget)

        const onClick =
          onClickFunc && !embedded
            ? () =>
                onClickFunc(
                  label,
                  label.path,
                  dataset.breakdownLabel,
                  customPeriod
                )
            : undefined

        if (dataset.type === DatasetType.NUMBER) {
          return (
            <BodyCell
              allowOnClick={!embedded}
              key={`row-${row}-column-${column}`}
              color={
                upperThresholdValue !== null
                  ? `${getStatus(
                    (dataset.data as DefaultDatasetData)[row],
                    upperThresholdValue,
                    lowerThresholdValue,
                    increaseIsPositive
                  )}.text`
                  : 'text.primary'
              }
              divider={verticalDividers}
              increaseIsPositive={increaseIsPositive}
              isComparativeButIllegal={isComparativeButIllegal}
              label={prettyValue + ' ' + unit} // @TODO: Unit rework.
              onClick={onClick}
              percentageDiff={percentageDiff}
              scaleFactor={scaleFactor}
              segmentLabel={label.display_label}
              status={status}
              showDiff={showDiff}
              type={dataset.type}
            />
          )
        } else if (dataset.type === DatasetType.NUMBER_TAG) {
          return (
            <BodyCell
              divider={verticalDividers}
              type={dataset.type}
              label={(prettyValue as string[]).map((val) =>
                unit ? `${val} ${unit}` : val
              )}
              onClick={onClick}
              scaleFactor={scaleFactor}
              status={status}
              // numberOfCharacters={numberOfCharacters}
              key={`row-${row}-column-${column}`}
            />
          )
        } else {
          return (
            <BodyCell
              divider={verticalDividers}
              label={prettyValue as string[]}
              onClick={onClick}
              scaleFactor={scaleFactor}
              status={status}
              type={dataset.type}
              key={`row-${row}-column-${column}`}
            />
          )
        }
      })}
    </>
  )
}

export default BodyRow
