import React, { useState } from 'react'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import { ConnectedProps, connect } from 'react-redux'
import { ApplicationState } from 'redux/Stores/types'

import Button from 'components_new/atoms/Button'
import Portal from 'components_new/atoms/Portal'
import Snackbar from 'components_new/atoms/Snackbar'
import Text from 'components_new/atoms/Text'
import TextField from 'components_new/atoms/TextField'

import SectionHeader from 'components_new/molecules/SectionHeader'
import SimpleDialog from 'components_new/molecules/SimpleDialog'

import { description, title } from './content'
import { sendMessageToSlack } from './utils'

interface RequestDashboardExampleDialogProps {
  onClose: () => void
  open: boolean
}

const RequestDashboardExampleDialog = (props: ComponentProps) => {
  const { AuthStore, onClose, open } = props

  const [data, setData] = useState<{ description: string; title: string }>({
    description: '',
    title: ''
  })

  const handleUpdate = (key: 'description' | 'title', value: string) => {
    setData((prevState) => ({
      ...prevState,
      [key]: value
    }))
  }

  const [submitSuccess, setSubmitSuccess] = useState<boolean>(false)

  const handleSubmit = () => {
    const email = AuthStore.user?.email ?? ''

    sendMessageToSlack(data, email)
      .then(() => {
        onClose()
        setSubmitSuccess(true)
      })
      .catch((error) => console.log(error))
  }

  return (
    <>
      <ThemeProvider theme={getTheme('light')}>
        <SimpleDialog
          actions={
            <>
              <Button onClick={onClose} variant="text">
                Avbryt
              </Button>
              <Button
                disabled={!Boolean(data.title) || !Boolean(data.description)}
                onClick={handleSubmit}
              >
                Skicka förfrågan
              </Button>
            </>
          }
          maxWidth="xs"
          onClose={onClose}
          open={open}
          title={title}
        >
          <Text color="text.secondary" variant="body1">
            {description}
          </Text>
          <SectionHeader title={'Rubrik'} sx={{ mt: 2 }} />
          <TextField
            helperText={`${data.title.length}/${50}`}
            InputProps={{
              inputProps: {
                maxLength: 50
              }
            }}
            onChange={(event) => handleUpdate('title', event.target.value)}
            placeholder={
              'Ex. Benchmarking av felanmälningar i våra fastigheter'
            }
            sx={{
              mt: 0,
              mb: 2,
              '& .MuiFormHelperText-root': {
                textAlign: 'right'
              }
            }}
            value={data.title}
          />
          <SectionHeader title={'Beskrivning'} />
          <TextField
            helperText={`${data.description.length}/${500}`}
            InputProps={{
              inputProps: {
                maxLength: 500
              }
            }}
            multiline={true}
            onChange={(event) =>
              handleUpdate('description', event.target.value)
            }
            placeholder={
              'Ex. Det hade varit intressant att få inspiration på hur man kan sätta upp en dashboard för att se hur felanmälningar ser ut i våra fastigheter – och framförallt jämföra dem mot varandra. Vilka nyckeltal och vilka visualiseringar är relevanta?'
            }
            rows={6}
            sx={{
              mt: 0,
              '& .MuiFormHelperText-root': {
                textAlign: 'right'
              }
            }}
            value={data.description}
          />
        </SimpleDialog>
      </ThemeProvider>

      {/*-- snackbars --*/}
      <Portal>
        <Snackbar
          open={submitSuccess}
          autoHideDuration={3000}
          onClose={(event, reason) => {
            if (reason !== 'clickaway') {
              setSubmitSuccess(false)
            }
          }}
          message="Förfrågan skickad"
        />
      </Portal>
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore
})

const connector = connect(mapStateToProps)

type ComponentProps = ConnectedProps<typeof connector> &
  RequestDashboardExampleDialogProps

export default connector(RequestDashboardExampleDialog)
