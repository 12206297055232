import React, { useEffect, useMemo, useState } from 'react'

import { WidgetObject, WidgetType } from 'types/GlobalWidget'

import SettingsDrawer from 'components_new/molecules/SettingsDrawer'
import SettingsEditDialog from 'components_new/molecules/SettingsEditDialog'
import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'

import { getOptions } from './utils'
import { AdditionalSegment } from 'types/GlobalAdditionalSegment'

interface AdditionalSegmentSectionProps {
  createAdditionalSegmentBy: (attributeOptionId: string) => void
  deleteAdditionalSegmentBy: (additionalSegmentId: string) => void
  onClose: () => void
  updateAdditionalSegmentBy: (
    additionalSegmentId: string,
    attributeOptionId: string
  ) => void
  widget: WidgetObject
}

const LEGAL_TYPES = [WidgetType.LIST]

const AdditionalSegmentSection = (props: AdditionalSegmentSectionProps) => {
  const {
    createAdditionalSegmentBy,
    deleteAdditionalSegmentBy,
    onClose,
    updateAdditionalSegmentBy,
    widget
  } = props

  if (!LEGAL_TYPES.includes(widget.settings.type.selected)) {
    return null
  }

  /*-- drawer --*/
  const [drawer, setDrawer] = useState<{
    open: boolean
    additionalSegmentId: string | null
    attributeOptionId: string | null
  }>({
    open: false,
    additionalSegmentId: null,
    attributeOptionId: null
  })

  const handleBackDrawer = () => {
    setDrawer((prevState) => ({
      ...prevState,
      additionalSegmentId: null,
      open: false
    }))
  }

  const handleCloseDrawer = () => {
    handleBackDrawer()
    onClose()
  }

  const handleOpenDrawer = (id: string) => {
    const additionalSegment = widget.settings.segment_by.additional.find(
      (additional) => additional.id === id
    ) as AdditionalSegment

    setDrawer({
      open: true,
      additionalSegmentId: id,
      attributeOptionId: additionalSegment.attribute_option_id
    })
  }

  useEffect(() => {
    if (drawer.additionalSegmentId) {
      const additionalSegment = widget.settings.segment_by.additional.find(
        (additional) => additional.id === drawer.additionalSegmentId
      ) as AdditionalSegment

      setDrawer({
        ...drawer,
        attributeOptionId: additionalSegment.attribute_option_id
      })
    }
  }, [widget])

  const title = useMemo(
    () =>
      widget.settings.segment_by.options.find(
        (option) => option.id === drawer.attributeOptionId
      )?.name ?? '',
    [drawer]
  )

  /*-- dialogs --*/
  const [dialog, setDialog] = useState<{
    open: boolean
    title: string
    variant: 'add' | 'edit'
    data: {
      value: string
    }
  }>({
    open: false,
    variant: 'add',
    title: '',
    data: {
      value: ''
    }
  })

  const handleCloseDialog = () => {
    setDialog((prevState) => ({ ...prevState, open: false }))
  }

  const handleOpenDialog = (variant: 'add' | 'edit') => {
    let inputValue = ''

    if (variant === 'edit') {
      inputValue =
        widget.settings.segment_by.options.find(
          (option) => option.id === drawer.attributeOptionId
        )?.id ?? ''
    }

    setDialog({
      open: true,
      title: 'Ytterligare segmentering',
      variant: variant,
      data: {
        value: inputValue
      }
    })
  }

  const handleEditDialogValue = (value: string) => {
    setDialog((prevState) => ({
      ...prevState,
      data: { ...prevState.data, value }
    }))
  }

  const handleSubmitDialog = () => {
    switch (dialog.variant) {
    case 'add':
      createAdditionalSegmentBy(dialog.data.value)
      break
    case 'edit':
      if (drawer.additionalSegmentId) {
        updateAdditionalSegmentBy(
          drawer.additionalSegmentId,
          dialog.data.value
        )
      }
      break
    }
  }

  const handleDelete = () => {
    if (drawer.additionalSegmentId) {
      deleteAdditionalSegmentBy(drawer.additionalSegmentId)
      handleBackDrawer()
    }
  }

  return (
    <>
      <SettingsGroup
        helperText={
          'När du visar data i en tabell kan du fortsätta att dela upp din segmentering. Du kan lägga till upp till 3 ytterliggare segmenteringar.'
        }
        title={'Ytterligare segmentering'}
      >
        {widget.settings.segment_by.additional.map((additional, i) => {
          const label =
            widget.settings.segment_by.options.find(
              (option) => option.id === additional.attribute_option_id
            )?.name ?? ''

          return (
            <SettingsItem
              indent={2 * (i + 1)}
              key={additional.id}
              onClick={() => handleOpenDrawer(additional.id)}
              title={label}
            />
          )
        })}
        {widget.settings.segment_by.additional.length < 3 ? (
          <SettingsItem
            color="success"
            indent={2 * (widget.settings.segment_by.additional.length + 1)}
            onClick={() => handleOpenDialog('add')}
            title="Lägg till"
            variant="add"
          />
        ) : null}
      </SettingsGroup>

      {/*-- drawer --*/}
      <SettingsDrawer
        open={drawer.open}
        onBack={handleBackDrawer}
        onClose={handleCloseDrawer}
        title={'Ytterligare segmentering'}
      >
        {drawer.additionalSegmentId ? (
          <>
            <SettingsGroup>
              <SettingsItem
                onClick={() => handleOpenDialog('edit')}
                title={'Segmentering'}
                value={title}
                variant="edit"
              />
            </SettingsGroup>

            <SettingsGroup>
              <SettingsItem
                color="error"
                onClick={handleDelete}
                title="Radera"
                variant="action"
              />
            </SettingsGroup>
          </>
        ) : null}
      </SettingsDrawer>

      {/*-- dialogs --*/}
      <SettingsEditDialog
        onChange={handleEditDialogValue}
        onClose={handleCloseDialog}
        onSubmit={handleSubmitDialog}
        open={dialog.open}
        options={getOptions(widget)}
        title={dialog.title}
        type={'select'}
        value={dialog.data.value}
        variant={dialog.variant}
      />
    </>
  )
}

export default AdditionalSegmentSection
