import { Theme } from '@mui/material'

import {
  ChartOptions,
  ScriptableContext,
  TooltipItem,
  Element,
  ChartEvent,
  ActiveElement,
  ScriptableTooltipContext
} from 'chart.js'
import { Context } from 'chartjs-plugin-datalabels'

import {
  getThresholdLines,
  Value,
  getColor,
  getDiffLabel,
  getDiffColor,
  formatAxisValue,
  getTooltipLabel,
  isForecast,
  getColorIndexAndAlpha,
  forecastIsActive,
  getLegendStyles,
  getBorderDash,
  getBorderWidth
} from '../utils'

import {
  BreakdownByDisplayType,
  DataType,
  DefaultDatasetData,
  FormattedWidgetData,
  ParsedWidgetDataLabel,
  WidgetObject
} from 'types/GlobalWidget'
import { ComparativeDisplayType } from 'types/GlobalKpiOption'

type ComboChartOptions = ChartOptions<'bar'> & {
  plugins: {
    horizontalLines: { lines: { value: number; color: string }[] }
    datalabels: {
      align: (context: Context) => string | number
      anchor: 'end' | 'start' | 'center'
      clamp: boolean
      clip: boolean
      color: (cont: Context) => string
      display: (context: Context) => boolean
      offset: (context: Context) => number
      font: (cont: Context) => {
        family: string
        size: number
        weight: number
      }
      rotation: (context: Context) => number
      formatter: (value: number, context: Context) => string
    }
  }
}

interface ElementWithPercentage extends Element {
  percentage: Value
}

interface DatasetContext extends ScriptableContext<'bar'> {
  element: ElementWithPercentage
}

export interface CustomTooltipItem extends TooltipItem<'bar'> {
  dataset: {
    data: number[]
    hasBreakdown: boolean
    label: string
    valueIsPercentageDiff: boolean
    yAxisID: string
  }
}

export const getOptions = (
  widget: WidgetObject,
  hasBreakdown: boolean,
  theme: Theme,
  allowOnClick: boolean,
  scaleFactor: number,
  formattedData: FormattedWidgetData,
  isComparativeButIllegal: boolean
): ComboChartOptions => {
  // Widget settings
  const invertYAxis = widget.settings.invert_y_axis
  const maxValue = widget.settings.max_value ?? undefined
  const minValue = widget.settings.min_value ?? undefined
  const labelChars = widget.settings.label_chars
  const breakdownByDisplayType = widget.settings.breakdown_by_display_type
  const stacked =
    breakdownByDisplayType === BreakdownByDisplayType.STACKED ||
    breakdownByDisplayType === BreakdownByDisplayType.STACKED_100_PERCENT
  const stacked100 =
    breakdownByDisplayType === BreakdownByDisplayType.STACKED_100_PERCENT

  // Bar chart dataset settings
  const barChartDataset = formattedData.datasets.find(
    (item) => item.settingIndex === 0
  )
  const barChartLabel = barChartDataset?.label || ''
  const barChartUnit = barChartDataset?.unit || null

  // Line chart dataset settings
  const lineChartDataset = formattedData.datasets.find(
    (item) => item.settingIndex === 1
  )
  const lineChartLabel = lineChartDataset?.label || ''
  const lineChartUnit = lineChartDataset?.unit || null

  return {
    onHover: (event: ChartEvent, elements: ActiveElement[]) => {
      const element = event.native?.target as HTMLElement

      element.style.cursor =
        elements.length === 1 && allowOnClick ? 'pointer' : 'default'
    },
    plugins: {
      legend: {
        display: formattedData.datasets.length > 1,
        ...getLegendStyles(scaleFactor, theme, 'bar')
      },
      stacked100: {
        enable: stacked100,
        replaceTooltipLabel: false,
        axisId: 'y'
      },
      horizontalLines: getThresholdLines(theme, widget),
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (item) => getTooltipLabel(item, hasBreakdown),
          footer: (items: TooltipItem<'bar'>[]) => {
            const dataset = formattedData.datasets.find(
              (item) => item.index === items[0].datasetIndex
            )

            if (dataset?.percentages) {
              return getDiffLabel(
                dataset.percentages[items[0].parsed.x] ?? null,
                isComparativeButIllegal
              )
            }

            return ''
          }
        },
        footerColor: (context: ScriptableTooltipContext<'bar'>) => {
          const dataset = formattedData.datasets.find(
            (item) => item.index === context.tooltipItems[0]?.datasetIndex
          )

          if (dataset?.percentages) {
            return getDiffColor(
              dataset.percentages[context.tooltip.dataPoints[0].parsed.x] ??
                null,
              dataset.increaseIsPositive,
              theme
            )
          }

          return '#fff'
        }
      },
      datalabels: {
        align: function (context: Context) {
          const dataset = formattedData.datasets.find(
            (item) => item.index === context.datasetIndex
          )

          if (dataset?.percentages && dataset.percentages.length > 8) {
            return -45
          }

          return 'end'
        },
        anchor: 'end',
        clamp: true,
        clip: false,
        color: function (context: Context) {
          const dataset = formattedData.datasets.find(
            (item) => item.index === context.datasetIndex
          )
          let color = '#fff'

          if (dataset?.percentages) {
            color =
              getDiffColor(
                dataset.percentages[context.dataIndex],
                dataset.increaseIsPositive,
                theme
              ) ?? '#fff'
          }

          return color
        },
        display: (context: Context) => {
          const dataset = formattedData.datasets.find(
            (item) => item.index === context.datasetIndex
          )

          return !!dataset?.percentages
        },
        offset: function (context: Context) {
          const dataset = formattedData.datasets.find(
            (item) => item.index === context.datasetIndex
          )

          if (dataset?.percentages && dataset.percentages.length > 8) {
            return -10
          }

          return 0
        },
        font: function (context: Context) {
          const dataset = formattedData.datasets.find(
            (item) => item.index === context.datasetIndex
          )
          let size = 10

          if (dataset?.percentages) {
            if (dataset.percentages.length > 8) {
              size = context.chart.width * 0.0075
            } else {
              size = context.chart.width * 0.02
            }
          }

          return {
            family: 'IBM Plex Sans',
            size: size,
            weight: 600
          }
        },
        rotation: function (context: Context) {
          const dataset = formattedData.datasets.find(
            (item) => item.index === context.datasetIndex
          )

          if (dataset?.percentages && dataset.percentages.length > 8) {
            return -45
          }

          return 0
        },
        formatter: function (value: number, context: Context) {
          const dataset = formattedData.datasets.find(
            (item) => item.index === context.datasetIndex
          )

          if (dataset?.percentages) {
            return getDiffLabel(
              dataset.percentages[context.dataIndex],
              isComparativeButIllegal
            )
          }

          return ''
        }
      }
    },
    layout: {
      padding: function (context: Context) {
        const dataset = formattedData.datasets.find(
          (item) => item.index === context.datasetIndex
        )

        let size = 0

        if (dataset?.percentages) {
          size = context.chart.width * 0.03
        }

        return size
      }
    },
    elements: {
      bar: {
        borderRadius: 0 // 2?
      },
      point: {
        borderWidth: function (context: any) {
          const scale = 0.001 * context.chart.width

          return 0.25 * scale
        },
        radius: function (context: any) {
          const scale = 0.001 * context.chart.width

          return 8 * scale
        },
        hoverBorderWidth: function (context: any) {
          const scale = 0.001 * context.chart.width

          return 0.75 * scale
        },
        hoverRadius: function (context: any) {
          const scale = 0.001 * context.chart.width

          return 8 * scale
        }
      }
    },
    interaction: {
      intersect: true
    },
    scales: {
      x: {
        stacked,
        grid: {
          display: false
        },
        border: {
          display: false
        },
        ticks: {
          callback: function (this, value): string {
            const label =
              typeof value === 'number' ? this.getLabelForValue(value) : ''

            if (!!labelChars && label?.length > labelChars) {
              return `${label.substring(0, labelChars)}...`
            }

            return label
          },
          font: {
            size() {
              return 16 * scaleFactor
            }
          },
          color: theme.palette.text.secondary
        }
      },
      y: {
        stacked,
        position: 'left',
        reverse: invertYAxis,
        display: true,
        grid: {
          color: theme.palette.divider
        },
        border: {
          display: false
        },
        title: {
          display: lineChartDataset ? true : false,
          text: lineChartDataset ? barChartLabel : '',
          font: {
            size() {
              return 16 * scaleFactor
            }
          },
          color: theme.palette.text.secondary
        },
        max: stacked100 ? 100 : invertYAxis ? minValue : maxValue,
        min: stacked100 ? 0 : invertYAxis ? maxValue : minValue,
        ticks: {
          count: stacked100 ? 6 : lineChartDataset ? 8 : undefined,
          callback: function (this, value, index, ticks): string {
            if (index === ticks.length - 1 && stacked100) {
              return '%'
            }
            if (index === ticks.length - 1 && barChartUnit) {
              return barChartUnit
            }

            if (ticks[ticks.length - 1].value < 5 && lineChartDataset) {
              return formatAxisValue(Number(value), 1)
            }

            if (lineChartDataset) {
              return formatAxisValue(Number(value), 0)
            }

            return formatAxisValue(Number(value), null)
          },
          font: {
            size() {
              return 16 * scaleFactor
            }
          },
          color: theme.palette.text.secondary
        }
      },
      yLine: {
        axis: 'y',
        stacked,
        position: 'right',
        display: !!lineChartDataset,
        grid: {
          display: false
        },
        border: {
          display: false
        },
        title: {
          display: lineChartDataset ? true : false,
          text: lineChartLabel,
          font: {
            size() {
              return 16 * scaleFactor
            }
          },
          color: theme.palette.text.secondary
        },
        max: maxValue,
        min: minValue,
        ticks: {
          count: stacked100 ? 6 : 8,
          callback: function (this, value, index, ticks): string {
            if (index === ticks.length - 1 && lineChartUnit) {
              return lineChartUnit
            } else if (ticks[ticks.length - 1].value < 5) {
              return formatAxisValue(Number(value), 1)
            } else {
              return formatAxisValue(Number(value), 0)
            }
          },
          font: {
            size() {
              return 16 * scaleFactor
            }
          },
          color: theme.palette.text.secondary
        }
      }
    },
    maintainAspectRatio: false,
    responsive: true
  }
}

export const getData = (
  widget: WidgetObject,
  theme: Theme,
  hasBreakdown: boolean,
  formattedData: FormattedWidgetData,
  scaleFactor: number
): {
  labels: string[]
  datasets: {
    label: string
    data: (string | number | null)[]
    theme: Theme
    increaseIsPositive: boolean
    percentages?: Value[]
    type: any
    order: number
  }[]
  dataLabels: ParsedWidgetDataLabel[]
} => {
  const forecastActive = forecastIsActive(widget)
  const stacked100 =
    widget.settings.breakdown_by_display_type ===
    BreakdownByDisplayType.STACKED_100_PERCENT

  return {
    labels: formattedData.labels.map((item) => item.display_label),
    datasets: formattedData.datasets.map((item, i) => {
      const valueIsPercentageDiff =
        item.comparativeDisplayTypeSelected ===
        ComparativeDisplayType.PERCENTAGE

      const getColors = (variant: 'background' | 'border'): string[] => {
        return item.data.map((value, j) => {
          const forecast = isForecast(
            formattedData.labels,
            j,
            forecastActive,
            item.isComparativeData
          )

          const colorSpecs = getColorIndexAndAlpha(
            item.showComparativeValue,
            item.isComparativeData,
            i,
            item.settingIndex
          )

          return getColor(
            value as number,
            colorSpecs.index,
            theme,
            item.increaseIsPositive,
            item.upperBoundThreshold,
            item.lowerBoundThreshold,
            forecast && variant !== 'border' ? 0.1 : colorSpecs.alpha
          )
        })
      }

      return {
        type: item.settingIndex === 0 ? 'bar' : 'line',
        order: item.settingIndex === 0 ? 1 : 0,
        yAxisID: item.settingIndex === 0 ? 'y' : 'yLine',
        stack: stacked100
          ? item.settingIndex === 0
            ? 'y'
            : 'yLine'
          : undefined,
        showLine: false,
        label: item.label,
        data: item.data as DefaultDatasetData,
        prettyData: item.prettyData as DefaultDatasetData,
        theme,
        percentages: item.percentages,
        hasBreakdown,
        increaseIsPositive: item.increaseIsPositive,
        valueIsPercentageDiff,
        unit: item.unit,
        backgroundColor: getColors('background'),
        borderColor:
          item.settingIndex === 0 ||
          widget.settings.segment_by.type === DataType.DATE
            ? (context: DatasetContext) => {
                const colorSpecs = getColorIndexAndAlpha(
                  item.showComparativeValue,
                  item.isComparativeData,
                  context.datasetIndex,
                  item.settingIndex
                )

                return getColor(
                  context.raw as number,
                  colorSpecs.index,
                  theme,
                  item.increaseIsPositive,
                  item.upperBoundThreshold,
                  item.lowerBoundThreshold,
                  colorSpecs.alpha
                )
              }
            : theme.palette.background.widget,
        boxBorderDash:
          item.settingIndex === 0
            ? (context: DatasetContext) => {
                if (context.raw === 0) {
                  return []
                }

                if (
                  isForecast(
                    formattedData.labels,
                    context.dataIndex,
                    forecastActive,
                    item.isComparativeData
                  )
                ) {
                  return getBorderDash(scaleFactor)
                }

                return []
              }
            : undefined,
        boxBorderWidth: getBorderWidth(scaleFactor)
      }
    }),
    dataLabels: formattedData.labels
  }
}
