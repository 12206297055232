import React, { useMemo, useState } from 'react'

import { getKpiFormulaString } from './utils'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import Table from 'components_new/atoms/Table'
import TableRow from 'components_new/atoms/Table/TableRow'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableContainer from 'components_new/atoms/Table/TableContainer'
import TableHead from 'components_new/atoms/Table/TableHead'
import TableBody from 'components_new/atoms/Table/TableBody'
import Tag from 'components_new/atoms/Tag'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

import OptionsMenuButton from 'components_new/molecules/OptionsMenuButton'

import DeleteDialog from 'components_new/organisms/dialogs/DeleteDialog'

import AddKpiDialog from './kpi_dialogs/AddKpiDialog'
import EditKpiDialog from './kpi_dialogs/EditKpiDialog'
import {
  KpiVariable,
  KpiVariableWithChildren
} from 'redux/reducers/KpiVariables'
import { KPI_UNIT } from 'types/Enums'
import {
  KpiTemplate,
  PutKpiTemplateCalculationBlock
} from 'types/GlobalKpiTemplates'

interface KpisSectionProps {
  kpis: KpiTemplate[]
  onCreateKpi: (body: {
    name: string
    unit: KPI_UNIT
    blocks: PutKpiTemplateCalculationBlock
  }) => void
  onDeleteKpi: (id: string) => void
  onUpdateKpi: (
    id: string,
    body: { name: string; blocks: PutKpiTemplateCalculationBlock }
  ) => void
  variables: KpiVariable[]
}

const KpisSection = (props: KpisSectionProps) => {
  const { kpis, onCreateKpi, onDeleteKpi, onUpdateKpi, variables } = props

  const [addKpi, setAddKpi] = useState<boolean>(false)
  const [editKpi, setEditKpi] = useState<string | null>(null)
  const [deleteKpi, setDeleteKpi] = useState<string | null>(null)

  const formattedVariables: KpiVariableWithChildren[] = useMemo(() => {
    const levelZero = variables.filter((v) => v.level === 0)
    const levelOne = variables.filter((v) => v.level === 1)
    const levelTwo = variables.filter((v) => v.level === 2)

    return levelZero.map((variable) => ({
      ...variable,
      children: levelOne
        .filter((v) => v.parent_kpi_variable_id === variable.id)
        .map((variableOne) => ({
          ...variableOne,
          children: levelTwo
            .filter((v) => v.parent_kpi_variable_id === variableOne.id)
            .map((v) => ({
              ...v,
              children: []
            }))
        }))
    }))
  }, [variables])

  // Note: Commented sections are for changing order in later release.

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead sx={{ color: 'text.secondary' }}>
            <TableRow>
              {/* <TableCell>{''}</TableCell> */}
              <TableCell>Namn</TableCell>
              <TableCell>Formel</TableCell>
              <TableCell>Enhet</TableCell>
              <TableCell>{''}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {kpis
              // .sort((a, b) => a.index - b.index)
              .sort((a, b) => a.title.localeCompare(b.title))
              .map((kpi) => {
                return (
                  <TableRow hover={true} key={kpi.id}>
                    <TableCell>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        <Tag
                          startIconName="TrendingUpOutlined"
                          color="primary"
                        />
                        <Text variant="subtitle2">{kpi.title}</Text>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <code>
                        {getKpiFormulaString(
                          kpi.custom_calculation_block ||
                            kpi.default_calculation_block,
                          variables,
                          kpis
                        )}
                      </code>
                    </TableCell>
                    <TableCell>{kpi.unit}</TableCell>
                    <TableCell sx={{ width: 100 }}>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        <Box component="span" sx={{ height: 'fit-content' }}>
                          <Button
                            onClick={() => setEditKpi(kpi.id)}
                            size="small"
                            variant="text"
                          >
                            Redigera
                          </Button>
                        </Box>
                        <Tooltip
                          title={
                            kpi.default_calculation_block
                              ? 'Nyckeltalet är en del av Homepals standard och går inte att ta bort.'
                              : !kpi.allow_delete
                                  ? 'Nyckeltalet används och går inte att ta bort.'
                                  : ''
                          }
                        >
                          <Box component="span">
                            <OptionsMenuButton
                              color="disabled"
                              disabled={!kpi.allow_delete}
                              iconName="MoreHorizOutlined"
                              options={[
                                {
                                  iconName: 'DeleteOutlined',
                                  onClick: () => {
                                    setDeleteKpi(kpi.id)
                                  },
                                  title: 'Radera'
                                }
                              ]}
                            />
                          </Box>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              })}
            <TableRow>
              <TableCell colSpan={4} sx={{ p: 0 }}>
                <MenuItem
                  component="button"
                  onClick={() => setAddKpi(true)}
                  sx={{
                    height: 48,
                    width: '100%',
                    fontWeight: 500,
                    justifyContent: 'center',
                    gap: 0.5
                  }}
                >
                  <Icon fontSize="small" name="AddCircleOutlineOutlined" />
                  Lägg till
                </MenuItem>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* ----- Dialogs ----- */}
      <AddKpiDialog
        formattedVariables={formattedVariables}
        open={addKpi}
        onClose={() => setAddKpi(false)}
        onSave={(body) => {
          onCreateKpi(body)
          setAddKpi(false)
        }}
        variables={variables}
        templates={kpis}
      />
      {editKpi ? (
        <EditKpiDialog
          formattedVariables={formattedVariables}
          kpi={kpis.find((kpi) => kpi.id === editKpi) as KpiTemplate}
          open={Boolean(editKpi)}
          onClose={() => setEditKpi(null)}
          onSave={(body) => {
            onUpdateKpi(editKpi, body)
            setEditKpi(null)
          }}
          variables={variables}
          templates={kpis}
        />
      ) : null}
      {deleteKpi ? (
        <DeleteDialog
          open={Boolean(deleteKpi)}
          handleClose={() => setDeleteKpi(null)}
          handleDelete={() => {
            onDeleteKpi(deleteKpi)
            setDeleteKpi(null)
          }}
          title={kpis.find((kpi) => kpi.id === deleteKpi)?.title}
          type="nyckeltal"
        />
      ) : null}
    </>
  )
}

export default KpisSection
