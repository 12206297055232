import React from 'react'

import Box from 'components_new/atoms/Box'
import Link from 'components_new/atoms/Link'
import Text from 'components_new/atoms/Text'

import EmptyPlaceholder from 'components_new/molecules/EmptyPlaceholder'
import SectionHeader from 'components_new/molecules/SectionHeader'

import { KpiTemplate } from 'types/GlobalKpiTemplates'

interface AboutProps {
  kpi: KpiTemplate
}

const About = (props: AboutProps) => {
  const { kpi } = props

  return (
    <>
      <SectionHeader size="medium" title="Om det här nyckeltalet" />
      {kpi.summary ? (
        <Text gutterBottom={true}>{kpi.summary}</Text>
      ) : (
        <EmptyPlaceholder
          background={true}
          empty={true}
          height={128}
          title={
            'Det här är ett anpassat nyckeltal och saknar därför beskrivning.'
          }
        />
      )}
      {kpi.url ? (
        <Box sx={{ mt: 2 }}>
          <Link
            href={kpi.url}
            newTab={true}
            onClick={(event) => event.stopPropagation()}
          >
            Läs mer på vår hemsida
          </Link>
        </Box>
      ) : null}
    </>
  )
}

export default About
