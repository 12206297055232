import React, { useState } from 'react'

import Box from 'components_new/atoms/Box'

import CardButton from 'components_new/molecules/CardButton'

import RequestDashboardExampleDialog from 'components_new/organisms/dialogs/RequestDashboardExampleDialog'
import SubmitDashboardExampleDialog from 'components_new/organisms/dialogs/SubmitDashboardExampleDialog'
import OrderDashboardExampleDialog from 'components_new/organisms/dialogs/OrderDashboardExampleDialog'

/*-- content --*/
import {
  description as orderDescription,
  iconName as orderIconName,
  title as orderTitle
} from 'components_new/organisms/dialogs/OrderDashboardExampleDialog/content'

import {
  description as requestDescription,
  iconName as requestIconName,
  title as requestTitle
} from 'components_new/organisms/dialogs/RequestDashboardExampleDialog/content'

import {
  description as submitDescription,
  iconName as submitIconName,
  title as submitTitle
} from 'components_new/organisms/dialogs/SubmitDashboardExampleDialog/content'

interface ActionsProps {
  dashboard: any
  sx?: object
}

type TAction = 'order' | 'request' | 'submit'

const Actions = (props: ActionsProps) => {
  const { dashboard, sx } = props

  const [dialog, setDialog] = useState<TAction | false>(false)

  const handleCloseDialog = () => {
    setDialog(false)
  }

  const handleOpenDialog = (variant: TAction) => {
    setDialog(variant)
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, ...sx }}>
        <CardButton
          body={orderDescription}
          color="brand"
          iconName={orderIconName}
          onClick={() => handleOpenDialog('order')}
          title={orderTitle}
        />
        <CardButton
          body={requestDescription}
          color="primary"
          iconName={requestIconName}
          onClick={() => handleOpenDialog('request')}
          title={requestTitle}
          variant="outlined"
        />
        <CardButton
          body={submitDescription}
          color="primary"
          iconName={submitIconName}
          onClick={() => handleOpenDialog('submit')}
          title={submitTitle}
        />
      </Box>

      {/*-- dialogs --*/}
      <OrderDashboardExampleDialog
        dashboard={dashboard}
        onClose={handleCloseDialog}
        open={dialog === 'order'}
      />
      <RequestDashboardExampleDialog
        onClose={handleCloseDialog}
        open={dialog === 'request'}
      />
      <SubmitDashboardExampleDialog
        onClose={handleCloseDialog}
        open={dialog === 'submit'}
      />
    </>
  )
}

export default Actions
