import React, { ElementType, MouseEventHandler, ReactNode } from 'react'
import { ListItemButton as MUIListItemButton } from '@mui/material'
import { Color } from 'themes'

import LinkWrapper from 'components_new/atoms/LinkWrapper'

interface ListItemButtonProps {
  alignItems?: 'center' | 'flex-start'
  color?: 'brand' | Color
  children: ReactNode
  component?: ElementType<any>
  dense?: boolean
  disabled?: boolean
  disableGutters?: boolean
  disableHistory?: boolean
  divider?: boolean
  newTab?: boolean
  onClick?: (() => void) | MouseEventHandler<HTMLElement>
  selected?: boolean
  to?: string
  sx?: object
}

const ListItemButton = React.forwardRef(
  (props: ListItemButtonProps, ref: any) => {
    const { children, disabled, disableHistory, newTab, to, ...rest } = props

    return (
      <LinkWrapper
        disabled={disabled}
        disableHistory={disableHistory}
        href={to}
        newTab={newTab}
        style={{ width: '100%' }}
      >
        <MUIListItemButton
          disabled={disabled}
          sx={{ whiteSpace: 'nowrap' }}
          {...rest}
          ref={ref}
        >
          {children}
        </MUIListItemButton>
      </LinkWrapper>
    )
  }
)

ListItemButton.displayName = 'ListItemButton'
export default ListItemButton
