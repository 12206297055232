import { createAction, createReducer } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'

import * as Types from 'redux/Types'

import { arrayToObject } from 'utils/functions'
import { IAllocationKey } from 'types/GlobalAllocationKey'

export type TAllocationKeyBody = {
  [key: string]: IAllocationKey
}

export interface IAllocationKeyReducerType {
  data: TAllocationKeyBody
  fetched: boolean
}

// Initial state
const INITIAL_STATE: IAllocationKeyReducerType = {
  data: {},
  fetched: false
}

// Actions
const getAllSuccessAction = createAction<{ data: IAllocationKey[] }>(
  Types.GET_ALL_ALLOCATION_KEYS_SUCCESS
)

const createSuccessAction = createAction<{ data: IAllocationKey }>(
  Types.CREATE_ALLOCATION_KEY_SUCCESS
)

const updateSuccessAction = createAction<{ data: IAllocationKey }>(
  Types.UPDATE_ALLOCATION_KEY_SUCCESS
)

const deleteSuccessAction = createAction<string>(
  Types.DELETE_ALLOCATION_KEY_SUCCESS
)

const signOutAction = createAction(Types.SIGN_OUT)

// Reducer
const allocationKeyReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(createSuccessAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.data.id]: payload.data
        }
      }
    })
    .addCase(getAllSuccessAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: arrayToObject<IAllocationKey>(payload.data),
        fetched: true
      }
    })
    .addCase(updateSuccessAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.data.id]: payload.data
        }
      }
    })
    .addCase(deleteSuccessAction, (state, action) => {
      const { payload } = action

      const newData = cloneDeep(state.data)

      delete newData[payload]

      return {
        ...state,
        data: newData
      }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export default allocationKeyReducer
