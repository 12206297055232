import React from 'react'

import Box from 'components_new/atoms/Box'

import 'chart.js/auto'
import { Chart } from 'react-chartjs-2'
import { useTheme } from '@mui/material'
import { getParsedDate } from 'utils/dateParser'

interface DashboardViewsLineChartProps {
  labels: string[]
  values: number[]
}

const DashboardViewsLineChart = (props: DashboardViewsLineChartProps) => {
  const { labels, values } = props

  const theme = useTheme()

  return (
    <Box
      sx={{
        flexGrow: 1,
        minHeight: 0,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box sx={{ position: 'relative', flexGrow: 1, minHeight: 0 }}>
        <Chart
          type="line"
          data={{
            labels: labels.map((label) => getParsedDate(label)),
            datasets: [
              {
                label: 'Visningar',
                data: values,
                borderColor: theme.palette.primary.main,
                backgroundColor: theme.palette.primary.main
              }
            ]
          }}
          options={{
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                displayColors: false
              }
            },
            elements: {
              point: {
                radius: 0,
                hoverRadius: 7,
                hoverBorderWidth: 3.5
              }
            },
            interaction: {
              intersect: false,
              mode: 'index', // nearest?
              axis: 'x'
            },
            scales: {
              x: {
                grid: {
                  display: false
                },
                border: {
                  display: false
                },
                ticks: {
                  callback: function (this, value): string {
                    const label =
                      typeof value === 'number'
                        ? this.getLabelForValue(value)
                        : ''

                    return label
                  }
                }
              },
              y: {
                display: true,
                border: {
                  display: false
                }
              }
            },
            maintainAspectRatio: false,
            responsive: true
          }}
        />
      </Box>
    </Box>
  )
}

export default DashboardViewsLineChart
