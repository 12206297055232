import { Dispatch } from 'redux'
import { getRealEstates } from 'redux/api/SIM'

import * as Types from 'redux/Types'

export function tryGetAllRealEstates() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_ALL_REAL_ESTATES
    })

    getRealEstates()
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_REAL_ESTATES_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_REAL_ESTATES_FAILED
        })
      })
  }
}
