import React, { ReactElement, ReactNode } from 'react'
import { FormControlLabel as MUIFormControlLabel } from '@mui/material'

interface FormControlLabelProps {
  control: ReactElement<any>
  checked?: boolean
  disabled?: boolean
  disableTypography?: boolean
  label: ReactNode
  labelPlacement: 'bottom' | 'end' | 'start' | 'top'
  onChange?: () => void
  slotProps?: object
  sx?: object
  value?: any
}

/**
 * The FormControlLabel is a drop-in replacement of
 * the Radio, Switch and Checkbox component. Use this
 * component if you want to display an extra label.
 */

const FormControlLabel = React.forwardRef(
  (props: FormControlLabelProps, ref: any) => {
    const { sx, ...rest } = props

    return (
      <MUIFormControlLabel
        sx={{ color: 'text.primary', ...sx }}
        {...rest}
        ref={ref}
      />
    )
  }
)

FormControlLabel.displayName = 'FormControlLabel'
export default FormControlLabel
