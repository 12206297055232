import { Theme } from '@mui/material'
import {
  ChartOptions,
  ScriptableContext,
  Element,
  Scale,
  CoreScaleOptions,
  Tick
} from 'chart.js'

import {
  formatAxisValue,
  getThresholdLines,
  Value,
  getDiffLabel,
  getDiffColor,
  getColor,
  getTooltipLabel,
  isForecast,
  getColorIndexAndAlpha,
  getLegendStyles,
  getBorderDash,
  getBorderWidth
} from '../utils'
import { Context } from 'chartjs-plugin-datalabels'
import {
  BreakdownByDisplayType,
  DefaultDatasetData,
  FormattedWidgetData,
  ParsedWidgetDataLabel,
  WidgetObject
} from 'types/GlobalWidget'
import { ComparativeDisplayType } from 'types/GlobalKpiOption'

type BarChartOptions = ChartOptions<'bar'> & {
  plugins: {
    horizontalLines: { lines: { value: number; color: string }[] }
    datalabels: {
      align: (context: Context) => string | number
      anchor: 'end' | 'start' | 'center'
      clamp: boolean
      clip: boolean
      color: (cont: Context) => string
      display: (context: Context) => boolean
      offset: (context: Context) => number
      font: (cont: Context) => {
        family: string
        size: number
        weight: number
      }
      rotation: (context: Context) => number
      formatter: (value: number, context: Context) => string
    }
    verticalLines: { lines: { value: number; color: string }[] }
  }
}

interface ElementWithPercentage extends Element {
  percentage: Value
}

interface DatasetContext extends ScriptableContext<'bar'> {
  element: ElementWithPercentage
}

/**
 * @returns The horizontal lines for thresholds.
 */
export const getOptions = (
  widget: WidgetObject,
  hasBreakdown: boolean,
  theme: Theme,
  allowOnClick: boolean,
  formattedData: FormattedWidgetData,
  isComparativeButIllegal: boolean,
  scaleFactor: number
): BarChartOptions => {
  // Widget settings
  const invertYAxis = widget.settings.invert_y_axis
  const maxValue = widget.settings.max_value ?? undefined
  const minValue = widget.settings.min_value ?? undefined
  const labelChars = widget.settings.label_chars
  const breakdownByDisplayType = widget.settings.breakdown_by_display_type
  const horizontal = widget.settings.horizontal
  const stacked =
    breakdownByDisplayType === BreakdownByDisplayType.STACKED ||
    breakdownByDisplayType === BreakdownByDisplayType.STACKED_100_PERCENT
  const stacked100 =
    breakdownByDisplayType === BreakdownByDisplayType.STACKED_100_PERCENT

  // Main dataset settings
  const mainDataset = formattedData.datasets.find(
    (item) => item.settingIndex === 0
  )
  const mainDatasetUnit = mainDataset?.unit || null

  return {
    indexAxis: horizontal ? 'y' : 'x',
    onHover: (event, elements) => {
      const element = event.native?.target as HTMLElement

      element.style.cursor =
        elements.length === 1 && allowOnClick ? 'pointer' : 'default'
    },
    plugins: {
      legend: {
        display: formattedData.datasets.length > 1,
        ...getLegendStyles(scaleFactor, theme, 'bar')
      },
      stacked100: {
        enable: stacked100,
        replaceTooltipLabel: false
      },
      horizontalLines: horizontal
        ? { lines: [] }
        : getThresholdLines(theme, widget),
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (item) => getTooltipLabel(item, hasBreakdown),
          footer: function (items) {
            const dataset = formattedData.datasets.find(
              (item) => item.index === items[0].datasetIndex
            )

            if (dataset?.percentages) {
              const parsedLabel = horizontal ? 'y' : 'x'

              return getDiffLabel(
                dataset?.percentages[items[0].parsed[parsedLabel]] ?? null,
                isComparativeButIllegal
              )
            }

            return ''
          }
        },
        footerColor: (context) => {
          const dataset = formattedData.datasets.find(
            (item) => item.index === context.tooltipItems[0]?.datasetIndex
          )

          if (dataset?.percentages) {
            return getDiffColor(
              dataset.percentages[context.tooltip.dataPoints[0].parsed.x] ??
                null,
              dataset.increaseIsPositive,
              theme
            )
          }

          return '#fff'
        }
      },
      datalabels: {
        align: function (context) {
          const dataset = formattedData.datasets.find(
            (item) => item.index === context.datasetIndex
          )

          if (dataset?.percentages && dataset.percentages.length > 8) {
            return -45
          }

          return 'end'
        },
        anchor: 'end',
        clamp: true,
        clip: false,
        color: function (context) {
          let color = '#fff'
          const dataset = formattedData.datasets.find(
            (item) => item.index === context.datasetIndex
          )

          if (dataset?.percentages) {
            color =
              getDiffColor(
                dataset.percentages[context.dataIndex],
                dataset.increaseIsPositive,
                theme
              ) ?? '#fff'
          }

          return color
        },
        display: (context) => {
          const dataset = formattedData.datasets.find(
            (item) => item.index === context.datasetIndex
          )

          return !!dataset?.percentages
        },
        offset: function (context: Context) {
          const dataset = formattedData.datasets.find(
            (item) => item.index === context.datasetIndex
          )

          if (dataset?.percentages && dataset.percentages.length > 8) {
            return -10
          }

          return 0
        },
        font: function (context) {
          const dataset = formattedData.datasets.find(
            (item) => item.index === context.datasetIndex
          )

          let size = 10

          if (dataset?.percentages) {
            if (dataset.percentages.length > 8) {
              size = context.chart.width * 0.0075
            } else {
              size = context.chart.width * 0.02
            }
          }

          return {
            family: 'IBM Plex Sans',
            size: size,
            weight: 600
          }
        },
        rotation: function (context) {
          const dataset = formattedData.datasets.find(
            (item) => item.index === context.datasetIndex
          )

          if (dataset?.percentages && dataset.percentages.length > 8) {
            return -45
          }

          return 0
        },
        formatter: function (value, context) {
          const dataset = formattedData.datasets.find(
            (item) => item.index === context.datasetIndex
          )

          if (dataset?.percentages) {
            return getDiffLabel(
              dataset?.percentages[context.dataIndex],
              isComparativeButIllegal
            )
          }

          return ''
        }
      },
      verticalLines: horizontal
        ? getThresholdLines(theme, widget)
        : { lines: [] }
    },
    layout: {
      padding: function (context) {
        const dataset = formattedData.datasets.find(
          (item) => item.index === context.datasetIndex
        )
        let size = 0

        if (dataset?.percentages) {
          size = context.chart.width * 0.03
        }

        return size
      }
    },
    elements: {
      bar: {
        borderRadius: 0 // 2?
      },
      point: {
        radius: 0,
        hitRadius: 0
      }
    },
    interaction: {
      intersect: true
    },
    scales: {
      x: {
        stacked,
        grid: {
          display: horizontal,
          color: theme.palette.divider
        },
        max: stacked100 ? 100 : invertYAxis ? minValue : maxValue,
        min: stacked100 ? 0 : invertYAxis ? maxValue : minValue,
        ticks: {
          count: stacked100 ? 6 : undefined,
          callback: function (this, value, index, ticks): string {
            if (horizontal) {
              if (stacked100 && index === ticks.length - 1) {
                return '%'
              }

              return getValueTicks(value, index, ticks, mainDatasetUnit)
            }

            return getIndexTicks(this, value, labelChars)
          },
          font: {
            size() {
              return 16 * scaleFactor
            }
          },
          color: theme.palette.text.secondary
        },
        border: {
          display: false
        }
      },
      y: {
        stacked,
        reverse: invertYAxis,
        display: true,
        grid: {
          display: !horizontal,
          color: theme.palette.divider
        },
        max: stacked100 ? 100 : invertYAxis ? minValue : maxValue,
        min: stacked100 ? 0 : invertYAxis ? maxValue : minValue,
        ticks: {
          count: stacked100 ? 6 : undefined,
          callback: function (this, value, index, ticks): string {
            if (horizontal) {
              return getIndexTicks(this, value, labelChars)
            }

            if (stacked100 && index === ticks.length - 1) {
              return '%'
            }

            return getValueTicks(value, index, ticks, mainDatasetUnit)
          },
          font: {
            size() {
              return 16 * scaleFactor
            }
          },
          color: theme.palette.text.secondary
        },
        border: {
          display: false
        }
      }
    },
    maintainAspectRatio: false,
    responsive: true
  }
}

export const getBars = (
  theme: Theme,
  hasBreakdown: boolean,
  formattedData: FormattedWidgetData,
  forecastActive: boolean,
  scaleFactor: number
): {
  labels: string[]
  datasets: {
    label: string
    data: (string | number | null)[]
    theme: Theme
    increaseIsPositive: boolean
    percentages?: Value[]
  }[]
  dataLabels: ParsedWidgetDataLabel[]
} => {
  return {
    labels: formattedData.labels.map((item) => item.display_label),
    datasets: formattedData.datasets.map((item, i) => {
      const valueIsPercentageDiff =
        item.comparativeDisplayTypeSelected ===
        ComparativeDisplayType.PERCENTAGE

      const getColors = (variant: 'background' | 'border'): string[] => {
        return item.data.map((value, j) => {
          const forecast = isForecast(
            formattedData.labels,
            j,
            forecastActive,
            item.isComparativeData
          )

          const colorSpecs = getColorIndexAndAlpha(
            item.showComparativeValue,
            item.isComparativeData,
            i,
            item.settingIndex
          )

          return getColor(
            value as number,
            colorSpecs.index,
            theme,
            item.increaseIsPositive,
            item.upperBoundThreshold,
            item.lowerBoundThreshold,
            forecast && variant !== 'border' ? 0.1 : colorSpecs.alpha
          )
        })
      }

      return {
        label: item.label,
        data: item.data as DefaultDatasetData,
        prettyData: item.prettyData,
        theme,
        percentages: item.percentages,
        hasBreakdown,
        increaseIsPositive: item.increaseIsPositive,
        valueIsPercentageDiff,
        unit: item.unit,
        backgroundColor: getColors('background'),
        borderColor: getColors('border'),
        borderWidth: 0,
        boxBorderDash: (context: DatasetContext) => {
          if (context.raw === 0) {
            return []
          }

          if (
            isForecast(
              formattedData.labels,
              context.dataIndex,
              forecastActive,
              item.isComparativeData
            )
          ) {
            return getBorderDash(scaleFactor)
          }

          return []
        },
        boxBorderWidth: getBorderWidth(scaleFactor)
      }
    }),
    dataLabels: formattedData.labels
  }
}

const getIndexTicks = (
  scale: Scale<CoreScaleOptions>,
  value: string | number,
  labelChars: number | null
) => {
  const label = typeof value === 'number' ? scale.getLabelForValue(value) : ''

  if (!!labelChars && label?.length > labelChars) {
    return `${label.substring(0, labelChars)}...`
  }

  return label
}

const getValueTicks = (
  value: string | number,
  index: number,
  ticks: Tick[],
  mainDatasetUnit: string | null
) => {
  if (index === ticks.length - 1 && mainDatasetUnit) {
    return mainDatasetUnit
  } else {
    return formatAxisValue(Number(value), null)
  }
}
