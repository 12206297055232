import { Dispatch } from 'redux'
import * as Types from 'redux/Types'

import {
  create,
  destroy,
  exportDetails,
  getDetails,
  update
} from 'redux/api/KpiOptions'
import { QueryParams } from 'redux/api/Widgets'
import {
  KpiOptionPatchAttributes,
  KpiOptionPostAttributes
} from 'types/GlobalKpiOption'

import { FileExtension } from 'utils/types'

export function tryCreateKpiOption(
  body: KpiOptionPostAttributes,
  dashboardId: string
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.CREATE_KPI_OPTION
    })

    create(body)
      .then((response) => {
        dispatch({
          type: Types.CREATE_KPI_OPTION_SUCCESS,
          payload: {
            dashboardId: dashboardId,
            option: response.data.data
          }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Nyckeltalet har lagts till' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_KPI_OPTION_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Nyckeltalet kunde inte läggas till' }
        })
      })
  }
}

export function tryUpdateKpiOption(
  id: string,
  body: KpiOptionPatchAttributes,
  dashboardId: string
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_KPI_OPTION
    })

    const skipFetchData =
      'increase_is_positive' in body.data ||
      'upper_bound_threshold' in body.data ||
      'lower_bound_threshold' in body.data ||
      'number_of_decimals' in body.data ||
      'unit_prefix' in body.data ||
      'equivalent' in body.data ||
      'hidden' in body.data

    update(id, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_KPI_OPTION_SUCCESS,
          payload: {
            dashboardId: dashboardId,
            data: response.data.data,
            skipFetchData
          }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Nyckeltalet har uppdaterats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_KPI_OPTION_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Nyckeltalet kunde inte uppdateras' }
        })
      })
  }
}

export function tryDeleteKpiOption(
  id: string,
  widgetId: string,
  dashboardId: string
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.DELETE_KPI_OPTION
    })

    destroy(id)
      .then(() => {
        dispatch({
          type: Types.DELETE_KPI_OPTION_SUCCESS,
          payload: {
            dashboardId: dashboardId,
            id,
            widgetId: widgetId
          }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Nyckeltalet har tagits bort' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_KPI_OPTION_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Nyckeltalet kunde inte tas bort' }
        })
      })
  }
}

export function tryGetDetails(
  id: string,
  offset: number,
  queryParam: QueryParams | null
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_DETAILS,
      payload: {
        kpiOptionId: id,
        segmentBy: queryParam?.segment_by,
        sort: queryParam?.sort,
        offset
      }
    })

    getDetails(id, offset, queryParam)
      .then((response) => {
        dispatch({
          type: Types.GET_DETAILS_SUCCESS,
          payload: {
            data: response.data.data,
            kpiOptionId: id,
            segmentBy: queryParam?.segment_by,
            sort: queryParam?.sort,
            offset
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_DETAILS_FAILED,
          payload: id
        })
      })
  }
}

export function tryExportDetails(
  id: string,
  fileName: string,
  ext: FileExtension,
  queryParam: QueryParams | null
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.EXPORT_DETAILS,
      payload: {
        kpiOptionId: id
      }
    })

    exportDetails(id, ext, queryParam)
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        )
        const link = document.createElement('a')

        link.href = downloadUrl
        link.setAttribute('download', `${fileName}.${ext}`)
        document.body.appendChild(link)
        link.click()
        link.remove()

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Export lyckades' }
        })

        dispatch({
          type: Types.EXPORT_DETAILS_SUCCESS,
          payload: {
            kpiOptionId: id
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Export misslyckades' }
        })

        dispatch({
          type: Types.EXPORT_DETAILS_FAILED,
          payload: id
        })
      })
  }
}
