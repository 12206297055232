import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { AccountRole } from 'types/GlobalUser'

const isHomepalInternalPage = (productPath) => {
  return productPath.includes('tracking') || productPath.includes('templates')
}

const RedirectWrapper = ({ AuthStore, children }) => {
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    const productPath = window.location.pathname.split('/')[1]

    let defaultPath = '/dashboards'
    const accessHubUser = AuthStore?.user?.role === AccountRole.ACCESS_HUB

    if (accessHubUser) {
      defaultPath = '/access-hub'
    }

    if (location.state && location.state.redirectPath) {
      history.push(location.state.redirectPath, {})
    } else if (!productPath.includes('access-hub') && accessHubUser) {
      history.push(defaultPath)
    } else if (
      AuthStore &&
      AuthStore.user &&
      AuthStore.user.role !== AccountRole.ADMIN &&
      !productPath.includes('dashboards') &&
      !productPath.includes('lists') &&
      !productPath.includes('insight-studio') &&
      !productPath.includes('access-hub') &&
      !productPath.includes('explore') &&
      !(AuthStore.user.is_homepal_user && isHomepalInternalPage(productPath))
    ) {
      history.push(defaultPath)
    } else if (productPath === '' || productPath === 'login') {
      history.push(defaultPath)
    }
  }, [window.location.pathname, AuthStore.user])

  return <>{children}</>
}

export default RedirectWrapper
