import { nanoid } from 'nanoid'
import {
  CustomPeriodFilter,
  ParsedSegmentPath,
  PeriodFilter,
  WidgetObject
} from 'types/GlobalWidget'
import { Filters } from './types'
import { Condition, FilterType, KpiOptionObject } from 'types/GlobalKpiOption'
import { DashboardGroup, DashboardOwnership } from 'redux/reducers/Dashboards'
import { DashboardFolderReduxData } from 'types/GlobalDashboardFolder'
import { AccountRole, User } from 'types/GlobalUser'

/**
 * Get thumbnail for dashboard.
 * Adding reload query parameter to avoid caching.
 * @param thumbnail
 * @returns
 */
export const getThumbnail = (thumbnail: string | null) =>
  thumbnail
    ? `${thumbnail}?reload=${nanoid()}`
    : '/assets/media/dashboard_thumbnail.svg'

export const getDashboardImageUrl = (id: string) => {
  return `https://homepal-dashboard-thumbnails.s3.eu-north-1.amazonaws.com/production/${id}.png`
}

// Type guard function for PeriodFilter
export function isPeriodFilterEnum(
  value: PeriodFilter | CustomPeriodFilter
): value is PeriodFilter {
  return typeof value === 'string' && value in PeriodFilter
}

// Type guard function for CustomPeriodFilter
export function isCustomPeriodFilter(
  value: PeriodFilter | CustomPeriodFilter
): value is CustomPeriodFilter {
  return typeof value === 'object' && 'from' in value
}

export function getFilterFromSegmentPaths(
  widget: WidgetObject,
  segmentPaths: ParsedSegmentPath[],
  kpiOptionId: string
): Filters {
  const filters: Filters = {}

  // attribute option can be either from main kpi option (segment)
  // or the kpi option that is being processed.
  const attributeOptions = {
    ...(
      widget.settings.kpi_options.find(
        (item) => item.index === 0
      ) as KpiOptionObject
    ).attribute_options,
    ...(
      widget.settings.kpi_options.find(
        (option) => option.id === kpiOptionId
      ) as KpiOptionObject
    ).attribute_options
  }

  segmentPaths.forEach((item) => {
    if (!item.attribute_option_id || item.period) {
      return
    }

    const selectedAttributeOption = attributeOptions[item.attribute_option_id]

    if (selectedAttributeOption.relation_key in filters) {
      (
        filters[selectedAttributeOption.relation_key].value as (
          | string
          | number
          | null
        )[]
      ).push(item.label)
    } else {
      filters[selectedAttributeOption.relation_key] = {
        attributeId: selectedAttributeOption.relation_key,
        condition: Condition.EQ,
        value: [item.label],
        type: FilterType.INPUT_VALUE
      }
    }
  })

  return filters
}

/**
 * Find the last occuring item in a list.
 * @param array - List to find item in.
 * @param validate - Function if item is the one to search for.
 * @returns last index that fulfills validate, -1 if not found.
 */
export function lastIndexOf<T>(array: T[], validate: (item: T) => boolean) {
  for (let i = array.length - 1; i >= 0; i--) {
    if (validate(array[i])) {
      return i
    }
  }

  return -1
}

export function getAvailableGroups(groups: { [key: string]: DashboardGroup }) {
  const groupArray = Object.values(groups)

  return groupArray.filter(
    (group) =>
      group.ownership === DashboardOwnership.SHARED_WITH_ME ||
      group.ownership === DashboardOwnership.OWNED_BY_ME
  )
}

export function getAvailableFolders(
  groups: DashboardGroup[],
  folders: DashboardFolderReduxData,
  user: User | null
) {
  if (!user) {
    return []
  }

  const folderArray = Object.values(folders)

  folderArray.sort((a, b) => a.name.localeCompare(b.name, 'sv'))

  // If admin or Homepal we return all folders.
  if (user.role === AccountRole.ADMIN || user.is_homepal_user) {
    return folderArray
  }

  // If user we return folders with content for the user.
  return folderArray.filter((folder) => {
    return groups.some((group) => group.folder_id === folder.id)
  })
}

/**
 * Parses array to object which uses the id as key.
 */
export function arrayToObject<T>(arr: (T & { id: string })[]) {
  const obj: { [id: string]: T } = {}

  arr.forEach((item) => {
    obj[item.id] = item
  })

  return obj
}
