import React from 'react'

import Paper from 'components_new/atoms/Paper'
import Text from 'components_new/atoms/Text'
import Box from 'components_new/atoms/Box'
import { default as AtomTable } from 'components_new/atoms/Table'
import TableHead from 'components_new/atoms/Table/TableHead'
import TableRow from 'components_new/atoms/Table/TableRow'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableBody from 'components_new/atoms/Table/TableBody'
import { Chart } from 'react-chartjs-2'
import TableContainer from 'components_new/atoms/Table/TableContainer'
import LoadingSection from 'components_new/molecules/LoadingSection'
import { parseDatetime } from 'utils/dateParser'
import { useTheme } from '@mui/material'

interface InternalDashboardWidgetProps {
  kpi: {
    title: string
    value: number
    loading: boolean
  }
  list: {
    title: string
    headers: string[]
    rows: string[][]
    loading: boolean
  }
  bar: {
    labels: string[]
    data: number[]
    label: string
    title: string
    loading: boolean
  }
  timeType?: 'bar' | 'line'
}

const InternalDashboardWidget = (props: InternalDashboardWidgetProps) => {
  const PaperSx = {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    p: 2,
    minWidth: 0,
    position: 'relative',
    gap: 2,
    bgcolor: 'grey.50'
  }

  const theme = useTheme()

  return (
    <Paper elevation={0} sx={PaperSx}>
      <Box sx={{ flex: '0 0 100px' }}>
        <Text
          component="div"
          fontSize={14}
          fontWeight={'bold'}
          lineHeight={1.1}
          sx={{
            color: 'text.primary'
          }}
        >
          {props.kpi.title}
        </Text>
        {props.kpi.loading ? (
          <LoadingSection loading titles={['Hämtar data']} />
        ) : (
          <Text
            component="div"
            fontSize={60}
            fontWeight={'bold'}
            lineHeight={1.1}
            sx={{
              color: 'text.primary',
              mt: 2
            }}
          >
            {props.kpi.value}
          </Text>
        )}
      </Box>
      <Text
        component="div"
        fontSize={14}
        fontWeight={'bold'}
        lineHeight={1.1}
        sx={{
          color: 'text.primary'
        }}
      >
        {props.list.title}
      </Text>
      <Box
        sx={{
          flex: '1 1 0',
          minHeight: 0,
          overflowY: props.list.loading ? 'hidden' : 'scroll'
        }}
      >
        {props.list.loading ? (
          <LoadingSection loading titles={['Hämtar data']} />
        ) : (
          <TableContainer component={Paper}>
            <AtomTable
              stickyHeader
              sx={{
                minHeight: 0
              }}
            >
              <TableHead>
                <TableRow>
                  {props.list.headers.map((header, i) => (
                    <TableCell
                      sx={{ fontWeight: 'bold' }}
                      key={header}
                      size={'small'}
                      align={i === 1 ? 'right' : 'left'}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.list.rows.map((row, i) => (
                  <TableRow key={i}>
                    {row.map((value, j) => (
                      <TableCell
                        key={`${i}-${j}`}
                        size={'small'}
                        align={j === 1 ? 'right' : 'left'}
                      >
                        {j === 1 ? parseDatetime(value) : value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </AtomTable>
          </TableContainer>
        )}
      </Box>
      <Text
        component="div"
        fontSize={14}
        fontWeight={'bold'}
        lineHeight={1.1}
        sx={{
          color: 'text.primary'
        }}
      >
        {props.bar.title}
      </Text>
      <Box sx={{ flex: '1 1 0', minHeight: 0 }}>
        {props.bar.loading ? (
          <LoadingSection loading titles={['Hämtar data']} />
        ) : (
          <Box sx={{ position: 'relative', flexGrow: 1, minHeight: '100%' }}>
            <Chart
              type={props.timeType || 'bar'}
              data={{
                labels: props.bar.labels,
                datasets: [
                  {
                    label: props.bar.label,
                    data: props.bar.data,
                    backgroundColor: theme.palette.brand.main,
                    borderColor: theme.palette.brand.main
                  }
                ]
              }}
              options={{
                plugins: {
                  legend: {
                    display: false
                  }
                },
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                  x: {
                    grid: {
                      display: false,
                      color: theme.palette.divider
                    },
                    border: {
                      display: false
                    },
                    ticks: {
                      color: theme.palette.text.secondary
                    }
                  },
                  y: {
                    grid: {
                      display: true,
                      color: theme.palette.divider
                    },
                    border: {
                      display: false
                    },
                    ticks: {
                      color: theme.palette.text.secondary
                    }
                  }
                }
              }}
            />
          </Box>
        )}
      </Box>
    </Paper>
  )
}

export default InternalDashboardWidget
