import React, { useState } from 'react'

import { WidgetObject, WidgetType } from 'types/GlobalWidget'
import {
  KpiOptionObject,
  KpiOptionPatchAttributes
} from 'types/GlobalKpiOption'

import Switch from 'components_new/atoms/Switch'

import SettingsEditDialog from 'components_new/molecules/SettingsEditDialog'
import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'

interface TitleSectionProps {
  kpi: KpiOptionObject
  updateKpiOption: (body: KpiOptionPatchAttributes) => void
  widget: WidgetObject
}

const TitleSection = (props: TitleSectionProps) => {
  const { kpi, updateKpiOption, widget } = props

  /*-- toggle --*/
  const handleToggle = () => {
    updateKpiOption({
      data: {
        hidden: !kpi.hidden
      }
    })
  }

  /*-- dialog --*/
  const [dialog, setDialog] = useState<{
    open: boolean
    title: string
    data: {
      value: string
    }
  }>({
    open: false,
    title: '',
    data: { value: '' }
  })

  const handleOpenDialog = () => {
    setDialog({
      open: true,
      title: 'Namn',
      data: { value: kpi.title }
    })
  }

  const handleCloseDialog = () => {
    setDialog((prevState) => ({ ...prevState, open: false }))
  }

  const handleEditDialogValue = (value: string) => {
    setDialog((prevState) => ({
      ...prevState,
      data: { ...prevState.data, value }
    }))
  }

  const handleSubmitDialog = () => {
    updateKpiOption({
      data: {
        title: dialog.data.value
      }
    })
    handleCloseDialog()
  }

  return (
    <>
      <SettingsGroup>
        {[WidgetType.LIST].includes(widget.settings.type.selected) ? (
          <SettingsItem
            actions={
              <Switch
                checked={!kpi.hidden}
                onChange={() => handleToggle()}
                size="small"
              />
            }
            title="Visa"
          />
        ) : null}
        <SettingsItem
          onClick={() => handleOpenDialog()}
          title="Namn"
          value={kpi.title}
          variant="edit"
        />
      </SettingsGroup>

      {/*-- dialogs --*/}
      <SettingsEditDialog
        disabled={
          // if title is duplicate
          widget.settings.kpi_options.some(
            (option) =>
              option.id !== kpi.id && option.title === dialog.data.value
          )
        }
        onChange={handleEditDialogValue}
        onClose={handleCloseDialog}
        onSubmit={handleSubmitDialog}
        open={dialog.open}
        title={dialog.title}
        type={'text'}
        value={dialog.data.value}
      />
    </>
  )
}

export default TitleSection
