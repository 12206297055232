import { TLocationState } from 'components_new/atoms/LinkWrapper'
import * as H from 'history'

type TQueryParams = {
  [key: string]: string[]
}

export const getQueryObject = (): TQueryParams => {
  const query = window.location.search.substring(1)
  const object: TQueryParams = {}

  if (query) {
    const vars = query.split('&')

    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=')

      object[pair[0]] = pair[1].split(',')
    }
  }

  return object
}

export const createQueryString = (object: TQueryParams): string => {
  const string = Object.entries(object)
    // encodeURIComponent ensures that keys and values are URL-safe:
    .map(
      ([key, values]) =>
        `${encodeURIComponent(key)}=${values.map(encodeURIComponent).join(',')}`
    )
    .join('&')

  return `?${string}`
}

export const updateQueryParam = (params: {
  history: H.History<unknown>
  key: string
  unique?: boolean
  value: string
}) => {
  const { history, key, unique, value } = params

  const query = getQueryObject()

  const currentValues = query[key] ?? []

  const updateHistory = (search: string) => {
    const prevLocationState = {
      prevPathname:
        (history.location.state as TLocationState)?.prevPathname ?? '/',
      prevSearch: (history.location.state as TLocationState)?.prevSearch ?? ''
    }

    history.replace(
      {
        search: search
      },
      prevLocationState
    )
  }

  // remove if exists
  if (currentValues.includes(value)) {
    const newValues = currentValues.filter((item) => item !== value)

    if (newValues.length > 0) {
      // remove if more values
      updateHistory(
        createQueryString({
          ...query,
          [key]: newValues
        })
      )
    } else {
      // remove if only value
      const newQuery = { ...query }

      delete newQuery[key]

      updateHistory(createQueryString(newQuery))
    }
  } else {
    // add if exists
    if (currentValues.length > 0 && !unique) {
      updateHistory(
        createQueryString({
          ...query,
          [key]: [...currentValues, value]
        })
      )
    } else {
      updateHistory(
        createQueryString({
          ...query,
          [key]: [value]
        })
      )
    }
  }
}
