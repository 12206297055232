import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import { withRouter } from 'react-router-dom'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import LoadingSection from 'components_new/molecules/LoadingSection'

import Logotype from 'assets/logotypes/Logotype'

enum State {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED'
}

const VISMA_LOGO =
  'https://www.visma.se/globalassets/global/common-images/logos/vismalogo.svg'

const URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990'
    : 'https://api.homepal.se'

const getContent = (state: State) => {
  switch (state) {
  case State.LOADING:
    return <LoadingSection titles={['Aktiverar visma']} loading />
  case State.SUCCESS:
    return (
      <>
        <Box>
          <h1>Visma har aktiverats</h1>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 4
          }}
        >
          <Box>
            <Logotype />
          </Box>
          <Icon name="SyncAlt" fontSize="large" />
          <img src={VISMA_LOGO} height={50} />
        </Box>
      </>
    )
  case State.FAILED:
    return (
      <>
        <Box>
          <h1>Visma kunde inte aktiveras</h1>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 4
          }}
        >
          <Box>
            <Logotype />
          </Box>
          <Icon name="CancelTwoTone" fontSize="large" />
          <img src={VISMA_LOGO} height={50} />
        </Box>
      </>
    )
  }
}

const VismaActivation = (props: any) => {
  const [state, setState] = useState<State>(State.LOADING)

  useEffect(() => {
    const params = new URLSearchParams(props.location.search)

    const code = params.get('code')
    const state = params.get('state')

    Axios.post(`${URL}/v1/public/visma/activate/${state}`, {
      data: { code }
    })
      .then(() => setState(State.SUCCESS))
      .catch(() => setState(State.FAILED))
  }, [])

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      {getContent(state)}
    </Box>
  )
}

export default withRouter(VismaActivation)
