import React, { useState } from 'react'

import { SxProps } from '@mui/material'

import { ConnectedProps, connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { ApplicationState } from 'redux/Stores/types'
import { Category } from 'redux/reducers/Dashboards'

import * as ConfigActions from 'redux/actions/Configs'

import {
  General,
  GeneralConfigType,
  Target,
  translateGeneralConfigAttributes
} from 'types/GlobalConfig'

import { translateCategory } from 'utils/enumTranslator'
import { sortAlphabeticalAsc } from 'utils/sortHelper'

import Box from 'components_new/atoms/Box'

import SettingsEditDialog from 'components_new/molecules/SettingsEditDialog'
import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'
import { TInputType } from 'components_new/molecules/SettingsEditDialog/utils'

import TargetEditDialog from 'components_new/organisms/TargetEditDialog'

import { getGeneralOptions } from './utils'
import { getParsedDate } from 'utils/dateParser'

interface ConfigTemplateProps {
  sx?: SxProps
}

export interface TargetSection {
  category: Category
  targets: { [id: string]: Target }
  generals: { [attribute: string]: General }
}

const ConfigTemplate = (props: ComponentProps) => {
  const {
    sx,
    // redux stores
    ConfigStore,
    // redux actions
    tryPutTargetValues,
    tryUpdateConfig
  } = props

  const [editTarget, setEditTarget] = useState<Target | null>(null)

  const sections = Object.values(ConfigStore.data)

  const maxDate = new Date()

  maxDate.setMonth(maxDate.getMonth() - 1)

  /*-- dialogs --*/
  const [dialog, setDialog] = useState<{
    open: boolean
    title: string
    type: TInputType
    configType: GeneralConfigType
    data: {
      attribute: string
      category: Category
      options: { label: string; value: any }[]
      unit: string | null
      value: string
    }
  }>({
    open: false,
    title: '',
    type: 'number',
    configType: GeneralConfigType.NUMBER,
    data: {
      attribute: '',
      category: Category.ECONOMY,
      options: [],
      unit: null,
      value: ''
    }
  })

  const handleCloseDialog = () => {
    setDialog((prevState) => ({ ...prevState, open: false }))
  }

  const handleOpenDialog = (general: General, category: Category) => {
    let inputType: TInputType = 'number'
    let inputOptions: { label: string; value: any }[] = []

    if (general.type === GeneralConfigType.OPTIONS) {
      inputType = 'select'
      inputOptions = getGeneralOptions(general)
    }

    if (general.type === GeneralConfigType.MONTH) {
      inputType = 'year_month'
    }

    setDialog({
      open: true,
      title: translateGeneralConfigAttributes[general.attribute],
      type: inputType,
      configType: general.type,
      data: {
        attribute: general.attribute,
        category: category,
        options: inputOptions,
        unit: general.unit,
        value: general.value ? general.value.toString() : ''
      }
    })
  }

  const handleEditDialogValue = (value: string) => {
    setDialog((prevState) => ({
      ...prevState,
      data: { ...prevState.data, value }
    }))
  }

  const handleSubmitDialog = () => {
    let body = {}

    if (dialog.configType === GeneralConfigType.OPTIONS) {
      body = {
        [dialog.data.attribute]:
          dialog.data.value === 'none' ? null : dialog.data.value
      }
    } else if (dialog.configType === GeneralConfigType.MONTH) {
      body = {
        [dialog.data.attribute]: dialog.data.value
      }
    } else {
      body = {
        [dialog.data.attribute]: dialog.data.value
          ? parseInt(dialog.data.value)
          : null
      }
    }

    tryUpdateConfig(body, dialog.data.category)
  }

  return (
    <Box sx={{ maxWidth: 'content', ...sx }}>
      {sections
        .sort((a, b) =>
          sortAlphabeticalAsc(
            translateCategory[a.category]?.title,
            translateCategory[b.category]?.title
          )
        )
        .map((section, i) => {
          const generals = Object.values(section.generals)
          const targets = Object.values(section.targets)

          return (
            <Box key={i}>
              {generals.length > 0 ? (
                <SettingsGroup
                  title={translateCategory[section.category].title}
                >
                  {generals.map((general) => {
                    // multiple selection
                    if (general.type === GeneralConfigType.OPTIONS) {
                      return (
                        <SettingsItem
                          key={general.attribute}
                          onClick={() =>
                            handleOpenDialog(general, section.category)
                          }
                          title={
                            translateGeneralConfigAttributes[general.attribute]
                          }
                          value={
                            general.options.find(
                              (option) => option.value === general.value
                            )?.label ?? null
                          }
                          variant="edit"
                        />
                      )
                    } else if (general.type === GeneralConfigType.MONTH) {
                      return (
                        <SettingsItem
                          key={general.attribute}
                          onClick={() =>
                            handleOpenDialog(general, section.category)
                          }
                          title={
                            translateGeneralConfigAttributes[general.attribute]
                          }
                          value={getParsedDate(general.value as string)}
                          variant="edit"
                        />
                      )
                    }

                    // number input
                    return (
                      <SettingsItem
                        key={general.attribute}
                        onClick={() =>
                          handleOpenDialog(general, section.category)
                        }
                        title={
                          translateGeneralConfigAttributes[general.attribute]
                        }
                        value={`${general.value} ${general.unit || ''}`}
                        variant="edit"
                      />
                    )
                  })}
                </SettingsGroup>
              ) : null}

              {targets.length > 0 ? (
                <SettingsGroup
                  helperText={
                    <>
                      Konfigurera ovanstående värden att kunna använda samtliga
                      nyckeltal inom {translateCategory[section.category].title}
                      .
                    </>
                  }
                  title={
                    generals.length === 0
                      ? translateCategory[section.category].title
                      : undefined
                  }
                >
                  {targets
                    .sort((a, b) => sortAlphabeticalAsc(a.title, b.title))
                    .map((target, i) => (
                      <SettingsItem
                        key={i}
                        onClick={() => setEditTarget(target)}
                        title={target.title}
                      />
                    ))}
                </SettingsGroup>
              ) : null}
            </Box>
          )
        })}

      {/*-- dialogs --*/}
      <SettingsEditDialog
        maxDate={maxDate.toISOString()}
        onChange={handleEditDialogValue}
        onClose={handleCloseDialog}
        onSubmit={handleSubmitDialog}
        open={dialog.open}
        options={dialog.data.options}
        title={dialog.title}
        type={dialog.type}
        unit={dialog.data.unit}
        value={dialog.data.value}
      />

      {editTarget ? (
        <TargetEditDialog
          description={editTarget.description}
          condition={editTarget.condition}
          unit={editTarget.unit}
          attributeValues={editTarget.attribute_values}
          attribute={editTarget.attribute}
          title={editTarget.title}
          onClose={() => setEditTarget(null)}
          open={Boolean(editTarget)}
          onUpdate={(values) => {
            tryPutTargetValues(editTarget.id, {
              data: {
                target_values: values.map((value) => ({
                  attribute_value: value.id,
                  value: value.value
                }))
              }
            })
          }}
        />
      ) : null}
    </Box>
  )
}

const mapStateToProps = (state: ApplicationState) => ({
  ConfigStore: state.ConfigStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...ConfigActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> & ConfigTemplateProps

export default connector(ConfigTemplate)
