import React from 'react'
import { Link } from 'react-router-dom'

import Box from 'components_new/atoms/Box'

import EditableTitle from 'components_new/molecules/EditableTitle'
import OverflowText from 'components_new/molecules/OverflowText'

import { DashboardFolder } from 'types/GlobalDashboardFolder'

interface PageTitleProps {
  editable: boolean
  folder?: DashboardFolder | null
  onSubmit: (value: string) => void
  sx?: object
  title: string
}

const PageTitle = (props: PageTitleProps) => {
  const { editable, folder, onSubmit, sx, title } = props

  return (
    <Box sx={sx}>
      {folder ? (
        <OverflowText
          color="text.secondary"
          variant="caption"
          sx={{ mb: 0.25 }}
        >
          <Link to={`/dashboards/folders/${folder?.id}`}>{folder?.name}</Link>
        </OverflowText>
      ) : null}

      <EditableTitle
        variant="h6"
        color="text.primary"
        value={title}
        onSubmit={onSubmit}
        disabled={!editable}
      />
    </Box>
  )
}

export default PageTitle
