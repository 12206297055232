import React, { MouseEvent, useState } from 'react'

import Icon from 'components_new/atoms/Icon'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Menu from 'components_new/atoms/Menu'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import Box from 'components_new/atoms/Box'
import TableCell from 'components_new/atoms/Table/TableCell'
import Tag from 'components_new/atoms/Tag'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

import TrendTag from 'components_new/molecules/TrendTag'

import {
  // ellipseLabel,
  getCellAlignment,
  getCellScaleSx
} from '../utils'
import { DatasetType, StatusData } from 'types/GlobalWidget'
import Chip from 'components_new/atoms/Chip'
import { EXPLORE_DISABLED_TOOLTIP, getExploreLabel } from 'utils/texts'

interface BodyCellProps {
  allowOnClick?: boolean
  color?: string
  divider: boolean
  increaseIsPositive?: boolean
  isComparativeButIllegal?: boolean
  label: string | number | null | string[] | number[]
  // numberOfCharacters?: number
  onClick?: () => void
  percentageDiff?: number | null
  scaleFactor: number
  segmentLabel?: string
  showDiff?: boolean
  status: StatusData
  type: DatasetType
  variant?: 'body' | 'footer' | 'head'
}

const BodyCell = (props: BodyCellProps) => {
  const {
    allowOnClick = false,
    color,
    divider,
    increaseIsPositive,
    isComparativeButIllegal,
    label,
    // numberOfCharacters,
    onClick,
    percentageDiff,
    scaleFactor,
    segmentLabel,
    showDiff,
    status,
    type,
    variant
  } = props
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const onClickDisabled = !onClick

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  return (
    <>
      <TableCell
        onClick={allowOnClick ? handleClick : undefined}
        align={getCellAlignment(type)}
        sx={{
          borderRight: divider ? '1px solid' : undefined,
          cursor: allowOnClick ? 'pointer' : undefined,
          '&:last-child': { borderRight: 0 },
          '&:hover': allowOnClick
            ? {
                bgcolor: 'action.hover',
                color: 'text.solid',
                textDecoration: 'underline'
              }
            : undefined,
          borderRightColor: 'divider',
          whiteSpace: 'nowrap',
          width: '1%', // For auto sized, fit content cells. (Hacker).
          ...getCellScaleSx(scaleFactor)
        }}
        variant={variant}
      >
        <TableCellBody
          color={color}
          increaseIsPositive={increaseIsPositive}
          isComparativeButIllegal={Boolean(isComparativeButIllegal)}
          label={label}
          percentageDiff={percentageDiff}
          showDiff={Boolean(showDiff)}
          status={status}
          type={type}
        />
      </TableCell>

      {/* ----- Menu ----- */}

      {allowOnClick ? (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          disableScrollLock={true}
          onClose={handleClose}
          open={open}
        >
          <Tooltip title={onClickDisabled ? EXPLORE_DISABLED_TOOLTIP : null}>
            <Box>
              <MenuItem
                disabled={onClickDisabled}
                onClick={(event) => {
                  handleClose(event)

                  onClick?.()
                }}
                onMouseDown={(event) => event.stopPropagation()}
              >
                <ListItemIcon>
                  <Icon name={'SearchOutlined'} fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={getExploreLabel(segmentLabel)} />
                <Chip
                  label={'Beta'}
                  color={'info'}
                  size={'small'}
                  sx={{ ml: 1 }}
                />
              </MenuItem>
            </Box>
          </Tooltip>
        </Menu>
      ) : null}
    </>
  )
}

interface TableCellBodyProps {
  color?: string
  increaseIsPositive?: boolean
  isComparativeButIllegal: boolean
  label: string | number | string[] | number[] | null
  percentageDiff?: number | null
  showDiff: boolean
  status: StatusData
  type: DatasetType
}

const TableCellBody = (props: TableCellBodyProps) => {
  const {
    color,
    increaseIsPositive,
    isComparativeButIllegal,
    label,
    percentageDiff,
    showDiff,
    status,
    type
  } = props

  const showTrendTag =
    !!showDiff &&
    percentageDiff != null &&
    increaseIsPositive !== undefined &&
    !isComparativeButIllegal

  if (type === DatasetType.ENUM) {
    return (
      // Use Chip in next release: <Chip label={label} size="small" />
      <Box sx={{ display: 'flex', gridGap: 4, justifyContent: 'center' }}>
        {(label as string[]).map((item, i) => (
          <Tag key={i} label={item} color={status ? status[i] : undefined} />
        ))}
      </Box>
    )
  }

  if (
    type === DatasetType.STRING ||
    type === DatasetType.DATE ||
    type === DatasetType.NUMBER_TAG
  ) {
    const getTextColor = (i: number) =>
      color || (status ? `${status[i]}.text` : undefined)

    return (
      <Box
        sx={{
          display: 'flex',
          gridGap: 4,
          justifyContent:
            type === DatasetType.NUMBER_TAG || type === DatasetType.DATE
              ? 'flex-end'
              : undefined
        }}
      >
        {(label as string[]).map((item, index, labels) => (
          <Text
            component="span"
            fontSize="inherit"
            key={index}
            variant="body2"
            sx={{ color: getTextColor(index) }}
          >
            {/* {ellipseLabel(label, numberOfCharacters)} */}
            {item}
            {index < labels.length - 1 ? ', ' : ''}
          </Text>
        ))}
      </Box>
    )
  }

  return (
    <>
      <Text component="span" fontSize="inherit" variant="body2" sx={{ color }}>
        {/* {ellipseLabel(label, numberOfCharacters)} */}
        {label}
      </Text>
      {showTrendTag ? (
        <TrendTag
          increaseIsPositive={increaseIsPositive}
          sx={{ ml: 1 }}
          value={percentageDiff}
        />
      ) : null}
    </>
  )
}

export default BodyCell
