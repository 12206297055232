import React from 'react'

import Box from 'components_new/atoms/Box'
import Grid from 'components_new/atoms/Grid'
import Icon from 'components_new/atoms/Icon'
import List from 'components_new/atoms/List'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Text from 'components_new/atoms/Text'

import NumberField from 'components_new/molecules/NumberField'
import OverflowText from 'components_new/molecules/OverflowText'
import SettingsToggleOption from 'components_new/molecules/SettingsToggleOption'

import {
  AutoAreaOptions,
  calculateTotalAllocation,
  translateAutoAreaOptions,
  validateTotalAllocation
} from 'types/GlobalAllocationKey'
import { IParsedRealEstateForAllocationKeys } from 'types/GlobalRealEstate'
import { formatNumber } from 'utils/numbers'

interface ISetAllocationPerRealEstateProps {
  allocations: { [key: string]: string }
  setAllocations: (keys: { [key: string]: string }) => void
  realEstates: IParsedRealEstateForAllocationKeys[]
}

const SetAllocationPerRealEstate = (
  props: ISetAllocationPerRealEstateProps
) => {
  const totalAllocation = calculateTotalAllocation(props.allocations)
  const validTotalAllocation = validateTotalAllocation(totalAllocation)

  return (
    <>
      <Text
        variant="h5"
        gutterBottom
        sx={{ mb: 2 }}
        color={validTotalAllocation.textColor}
      >
        {`Total fördelning: ${formatNumber(totalAllocation, 2)}%`}
      </Text>
      <Grid container={true} columns={4}>
        {Object.values(AutoAreaOptions).map((option) => {
          const translatedOption = translateAutoAreaOptions[option]

          return (
            <Grid item={true} key={option} xs={1}>
              <SettingsToggleOption
                iconName={translatedOption.iconName}
                onClick={() => {
                  const newInputs: { [key: string]: string } = {}
                  // eslint-disable-next-line max-len
                  let realEstateObjectAllocationKey: keyof IParsedRealEstateForAllocationKeys =
                    'percentOfTotalAtemp'

                  if (option === AutoAreaOptions.BOA) {
                    realEstateObjectAllocationKey = 'percentOfTotalBoa'
                  } else if (option === AutoAreaOptions.LOA) {
                    realEstateObjectAllocationKey = 'percentOfTotalLoa'
                  } else if (option === AutoAreaOptions.RENTABLE) {
                    realEstateObjectAllocationKey = 'percentOfTotalRentable'
                  }

                  props.realEstates.forEach((realEstate) => {
                    newInputs[realEstate.id] = realEstate[
                      realEstateObjectAllocationKey
                    ] as string
                  })

                  props.setAllocations(newInputs)
                }}
                selected={false}
                title={translatedOption.abbreviation}
                tooltip={
                  <>
                    <Text
                      color="text.secondary"
                      component="span"
                      fontSize="inherit"
                    >
                      Fördela per{' '}
                    </Text>
                    <Text component="span" fontSize="inherit" fontWeight="bold">
                      {translatedOption.label}
                    </Text>
                  </>
                }
              />
            </Grid>
          )
        })}
      </Grid>
      <List sx={{ mt: 2 }}>
        {props.realEstates
          .sort((a, b) => a.name.localeCompare(b.name, 'sv'))
          .map((realEstate) => {
            return (
              <ListItem
                key={realEstate.id}
                secondaryAction={
                  <NumberField
                    label="Andel (%)"
                    onChange={(value) =>
                      props.setAllocations({
                        ...props.allocations,
                        [realEstate.id]: value
                      })
                    }
                    precision={2}
                    size={'small'}
                    type={'decimal'}
                    value={props.allocations[realEstate.id] ?? '0'}
                  />
                }
              >
                <Icon color="disabled" name="AccountTreeOutlined" />
                <ListItemText
                  sx={{ ml: 3 }}
                  primary={realEstate.name}
                  primaryTypographyProps={{ fontWeight: 500 }}
                  secondary={
                    <Box sx={{ maxWidth: '70%' }}>
                      <OverflowText color="text.secondary" variant="caption">
                        {`atemp: ${realEstate.percentOfTotalAtemp}% • boa: ${realEstate.percentOfTotalBoa}% • loa: ${realEstate.percentOfTotalLoa}% • total uthyrningsbar: ${realEstate.percentOfTotalRentable}%`}
                      </OverflowText>
                    </Box>
                  }
                />
              </ListItem>
            )
          })}
      </List>
    </>
  )
}

export default SetAllocationPerRealEstate
