import React, { ElementType, MouseEvent, useState } from 'react'

import { Color } from 'themes'

import Button from 'components_new/atoms/Button'
import Icon, { IconNames } from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Tooltip from 'components_new/atoms/Tooltip'

import ButtonField from 'components_new/molecules/ButtonField'
import OptionsMenu, { OptionProps } from '../OptionsMenu'

interface OptionsMenuButtonProps {
  buttonComponent?: ElementType<any>
  ButtonProps?: object
  color?: Color | 'default' | 'disabled' | 'inherit'
  disabled?: boolean
  iconName?: IconNames
  MenuSx?: object
  options: OptionProps[]
  size?: 'small'
  sx?: object
  tooltip?: string
  variant?: 'button' | 'buttonField' | 'icon'
}

/**
 * The OptionMenuButton component is used for
 * displaying one or more options (Icon
 * + Text) in a list when clicking a
 * variant of a button.
 */

const OptionsMenuButton = React.forwardRef(
  (props: OptionsMenuButtonProps, ref: any) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      setAnchorEl(event.currentTarget)
    }

    const handleClose = (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      setAnchorEl(null)
    }

    return (
      <>
        {props.variant === 'icon' ? (
          <Tooltip title={props.tooltip ?? ''}>
            <IconButton
              color={props.color ?? 'inherit'}
              component={props.buttonComponent}
              disabled={props.disabled}
              onClick={handleClick}
              onMouseDown={(event) => event.stopPropagation()}
              ref={ref}
              size="small"
              sx={props.sx}
            >
              <Icon
                name={props.iconName ?? 'MoreVertOutlined'}
                sx={props.size === 'small' ? { fontSize: 12 } : undefined}
              />
            </IconButton>
          </Tooltip>
        ) : props.variant === 'button' ? (
          <Button
            {...props.ButtonProps}
            disabled={props.disabled}
            onClick={handleClick}
            onMouseDown={(event) => event.stopPropagation()}
            ref={ref}
            sx={props.sx}
          >
            {props.tooltip}
          </Button>
        ) : (
          <ButtonField
            onClick={handleClick}
            onMouseDown={(event) => event.stopPropagation()}
            sx={props.sx}
          >
            {props.tooltip}
          </ButtonField>
        )}
        <OptionsMenu
          anchorEl={anchorEl}
          disableScrollLock={true}
          onClose={handleClose}
          open={open}
          options={props.options}
          sx={props.MenuSx}
        />
      </>
    )
  }
)

OptionsMenuButton.defaultProps = {
  variant: 'icon'
}

OptionsMenuButton.displayName = 'OptionsMenuButton'
export default OptionsMenuButton
