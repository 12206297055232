import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react'

import Autocomplete from 'components_new/atoms/Autocomplete'
import Avatar from 'components_new/atoms/Avatar'
import Box from 'components_new/atoms/Box'
import Collapse from 'components_new/atoms/Collapse'
import List from 'components_new/atoms/List'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemAvatar from 'components_new/atoms/List/ListItemAvatar'
import ListItemText from 'components_new/atoms/List/ListItemText'
import TextField from 'components_new/atoms/TextField'

import SectionHeader from 'components_new/molecules/SectionHeader'

import {
  PermissionPatchType,
  UserType
} from 'components_new/organisms/Dashboard/utils'

import PermissionItem from './PermissionItem'
import WhoCanViewItem from '../WhoCanViewSection/WhoCanViewItem'

import { WhoCanView } from 'redux/reducers/Dashboards'

interface PermissionSectionProps {
  addUserInputRef?: RefObject<HTMLElement>
  fullRights: boolean
  permissions: UserType[]
  onInvite: (newPermission: PermissionPatchType) => void
  onRemove: (accountId: string) => void
  userOptions: UserType[]
  whoCanView: WhoCanView
}

const PermissionSection = (props: PermissionSectionProps) => {
  const {
    addUserInputRef,
    permissions,
    fullRights,
    onInvite,
    onRemove,
    userOptions,
    whoCanView
  } = props

  const notAddedUserOptions = useMemo(
    () =>
      userOptions.filter(
        (user: UserType) =>
          !permissions.some(
            (currentUser) => currentUser.accountId === user.accountId
          )
      ),
    [permissions, userOptions]
  )

  const filteredPermissions = useMemo(() => {
    if (whoCanView === WhoCanView.ORGANIZATION) {
      return permissions.filter((permission) => permission.owner)
    } else {
      return permissions
    }
  }, [permissions, whoCanView])

  const ref = useRef<HTMLElement>(null)

  const [scrollTop, setScrollTop] = useState(true)
  const [scrollBottom, setScrollBottom] = useState(true)

  useEffect(() => {
    const element = ref.current
    const handleScroll = () => {
      // Check scroll at top
      if (element && element.scrollTop === 0) {
        setScrollTop(true)
      } else {
        setScrollTop(false)
      }
      // Check scroll at bottom
      if (
        element &&
        element.scrollTop > element.scrollHeight - element.offsetHeight
      ) {
        setScrollBottom(true)
      } else {
        setScrollBottom(false)
      }
    }

    if (element) {
      element.addEventListener('scroll', handleScroll)

      // Clean-up
      return () => {
        element.removeEventListener('scroll', handleScroll)
      }
    }

    return
  }, [])

  return (
    <Box>
      <Collapse in={whoCanView === WhoCanView.ONLY_ADDED_USERS}>
        <Autocomplete
          blurOnSelect={true}
          disableClearable
          value={null}
          onChange={(event, newUser: unknown) => {
            const user = newUser as UserType

            onInvite({
              accountId: user.accountId,
              companyGroupId: user.companyGroupId
                ? user.companyGroupId
                : undefined,
              allowRevoke: true
            })
          }}
          options={notAddedUserOptions.filter((user) => !user.companyGroupId)}
          filterSelectedOptions
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option.accountId === value.accountId
          }
          renderOption={(props, option) => (
            <ListItem {...props}>
              <ListItemAvatar>
                <Avatar alt={option.name} />
              </ListItemAvatar>
              <ListItemText primary={option.name} secondary={option.email} />
            </ListItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={addUserInputRef}
              label="Lägg till användare"
              placeholder="Sök..."
            />
          )}
          renderTags={() => null}
          sx={{
            '& .MuiFormControl-root': {
              mt: 0,
              mb: 2
            }
          }}
        />
      </Collapse>
      <SectionHeader title="Användare med åtkomst" />
      <List
        ref={ref}
        dense={true}
        sx={{
          pt: 0,
          maxHeight: '30vh',
          overflowY: 'auto',
          borderTop: scrollTop ? undefined : '1px solid',
          borderBottom: scrollBottom ? undefined : '1px solid',
          borderColor: 'divider'
        }}
      >
        <Collapse in={whoCanView === WhoCanView.ORGANIZATION}>
          <WhoCanViewItem
            fullRights={fullRights}
            whoCanView={WhoCanView.ORGANIZATION}
          />
        </Collapse>
        {filteredPermissions
          .sort((a, b) => a.name.localeCompare(b.name, 'sv'))
          .map((user, i) => (
            <PermissionItem
              body={user.email}
              editable={fullRights && user.allowRevoke}
              fullRights={fullRights}
              isOwner={!!user.owner}
              key={i}
              onDelete={() => onRemove(user.accountId)}
              role={user.role}
              title={user.name}
            />
          ))}
      </List>
    </Box>
  )
}

export default PermissionSection
