import React, { useState } from 'react'

import { useTheme } from '@mui/material'
import { getColorMode, getTheme } from 'themes'

import { ColorSchemePatchBody } from 'redux/api/Admin/Customization'
import { CustomColors } from 'types/GlobalCustomization'

import Alert from 'components_new/atoms/Alert'
import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import Link from 'components_new/atoms/Link'

import ButtonField from 'components_new/molecules/ButtonField'
import ColorInput from 'components_new/molecules/ColorInput'
import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'

import EditLogotypeDialog from './EditLogotypeDialog'

export type InnerCustomizationBody = ColorSchemePatchBody['data']
interface CustomizationProps {
  customColors: CustomColors
  setCustomColors: (params: InnerCustomizationBody) => void
  allowWhiteLabelColors: boolean
  allowWhiteLabelLogotype: boolean
}

const CustomizationTemplate = (props: CustomizationProps) => {
  const { customColors, setCustomColors } = props

  const theme = useTheme()

  const [editLogotype, setEditLogotype] = useState<boolean>(false)

  const resetGraphColors = () => {
    setCustomColors({
      primary_color: null,
      secondary_color: null,
      tertiary_color: null,
      quaternary_color: null,
      quintary_color: null,
      senary_color: null,
      septenary_color: null,
      octonary_color: null,
      nonary_color: null,
      denary_color: null
    })
  }

  const resetAllColors = () => {
    setCustomColors({
      primary_color: null,
      secondary_color: null,
      tertiary_color: null,
      quaternary_color: null,
      quintary_color: null,
      senary_color: null,
      septenary_color: null,
      octonary_color: null,
      nonary_color: null,
      denary_color: null,
      text_primary_color: null,
      text_secondary_color: null,
      background_color: null,
      widget_background_color: null
    })
  }

  const getPrimaryColor = () => {
    if (customColors?.primary_color) {
      return customColors?.primary_color
    }

    if (customColors?.widget_background_color) {
      return getTheme(getColorMode(customColors.widget_background_color))
        .palette.primary.main
    }

    return theme.palette.primary.main
  }

  const getPrimaryTextColor = () => {
    if (customColors?.text_primary_color) {
      return customColors?.text_primary_color
    }

    if (customColors?.widget_background_color) {
      return getTheme(getColorMode(customColors.widget_background_color))
        .palette.text.primaryHex
    }

    return theme.palette.text.primaryHex
  }

  const getSecondaryTextColor = () => {
    if (customColors?.text_secondary_color) {
      return customColors?.text_secondary_color
    }

    if (customColors?.widget_background_color) {
      return getTheme(getColorMode(customColors.widget_background_color))
        .palette.text.secondaryHex
    }

    return theme.palette.text.secondaryHex
  }

  return (
    <Box sx={{ maxWidth: 'content' }}>
      <SettingsGroup
        helperText="Anpassa logotyp så att detta matchar er grafiska profil och
            identitet."
      >
        <SettingsItem
          actions={
            !props.allowWhiteLabelLogotype ? (
              <>
                <Alert severity="warning" icon={<Icon name="PrivacyTip" />}>
                  <strong>Anpassad logotyp ingår inte i din licens.</strong>{' '}
                  Kontakta{' '}
                  <Link href={'mailto:support@homepal.se'}>
                    support@homepal.se
                  </Link>{' '}
                  om du vill aktivera den här funktionen.
                </Alert>
              </>
            ) : (
              <Box sx={{ height: 72 }}>
                {customColors.logotype ? (
                  <Box
                    onClick={() => setEditLogotype(true)}
                    sx={{
                      position: 'relative',
                      height: '100%',
                      cursor: 'pointer',
                      display: 'flex',
                      flexDirection: 'column',
                      bgcolor: 'action.hover',
                      '&:hover': {
                        bgcolor: 'action.selected'
                      },
                      width: 'fit-content',
                      p: 1,
                      borderRadius: 1
                    }}
                  >
                    <Icon
                      fontSize="small"
                      name="EditOutlined"
                      sx={{ position: 'absolute', top: 4, right: 4 }}
                    />
                    <img
                      src={customColors.logotype}
                      style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'contain'
                      }}
                    />
                  </Box>
                ) : (
                  <ButtonField
                    fullHeight={true}
                    onClick={() => setEditLogotype(true)}
                    sx={{ width: 144 }}
                  >
                    Lägg till
                  </ButtonField>
                )}
              </Box>
            )
          }
          title={props.allowWhiteLabelLogotype ? 'Logotyp' : undefined}
        />
      </SettingsGroup>

      <SettingsGroup
        helperText={
          'Anpassa färgschema så att det matchar organisationens grafiska profil och identitet.'
        }
        title="Färger"
      >
        <SettingsItem
          actions={
            <>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                <ColorInput
                  hexCode={getPrimaryColor()}
                  isDefault={!customColors?.primary_color}
                  title="Primär"
                  onChange={(hexCode: string) =>
                    setCustomColors({ primary_color: hexCode })
                  }
                />
                <ColorInput
                  hexCode={
                    customColors?.secondary_color ??
                    theme.palette.secondary.main
                  }
                  isDefault={!customColors?.secondary_color}
                  title="Sekundär"
                  onChange={(hexCode: string) =>
                    setCustomColors({ secondary_color: hexCode })
                  }
                />
                <ColorInput
                  hexCode={
                    customColors?.tertiary_color ?? theme.palette.tertiary.main
                  }
                  isDefault={!customColors?.tertiary_color}
                  title="Tertiär"
                  onChange={(hexCode: string) =>
                    setCustomColors({ tertiary_color: hexCode })
                  }
                />
                <ColorInput
                  hexCode={
                    customColors?.quaternary_color ??
                    theme.palette.quaternary.main
                  }
                  isDefault={!customColors?.quaternary_color}
                  title="#4"
                  onChange={(hexCode: string) =>
                    setCustomColors({ quaternary_color: hexCode })
                  }
                />
                <ColorInput
                  hexCode={
                    customColors?.quintary_color ?? theme.palette.quintary.main
                  }
                  isDefault={!customColors?.quintary_color}
                  title="#5"
                  onChange={(hexCode: string) =>
                    setCustomColors({ quintary_color: hexCode })
                  }
                />
                <ColorInput
                  hexCode={
                    customColors?.senary_color ?? theme.palette.senary.main
                  }
                  isDefault={!customColors?.senary_color}
                  title="#6"
                  onChange={(hexCode: string) =>
                    setCustomColors({ senary_color: hexCode })
                  }
                />
                <ColorInput
                  hexCode={
                    customColors?.septenary_color ??
                    theme.palette.septenary.main
                  }
                  isDefault={!customColors?.septenary_color}
                  title="#7"
                  onChange={(hexCode: string) =>
                    setCustomColors({ septenary_color: hexCode })
                  }
                />
                <ColorInput
                  hexCode={
                    customColors?.octonary_color ?? theme.palette.octonary.main
                  }
                  isDefault={!customColors?.octonary_color}
                  title="#8"
                  onChange={(hexCode: string) =>
                    setCustomColors({ octonary_color: hexCode })
                  }
                />
                <ColorInput
                  hexCode={
                    customColors?.nonary_color ?? theme.palette.nonary.main
                  }
                  isDefault={!customColors?.nonary_color}
                  title="#9"
                  onChange={(hexCode: string) =>
                    setCustomColors({ nonary_color: hexCode })
                  }
                />
                <ColorInput
                  hexCode={
                    customColors?.denary_color ?? theme.palette.denary.main
                  }
                  isDefault={!customColors?.denary_color}
                  title="#10"
                  onChange={(hexCode: string) =>
                    setCustomColors({ denary_color: hexCode })
                  }
                />
                <Button
                  disabled={
                    !Boolean(customColors?.primary_color) &&
                    !Boolean(customColors?.secondary_color) &&
                    !Boolean(customColors?.tertiary_color) &&
                    !Boolean(customColors?.quaternary_color) &&
                    !Boolean(customColors?.quintary_color) &&
                    !Boolean(customColors?.senary_color) &&
                    !Boolean(customColors?.septenary_color) &&
                    !Boolean(customColors?.octonary_color) &&
                    !Boolean(customColors?.nonary_color) &&
                    !Boolean(customColors?.denary_color)
                  }
                  onClick={resetGraphColors}
                  size="small"
                  variant="text"
                >
                  Auto
                </Button>
              </Box>
            </>
          }
          title="Grafer"
        />
      </SettingsGroup>

      <SettingsGroup
        helperText={
          <>
            Bakgrund och text anpassas automatiskt efter applikationens
            färgläge. Om du väljer att anpassa dessa färger kommer den
            automatiska anpassningen att inaktiveras. Vi rekommenderar därför{' '}
            <u>starkt</u> att ha kvar både bakgrund och text på{' '}
            <code>auto</code>.
          </>
        }
      >
        <SettingsItem
          actions={
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              <ColorInput
                hexCode={
                  customColors?.background_color ?? theme.palette.white.main
                }
                isDefault={!customColors?.background_color}
                title="Dashboard"
                onChange={(hexCode: string) =>
                  setCustomColors({ background_color: hexCode })
                }
              />
              <ColorInput
                hexCode={
                  customColors?.widget_background_color ??
                  theme.palette.white.main
                }
                isDefault={!customColors?.widget_background_color}
                title="Widget"
                onChange={(hexCode: string) =>
                  setCustomColors({ widget_background_color: hexCode })
                }
              />
              <Button
                disabled={
                  !Boolean(customColors?.background_color) &&
                  !Boolean(customColors?.widget_background_color)
                }
                onClick={() =>
                  setCustomColors({
                    background_color: null,
                    widget_background_color: null
                  })
                }
                size="small"
                variant="text"
              >
                Auto
              </Button>
            </Box>
          }
          title="Bakgrund"
        />

        <SettingsItem
          actions={
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              <ColorInput
                hexCode={getPrimaryTextColor()}
                isDefault={!customColors?.text_primary_color}
                title="Primär"
                onChange={(hexCode: string) =>
                  setCustomColors({ text_primary_color: hexCode })
                }
              />
              <ColorInput
                hexCode={getSecondaryTextColor()}
                isDefault={!customColors?.text_secondary_color}
                title="Sekundär"
                onChange={(hexCode: string) =>
                  setCustomColors({ text_secondary_color: hexCode })
                }
              />
              <Button
                disabled={
                  !Boolean(customColors?.text_primary_color) &&
                  !Boolean(customColors?.text_secondary_color)
                }
                onClick={() =>
                  setCustomColors({
                    text_primary_color: null,
                    text_secondary_color: null
                  })
                }
                size="small"
                variant="text"
              >
                Auto
              </Button>
            </Box>
          }
          title="Text"
        />
      </SettingsGroup>

      <SettingsGroup
        helperText={
          <>
            <strong>Tänk på!</strong> Du bör undvika att välja en grön, gul
            eller röd färg i dina anpassade graf- och textfärger ovan. Dessa
            används redan till att indikera positiv eller negativ utveckling i
            ett nyckeltal, och i vissa fall även som statusindikator.
          </>
        }
      >
        <SettingsItem
          actions={
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              <ColorInput
                disabled={true}
                hexCode={theme.palette.success.main}
                isDefault={true}
                title={'Positiv färg'}
              />
              <ColorInput
                disabled={true}
                hexCode={theme.palette.warning.main}
                isDefault={true}
                title={'Varningsfärg'}
              />
              <ColorInput
                disabled={true}
                hexCode={theme.palette.error.main}
                isDefault={true}
                title={'Negativ färg'}
              />
            </Box>
          }
          title="Status"
        />
      </SettingsGroup>

      <SettingsGroup>
        <SettingsItem
          color="info"
          onClick={resetAllColors}
          title="Återställ alla"
          variant="action"
        />
      </SettingsGroup>

      <EditLogotypeDialog
        open={editLogotype}
        onClose={() => setEditLogotype(false)}
        onSave={(value) => setCustomColors({ logotype: value })}
        value={customColors.logotype}
      />
    </Box>
  )
}

export default CustomizationTemplate
