import { ChangeEvent } from 'react'

export type TInputType =
  | 'decimal'
  | 'number'
  | 'multiselect'
  | 'select'
  | 'text'
  | 'year_month'

export type TInputOption = { disabled?: boolean; label: string; value: any }

export const createChangeEvent = (value: string) => {
  const inputElement: HTMLInputElement = document.createElement('input')

  // synthetic event in order to manually set value
  const event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> = {
    target: {
      ...inputElement,
      value: value
    } as EventTarget & (HTMLInputElement | HTMLTextAreaElement),
    currentTarget: inputElement,
    bubbles: true,
    cancelable: true,
    defaultPrevented: false,
    eventPhase: 3,
    isTrusted: false,
    nativeEvent: {} as Event,
    preventDefault: () => {},
    isDefaultPrevented: () => false,
    stopPropagation: () => {},
    isPropagationStopped: () => false,
    persist: () => {},
    timeStamp: Date.now(),
    type: 'change'
  }

  return event
}

export const isResetable = (type: TInputType, options?: TInputOption[]) => {
  // checks if the returned value can be set to ''
  if (type === 'select' && options) {
    return options?.some((option) => option.value === '')
  } else {
    return true
  }
}
