import React, { MouseEvent, useState } from 'react'

import { ConnectedProps, connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { ApplicationState } from 'redux/Stores/types'

import * as DashboardActions from 'redux/actions/Dashboards'
import * as DashboardFolderActions from 'redux/actions/DashboardFolders'

import { AccountRole } from 'types/GlobalUser'

import { useHistory, useLocation, useParams } from 'react-router-dom'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Fab from 'components_new/atoms/Fab'
import Icon from 'components_new/atoms/Icon'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Menu from 'components_new/atoms/Menu'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import TextField from 'components_new/atoms/TextField'
import Tooltip from 'components_new/atoms/Tooltip'

import SimpleDialog from 'components_new/molecules/SimpleDialog'

import { Customer } from 'types/GlobalCustomer'

interface NewButtonProps {
  sx?: object
}

const NewButton = (props: ComponentProps) => {
  const {
    sx,
    // redux stores
    AuthStore,
    NavigationMenuStore,
    // redux actions
    tryCreateDashboard,
    tryCreateDashboardFolder
  } = props

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)
  const params = useParams<{ folderId?: string }>()
  const history = useHistory()

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [createFolderDialog, setCreateFolderDialog] = useState<{
    open: boolean
    value: string
  }>({ open: false, value: '' })

  const handleCreateDashboard = () => {
    tryCreateDashboard(
      {
        data: {
          folder_id: params.folderId
        }
      },
      (id) => {
        history.push(`/dashboards/${id}`)
      }
    )
  }

  const userIsCustomer = Boolean(AuthStore?.user?.is_company_group)

  // A customer should never be able to create anything:
  if (userIsCustomer) return null

  const userCanEditFolder = Boolean(
    AuthStore.user?.role === AccountRole.ADMIN ||
      AuthStore.user?.is_homepal_user
  )

  const collapsed = !NavigationMenuStore?.open
  const location = useLocation()

  const createFolderDisabled = location.pathname.includes('folders')

  const numberOfDashboardsInLicense = (AuthStore.customer as Customer)
    .number_of_dashboards
  const numberOfDashboardsInUse = (AuthStore.customer as Customer)
    .number_of_dashboards_in_use

  let dashboardLimitReached = false
  let currentUseOfLicense = undefined

  if (numberOfDashboardsInLicense) {
    dashboardLimitReached =
      numberOfDashboardsInUse >= numberOfDashboardsInLicense

    currentUseOfLicense = `${numberOfDashboardsInUse} av ${numberOfDashboardsInLicense}`
  }

  return (
    <>
      <Box sx={sx}>
        {
          // default button
          !collapsed ? (
            <Button
              color="neutral"
              onClick={(event: MouseEvent<HTMLElement>) =>
                setAnchorEl(event.currentTarget)
              }
              size="large"
              startIcon={<Icon name="AddOutlined" />}
              sx={{ py: 2, borderRadius: 4 }}
              variant="contained"
            >
              Nytt
            </Button>
          ) : (
            // collapsed button
            <Tooltip placement="right" title="Nytt">
              <>
                <Fab
                  color="neutral"
                  onClick={(event: MouseEvent<HTMLElement>) =>
                    setAnchorEl(event.currentTarget)
                  }
                  size="small"
                >
                  <Icon name="AddOutlined" />
                </Fab>
              </>
            </Tooltip>
          )
        }
      </Box>

      {/*-- menu --*/}
      <Menu
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}
        PaperProps={{ sx: { minWidth: 320 } }}
      >
        {userCanEditFolder ? (
          <Tooltip
            placement="right"
            title={
              createFolderDisabled
                ? 'Det går inte att skapa en mapp i en annan mapp.'
                : undefined
            }
          >
            <span>
              <MenuItem
                disabled={createFolderDisabled}
                onClick={() => {
                  setCreateFolderDialog({ open: true, value: '' })
                  handleClose()
                }}
              >
                <ListItemIcon>
                  <Icon name="CreateNewFolderOutlined" />
                </ListItemIcon>
                Ny mapp
              </MenuItem>
            </span>
          </Tooltip>
        ) : null}
        <Tooltip
          placement="right"
          title={
            dashboardLimitReached
              ? 'Din organisation använder alla dashboards som ingår i er licens. Ta bort en dashboard eller kontakta oss för att utöka din licens.'
              : undefined
          }
        >
          <span>
            <MenuItem
              disabled={dashboardLimitReached}
              onClick={() => {
                handleCreateDashboard()
                handleClose()
              }}
            >
              <ListItemIcon>
                <Icon name="DashboardCustomizeOutlined" />
              </ListItemIcon>
              <ListItemText
                secondary={currentUseOfLicense}
                secondaryTypographyProps={{
                  color: 'text.secondary',
                  fontWeight: 'medium',
                  variant: 'caption'
                }}
              >
                Ny dashboard
              </ListItemText>
            </MenuItem>
          </span>
        </Tooltip>
      </Menu>

      <SimpleDialog
        actions={
          <>
            <Button
              variant="text"
              onClick={() => {
                setCreateFolderDialog({ open: false, value: '' })
              }}
            >
              Avbryt
            </Button>
            <Button
              disabled={createFolderDialog.value.length === 0}
              variant="contained"
              size="large"
              onClick={() => {
                tryCreateDashboardFolder({
                  data: { name: createFolderDialog.value }
                })

                setCreateFolderDialog({ open: false, value: '' })
              }}
            >
              Lägg till
            </Button>
          </>
        }
        onClose={() => {
          setCreateFolderDialog({ open: false, value: '' })
        }}
        open={createFolderDialog.open}
        title={'Ny mapp'}
      >
        <TextField
          label="Namn"
          required={true}
          autoComplete="off"
          value={createFolderDialog.value}
          onChange={(event) => {
            setCreateFolderDialog((prevState) => ({
              ...prevState,
              value: event.target.value
            }))
          }}
          sx={{ mt: 1, mb: 2 }}
        />
      </SimpleDialog>
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  NavigationMenuStore: state.NavigationMenuStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...DashboardActions,
      ...DashboardFolderActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type ComponentProps = ConnectedProps<typeof connector> & NewButtonProps

export default connector(NewButton)
