import React, { MouseEvent, useState } from 'react'

import { ChromePicker } from 'react-color'

import { Popover } from '@mui/material'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import Tooltip from 'components_new/atoms/Tooltip'

import { getContrastTextColor } from 'themes'
import { EDIT_ICON } from 'utils/icons'

interface ColorInputProps {
  disabled?: boolean
  hexCode: string
  isDefault: boolean
  onChange?: (hexCode: string) => void
  sx?: object
  title?: string
}

const ColorInput = React.forwardRef((props: ColorInputProps, ref: any) => {
  const { isDefault, disabled, hexCode, onChange, sx, title } = props

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const [editColor, setEditColor] = useState<string>(hexCode)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleChange = () => {
    if (editColor !== hexCode && onChange) {
      onChange(editColor)
    }
    setAnchorEl(null)
  }

  return (
    <>
      <Tooltip title={title}>
        <Box
          onClick={(event) => {
            disabled ? null : handleClick(event)
          }}
          ref={ref}
          sx={{
            position: 'relative',
            cursor: disabled ? 'default' : 'pointer',
            width: 32,
            height: 32,
            borderRadius: 16,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: hexCode,
            border: '1px solid',
            borderColor: 'divider',
            transition: 'all 240ms ease 0s',
            '&:hover': !disabled
              ? {
                  borderColor: 'action.focus'
                }
              : undefined,
            ...sx
          }}
        >
          {disabled ? (
            <Icon
              fontSize="small"
              name="LockOutlined"
              sx={{
                color: getContrastTextColor(hexCode)
              }}
            />
          ) : null}
          {!isDefault ? (
            <Icon
              fontSize="small"
              name={EDIT_ICON}
              sx={{
                color: getContrastTextColor(hexCode)
              }}
            />
          ) : null}
        </Box>
      </Tooltip>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleChange}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <ChromePicker
          color={editColor}
          onChange={(value) => setEditColor(value.hex)}
          disableAlpha
        />
      </Popover>
    </>
  )
})

export default ColorInput
