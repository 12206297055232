import React from 'react'

import Box from 'components_new/atoms/Box'
import Table from 'components_new/atoms/Table'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableRow from 'components_new/atoms/Table/TableRow'
import Tooltip from 'components_new/atoms/Tooltip'
import Text from 'components_new/atoms/Text'

interface SystemsProps {
  sx?: object
  systems: any[] | undefined
}

const Systems = (props: SystemsProps) => {
  const { sx, systems } = props

  if (systems === undefined) return null

  let otherSystems: any[] = []
  let pmSystems: any[] = []

  if (systems !== undefined) {
    otherSystems = systems?.filter(
      (item: any) => item.fields.category?.fields.slug !== 'portfolio'
    )

    pmSystems = systems?.filter(
      (item: any) => item.fields.category?.fields.slug === 'portfolio'
    )
  }

  return (
    <>
      <Text component="div" gutterBottom={true} variant="h5">
        Vilka system kan användas?
      </Text>
      <Table sx={sx}>
        {pmSystems && pmSystems.length > 0 ? (
          <TableRow>
            <TableCell>Exempel på fastighetssystem</TableCell>
            <TableCell align="right" sx={{ width: '0px' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: 1
                }}
              >
                {pmSystems.map((system: any) => (
                  <>
                    <Tooltip
                      key={system.fields.slug}
                      title={system.fields.title}
                    >
                      <span>
                        <img
                          src={system.fields.icon.fields.file.url}
                          height={24}
                          width={24}
                        />
                      </span>
                    </Tooltip>
                    <Box sx={{ '&:last-child': { display: 'none' } }}>/</Box>
                  </>
                ))}
              </Box>
            </TableCell>
          </TableRow>
        ) : null}
        {otherSystems && otherSystems.length > 0 ? (
          <TableRow>
            <TableCell>Exempel på kompletterande system</TableCell>
            <TableCell align="right" sx={{ width: '0px' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: 1
                }}
              >
                {otherSystems.map((system: any) => (
                  <>
                    <Tooltip
                      key={system.fields.slug}
                      title={system.fields.title}
                    >
                      <span>
                        <img
                          src={system.fields.icon.fields.file.url}
                          height={24}
                          width={24}
                        />
                      </span>
                    </Tooltip>
                    <Box sx={{ '&:last-child': { display: 'none' } }}>/</Box>
                  </>
                ))}
              </Box>
            </TableCell>
          </TableRow>
        ) : null}
      </Table>
    </>
  )
}

export default Systems
