import { Dispatch } from 'redux'
import * as Types from 'redux/Types'

import { getAllTagOptions } from 'redux/api/TagOptions'

export function tryGetAllTagOptions() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_ALL_TAG_OPTIONS
    })

    getAllTagOptions()
      .then((response) => {
        dispatch({
          type: Types.GET_ALL_TAG_OPTIONS_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ALL_TAG_OPTIONS_FAILED
        })
      })
  }
}
