import React from 'react'

import Box from 'components_new/atoms/Box'
import ListItem from 'components_new/atoms/List/ListItem'
import Skeleton from 'components_new/atoms/Skeleton'

const Loading = () => {
  return (
    <>
      <Box sx={{ mb: 2 }}>
        <ListItem>
          <Skeleton variant="text" width={128} />
        </ListItem>
        <ListItem>
          <Skeleton variant="text" width={180} />
        </ListItem>
        <ListItem>
          <Skeleton variant="text" width={180} />
        </ListItem>
        <ListItem>
          <Skeleton variant="text" width={180} />
        </ListItem>
      </Box>

      <Box>
        <ListItem>
          <Skeleton variant="text" width={128} />
        </ListItem>
        <ListItem>
          <Skeleton variant="text" width={180} />
        </ListItem>
        <ListItem>
          <Skeleton variant="text" width={180} />
        </ListItem>
        <ListItem>
          <Skeleton variant="text" width={180} />
        </ListItem>
      </Box>
    </>
  )
}

Loading.displayName = 'Loading'

export default Loading
