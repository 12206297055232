import React, { useMemo } from 'react'

import Stack from 'components_new/atoms/Stack'
import { parseLabel } from 'components_new/organisms/Dashboard/utils'

import FunnelEvent from './FunnelEvent'
import {
  WidgetObject,
  WidgetType,
  WidgetData,
  FunnelStatus,
  DefaultDatasetData,
  ParsedSegmentPath
} from 'types/GlobalWidget'

import { translateWidgetType } from 'utils/enumTranslator'

import NoValue from '../../errors/NoValue'
import { KpiOptionObject } from 'types/GlobalKpiOption'

interface FunnelProps {
  clipContent?: boolean
  embedded: boolean
  scaleFactor: number
  setDetailsDialog: (segments: ParsedSegmentPath[]) => void
  widget: WidgetObject
}

const Funnel = (props: FunnelProps) => {
  const { clipContent, embedded, scaleFactor, setDetailsDialog, widget } = props

  const data = widget.data as WidgetData

  const stages = useMemo(
    () => widget.settings.segment_by.stages.filter((stage) => !stage.hidden),
    [widget.settings.segment_by.stages]
  )

  const [indexMapper, totalValue, maxValue] = useMemo(() => {
    const mapper: { [title: string]: number } = {}

    const allValues =
      (data.data?.[0]?.data as DefaultDatasetData)?.map((val) => val || 0) || []
    const includedValues: number[] = []

    const dataLabels = data.labels.map((item) => item.label)

    stages.forEach((stage) => {
      const index = dataLabels.indexOf(stage.title)

      mapper[stage.title] = allValues[index] || 0

      if (stage.status === FunnelStatus.UNSET) {
        includedValues.push(allValues[index] || 0)
      }
    })

    const totalValue = includedValues.reduce((acc, curr) => acc + curr, 0)
    const maxValue = Math.max(...includedValues)

    return [mapper, totalValue, maxValue]
  }, [widget.data, stages])

  return data?.data?.length > 0 ? (
    <Stack
      sx={{
        width: '100%',
        flexGrow: 1,
        overflowY: clipContent ? 'hidden' : undefined
      }}
    >
      {stages.map((stage) => (
        <FunnelEvent
          key={stage.index}
          interactive={!embedded}
          kpiOption={widget.settings.kpi_options[0] as KpiOptionObject}
          maxValue={maxValue}
          stage={stage}
          setDetailsDialog={(label) =>
            setDetailsDialog([
              {
                attribute_option_id: widget.settings.segment_by
                  .selected as string,
                label,
                display_label: parseLabel({
                  label,
                  technical_name: widget.settings.segment_by
                    .technical_name as string,
                  type: widget.settings.segment_by.type
                }),
                // no need for details
                segment_options: {},
                period: null,
                type: widget.settings.segment_by.type,
                technical_name: widget.settings.segment_by
                  .technical_name as string
              }
            ])
          }
          totalValue={totalValue}
          value={indexMapper[stage.title]}
        />
      ))}
    </Stack>
  ) : (
    <NoValue
      iconName={translateWidgetType[WidgetType.FUNNEL].iconName}
      embedded={embedded}
      scaleFactor={scaleFactor}
    />
  )
}

Funnel.defaultProps = {
  interactive: false
}

export default Funnel
