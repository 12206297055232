import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import Authentication from 'components/containers/Admin/Authentication'

import AllocationKeys from 'components_new/pages/settings/AllocationKeys'
import ChartOfAccounts from 'components_new/pages/settings/ChartOfAccounts'
import Companies from 'components_new/pages/settings/Companies'
import Configuration from 'components_new/pages/settings/Configuration'
import Customization from 'components_new/pages/settings/Customization'
import Economy from 'components_new/pages/settings/Economy'
import License from 'components_new/pages/settings/License'
import SimRenamings from 'components_new/pages/settings/SimRenamings'
import ApiKeys from 'components_new/pages/settings/Apikeys'
import Users from 'components_new/pages/settings/Users'

import AuthRoute from './AuthRoute'

const Routes = ({ AuthStore }) => {
  return (
    <Switch>
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={() => <Users />}
        path={'/admin/settings/users'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={() => <Companies />}
        path={'/admin/settings/companies'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={() => <License />}
        path={'/admin/settings/license'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={() => <Configuration />}
        path={'/admin/settings/config'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={() => <ChartOfAccounts />}
        path={'/admin/settings/chart-of-accounts'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={() => <AllocationKeys />}
        path={'/admin/settings/allocation-keys'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={() => <Economy />}
        path={'/admin/settings/economy'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={() => <Customization />}
        path={'/admin/settings/customization'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={() => <SimRenamings />}
        path={'/admin/settings/terminologi'}
        exact
      />
      <AuthRoute
        isAuthenticated={
          AuthStore.isAuthenticated &&
          !!AuthStore.customer?.is_management_company
        }
        Component={() => <ApiKeys />}
        path={'/admin/settings/api-keys'}
        exact
      />
      <AuthRoute
        isAuthenticated={AuthStore.isAuthenticated}
        Component={() => <Authentication />}
        path={'/admin/settings/authentication'}
        exact
      />
      <Route path={'/admin'}>
        <Redirect to="/admin/settings/customization" />
      </Route>
      <Route path={'/admin/*'}>
        <Redirect to="/admin/settings/customization" />
      </Route>
    </Switch>
  )
}

export default Routes
