import { uniqWith } from 'lodash'

/**
 * Get unique items from a list for a specific key. Eg. categories or segments.
 *
 * @param list is the list to loop through,
 * @param field is the field to get unique items from,
 * @param compareField is the field to compare uniqueness by.
 * @returns a list of unique items.
 */
export const getUniqueItemsFromField = (
  list: any[],
  field: string,
  compareField: string
) => {
  const items: any[] = []

  list.forEach((listItem: any) => {
    if (listItem?.fields[field] === undefined) return

    if (Array.isArray(listItem?.fields[field])) {
      listItem?.fields[field].map((fieldItem: any) => items.push(fieldItem))
    } else {
      items.push(listItem?.fields[field])
    }
  })

  return uniqWith(
    items,
    (a: any, b: any) => a?.fields[compareField] === b?.fields[compareField]
  )
}
