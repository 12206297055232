import React, { useEffect, useMemo, useState } from 'react'
import { ApplicationState } from 'redux/Stores/types'
import { ConnectedProps, connect } from 'react-redux'

import AccountTree from 'components_new/molecules/AccountTree'
import GuideDialog from 'components_new/molecules/GuideDialog'
import SetAllocationPerRealEstate from 'components_new/molecules/SetAllocationPerRealEstate'

import * as AllocationKeyActions from 'redux/actions/AllocationKeys'

import Actions from './Actions'

import {
  IParsedRealEstateForAllocationKeys,
  parseAllocationBody,
  parseRealEstatesForAllocationKeys
} from 'types/GlobalRealEstate'
import TextField from 'components_new/atoms/TextField'

interface ICreateAllocationKeyDialogProps {
  onClose: () => void
  open: boolean
}

const CreateAllocationKeyDialog = (props: ComponentProps) => {
  const [allocations, setAllocations] = useState<{
    [key: string]: string
  }>({})
  const [isCreating, setIsCreating] = useState(false)
  const [nameInput, setNameInput] = useState('')
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([])
  const [step, setStep] = useState(1)

  const disabledAccounts = useMemo(() => {
    return Object.values(props.AllocationKeyStore.data)
      .map((item) => item.accounts)
      .flat()
  }, [props.AllocationKeyStore.data])

  useEffect(() => {
    if (props.open) {
      const initialAllocationKeys: { [key: string]: string } = {}

      Object.values(props.RealEstateStore.data).forEach((realEstate) => {
        initialAllocationKeys[realEstate.id] = '0'
      })

      setAllocations(initialAllocationKeys)
      setStep(1)
      setNameInput('')
      setSelectedAccounts([])
    }
  }, [props.open])

  const parsedRealEstates = useMemo(
    () =>
      parseRealEstatesForAllocationKeys(
        Object.values(props.RealEstateStore.data)
      ),
    [props.RealEstateStore.data]
  )

  const mappedAccounts = useMemo(() => {
    // A real estate company can only have one economy set.
    const map: { [accountNumber: string]: string } = {}
    const set = Object.values(props.EconomySetStore.data)[0]

    if (set) {
      set.accounts.forEach((acc) => {
        if (acc.name) {
          map[acc.number] = acc.name
        }
      })
    }

    return map
  }, [props.EconomySetStore.data])

  function handleCreate() {
    setIsCreating(true)

    props.tryCreateAllocationKey(
      {
        name: nameInput,
        accounts: selectedAccounts,
        allocations: Object.entries(allocations).map(([realEstateId, share]) =>
          parseAllocationBody(realEstateId, share)
        )
      },
      (err) => {
        if (!err) {
          props.onClose()
        }

        setIsCreating(false)
      }
    )
  }

  return (
    <GuideDialog
      actions={
        <Actions
          allocations={allocations}
          nameInput={nameInput}
          isCreating={isCreating}
          onClose={props.onClose}
          onCreate={handleCreate}
          selectedAccounts={selectedAccounts}
          step={step}
          setStep={setStep}
        />
      }
      open={props.open}
      title={getTitle(step)}
      subtitle={getSubtitle(step)}
      steps={3}
      step={step}
    >
      <Body
        allocations={allocations}
        disabledAccounts={disabledAccounts}
        setAllocations={setAllocations}
        step={step}
        mappedAccounts={mappedAccounts}
        nameInput={nameInput}
        setNameInput={setNameInput}
        selectedAccounts={selectedAccounts}
        setSelectedAccounts={setSelectedAccounts}
        realEstates={parsedRealEstates}
      />
    </GuideDialog>
  )
}

function getTitle(step: number) {
  if (step === 1) {
    return 'Namnge din fördelningsnyckel'
  }

  if (step === 2) {
    return 'Välj konton'
  }

  return 'Välj fördelning'
}

function getSubtitle(step: number) {
  if (step === 1) {
    return 'Ge din fördelningsnyckel ett namn så att du kan urskilja den från andra fördelningsnycklar.'
  }

  if (step === 2) {
    return 'Välj vilka konton som ska fördelas med nyckeln. Här kan du välja alla konton som ska fördelas på samma sätt.'
  }

  return 'Välj hur utfallen på konton som inte redan är fördelade via ett kostnadsställe ska fördelas mellan fastigheterna.'
}

const Body = (props: {
  allocations: { [key: string]: string }
  disabledAccounts: string[]
  setAllocations: (keys: { [key: string]: string }) => void
  step: number
  mappedAccounts: { [number: string]: string }
  nameInput: string
  setNameInput: (value: string) => void
  selectedAccounts: string[]
  setSelectedAccounts: (values: string[]) => void
  realEstates: IParsedRealEstateForAllocationKeys[]
}) => {
  if (props.step === 1) {
    return (
      <TextField
        fullWidth={true}
        label="Namn"
        autoComplete="off"
        value={props.nameInput}
        onChange={(event) => props.setNameInput(event.target.value)}
        sx={{ mt: 1, mb: 2 }}
        required
      />
    )
  }

  if (props.step === 2) {
    return (
      <AccountTree
        disabledAccounts={props.disabledAccounts}
        mappedAccounts={props.mappedAccounts}
        selected={props.selectedAccounts}
        setSelected={props.setSelectedAccounts}
      />
    )
  }

  return (
    <SetAllocationPerRealEstate
      allocations={props.allocations}
      setAllocations={props.setAllocations}
      realEstates={props.realEstates}
    />
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AllocationKeyStore: state.AllocationKeyStore,
  EconomySetStore: state.EconomySetStore,
  RealEstateStore: state.RealEstateStore
})

const connector = connect(mapStateToProps, { ...AllocationKeyActions })

type ComponentProps = ConnectedProps<typeof connector> &
  ICreateAllocationKeyDialogProps

export default connector(CreateAllocationKeyDialog)
