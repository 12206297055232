import React, { FC, useEffect } from 'react'

import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'

import { ApplicationState } from 'redux/Stores/types'

import LoadingSettings from 'components_new/organisms/pageLoaders/LoadingSettings'

import AllocationKeysTemplate from 'components_new/templates/settings/AllocationKeys'
import SettingsTemplate from 'components_new/templates/SettingsTemplate'

import * as AccountActions from 'redux/actions/Accounts'
import * as AllocationKeyActions from 'redux/actions/AllocationKeys'
import * as EconomySetActions from 'redux/actions/EconomySets'
import * as RealEstateActions from 'redux/actions/RealEstates'

const mapStateToProps = (state: ApplicationState) => ({
  AccountStore: state.AccountStore,
  AllocationKeyStore: state.AllocationKeyStore,
  AuthStore: state.AuthStore,
  EconomySetStore: state.EconomySetStore,
  RealEstateStore: state.RealEstateStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AccountActions,
      ...AllocationKeyActions,
      ...EconomySetActions,
      ...RealEstateActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type AllocationKeysPageProps = ConnectedProps<typeof connector>

const AllocationKeysPage: FC<AllocationKeysPageProps> = (
  props: AllocationKeysPageProps
) => {
  useEffect(() => {
    if (!props.AllocationKeyStore.fetched) {
      props.tryGetAllAllocationKeys()
    }

    if (!props.EconomySetStore.fetched && !props.EconomySetStore.fetching) {
      props.tryGetAllEconomySets()
    }

    if (!props.RealEstateStore.fetched) {
      props.tryGetAllRealEstates()
    }
  }, [])

  return (
    <>
      <SettingsTemplate>
        {props.RealEstateStore.fetched &&
        props.EconomySetStore.fetched &&
        props.AllocationKeyStore.fetched ? (
              <AllocationKeysTemplate />
            ) : (
              <LoadingSettings
                titles={[
                  'Läser in fördelningsnycklar',
                  'Hämtar fastigheter',
                  'Utför fördelningar',
                  'Hämtar konton'
                ]}
              />
            )}
      </SettingsTemplate>
    </>
  )
}

export default connector(AllocationKeysPage)
