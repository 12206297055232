import React, { useState } from 'react'

import {
  KpiOptionObject,
  KpiOptionPatchAttributes
} from 'types/GlobalKpiOption'
import { WidgetObject } from 'types/GlobalWidget'

import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsEditDialog from 'components_new/molecules/SettingsEditDialog'
import { TInputType } from 'components_new/molecules/SettingsEditDialog/utils'
import SettingsItem from 'components_new/molecules/SettingsItem'
import TrendTag from 'components_new/molecules/TrendTag'

import ThresholdSection from './ThresholdSection'

interface IndicationSectionProps {
  kpi: KpiOptionObject
  onClose: () => void
  updateKpiOption: (body: KpiOptionPatchAttributes) => void
  widget: WidgetObject
}

const IndicationSection = (props: IndicationSectionProps) => {
  const { kpi, onClose, updateKpiOption, widget } = props

  /*-- dialogs --*/
  const [dialog, setDialog] = useState<{
    open: boolean
    property: TProperty
    title: string
    data: {
      options: { label: string; value: any }[]
      type: TInputType
      value: string
    }
  }>({
    open: false,
    property: 'increase_is_positive',
    title: '',
    data: {
      options: [],
      type: 'text',
      value: ''
    }
  })

  // Properties edited in this component.
  type TProperty = 'increase_is_positive'

  const getPropertyTitle = (property: TProperty) => {
    switch (property) {
    case 'increase_is_positive':
      return 'Ökning är'
    }
  }

  const handleCloseDialog = () => {
    setDialog((prevState) => ({ ...prevState, open: false }))
  }

  const handleOpenDialog = (property: TProperty) => {
    let inputValue = ''
    let inputType: TInputType = 'text'
    let inputOptions: { label: string; value: any }[] = []

    switch (property) {
    case 'increase_is_positive':
      inputValue = kpi.increase_is_positive.toString()
      inputType = 'select'
      inputOptions = [
        { label: 'Posivtivt', value: 'true' },
        { label: 'Negativt', value: 'false' }
      ]
      break
    }

    setDialog({
      open: true,
      property: property,
      title: getPropertyTitle(property),
      data: {
        options: inputOptions,
        type: inputType,
        value: inputValue
      }
    })
  }

  const handleEditDialogValue = (value: string) => {
    setDialog((prevState) => ({
      ...prevState,
      data: { ...prevState.data, value }
    }))
  }

  const handleSubmitDialog = () => {
    updateKpiOption({
      data: {
        increase_is_positive: dialog.data.value === 'true'
      }
    })
  }

  return (
    <>
      <SettingsGroup title="Indikation">
        <ThresholdSection
          kpi={kpi}
          onClose={onClose}
          updateKpiOption={updateKpiOption}
          widget={widget}
        />
        <SettingsItem
          actions={
            <>
              <TrendTag
                increaseIsPositive={kpi.increase_is_positive}
                value={10}
              />
              <TrendTag
                increaseIsPositive={kpi.increase_is_positive}
                value={-10}
              />
            </>
          }
          onClick={() => handleOpenDialog('increase_is_positive')}
          title={getPropertyTitle('increase_is_positive')}
          value={kpi.increase_is_positive ? 'Positivt' : 'Negativt'}
          variant="edit"
        />
      </SettingsGroup>

      {/*-- dialogs --*/}
      <SettingsEditDialog
        onChange={handleEditDialogValue}
        onClose={handleCloseDialog}
        onSubmit={handleSubmitDialog}
        open={dialog.open}
        options={dialog.data.options}
        title={dialog.title}
        type={dialog.data.type}
        value={dialog.data.value}
      />
    </>
  )
}

export default IndicationSection
