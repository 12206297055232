import React from 'react'

import Box from 'components_new/atoms/Box'
import Icon, { IconNames } from 'components_new/atoms/Icon'

import OptionsMenuButton from 'components_new/molecules/OptionsMenuButton'
import OverflowText from 'components_new/molecules/OverflowText'

import { translateFunnelStatusType } from 'utils/enumTranslator'
import { FunnelStatus } from 'types/GlobalWidget'

interface ReOrderItemProps {
  editable: boolean
  hidden: boolean
  setHidden: (hidden: boolean) => void
  status: FunnelStatus | null
  setStatus: (status: FunnelStatus) => void
  title: string
}

const ReOrderItem = React.forwardRef((props: ReOrderItemProps, ref: any) => {
  const { editable, hidden, setHidden, status, setStatus, title } = props

  const funnelStatus = status ? translateFunnelStatusType[status] : null

  return (
    <Box
      ref={ref}
      sx={{
        alignItems: 'center',
        bgcolor: funnelStatus
          ? `${funnelStatus.color}.transparent`
          : 'action.hover',
        cursor: editable ? 'grab' : 'default',
        '&:hover': editable
          ? {
              bgcolor: funnelStatus
                ? `${funnelStatus.color}.transparent`
                : 'action.focus'
            }
          : undefined,
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        minWidth: 0,
        opacity: hidden ? 0.5 : undefined,
        position: 'relative',
        px: 0.5
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          right: 0,
          top: 0,
          p: 0.5,
          color: funnelStatus ? `${funnelStatus.color}.main` : 'action.disabled'
        }}
      >
        {hidden && !editable ? (
          <Icon
            name={'VisibilityOffOutlined'}
            color="inherit"
            sx={{ fontSize: 18 }}
          />
        ) : null}
        {editable ? (
          <OptionsMenuButton
            options={[
              {
                iconName: 'PinDropOutlined',
                infoTip:
                  'Genom att markera en händelse som slutpunkt av ett flöde så exkluderas den från fördelningen men visas fortfarande som en del av flödet.',
                onClick: () => {},
                title: 'Markera som slutpunkt',
                nestedOptions: [
                  {
                    iconName: (
                      translateFunnelStatusType[FunnelStatus.COMPLETED] as {
                        iconName: IconNames
                        color: 'error' | 'success'
                      }
                    ).iconName,
                    onClick: () => setStatus(FunnelStatus.COMPLETED),
                    title: 'Avslutad'
                  },
                  {
                    divider: true,
                    iconName: (
                      translateFunnelStatusType[FunnelStatus.TERMINATED] as {
                        iconName: IconNames
                        color: 'error' | 'success'
                      }
                    ).iconName,
                    onClick: () => setStatus(FunnelStatus.TERMINATED),
                    title: 'Avbruten'
                  },
                  {
                    iconName: 'RestartAltOutlined',
                    onClick: () => setStatus(FunnelStatus.UNSET),
                    title: 'Återställ'
                  }
                ]
              },
              {
                iconName: hidden
                  ? 'VisibilityOutlined'
                  : 'VisibilityOffOutlined',
                onClick: () => setHidden(!hidden),
                title: hidden ? 'Visa' : 'Dölj'
              }
            ]}
            MenuSx={{ zIndex: 1500 }}
            tooltip="Redigera"
          />
        ) : null}
      </Box>
      <Box
        sx={{
          minWidth: 0,
          textAlign: 'center',
          overflowX: 'hidden',
          overflowY: 'visible'
        }}
      >
        {funnelStatus ? (
          <Icon
            name={funnelStatus.iconName}
            color={status ? funnelStatus.color : 'disabled'}
          />
        ) : null}
        <OverflowText
          variant="body1"
          color={funnelStatus ? `${funnelStatus.color}.main` : undefined}
        >
          {title}
        </OverflowText>
      </Box>
    </Box>
  )
})

ReOrderItem.displayName = 'ReOrderItem'
export default ReOrderItem
