import React from 'react'

import Box from 'components_new/atoms/Box'
import Paper from 'components_new/atoms/Paper'
import Skeleton from 'components_new/atoms/Skeleton'

import ThumbnailList from 'components_new/molecules/ThumbnailList'

const Loading = () => {
  return (
    <>
      <Paper
        sx={{
          height: '100%',
          width: '100%',
          p: 2,
          overflow: 'hidden'
        }}
      >
        <Box
          sx={{
            mb: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Skeleton
            variant="rectangular"
            width={200}
            height={54}
            sx={{ mb: 2 }}
          />
          <Skeleton variant="rectangular" width={272} height={24} />
        </Box>

        <ThumbnailList itemSize={{ xs: 4, md: 6, lg: 4, xl: 3 }}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
            <Skeleton
              key={item}
              variant="rectangular"
              width={'100%'}
              height={320}
            />
          ))}
        </ThumbnailList>
      </Paper>
    </>
  )
}

Loading.displayName = 'Loading'

export default Loading
