import React, { ReactNode, useMemo } from 'react'

import { Link, useLocation } from 'react-router-dom'

interface LinkWrapperProps {
  children: ReactNode
  disabled?: boolean
  disableHistory?: boolean
  goBack?: boolean
  href: string | undefined
  newTab?: boolean
  search?: string
  style?: object
}

export type TLocationState =
  | { prevPathname: string; prevSearch: string }
  | undefined

const LinkWrapper = React.forwardRef((props: LinkWrapperProps, ref: any) => {
  const {
    children,
    disabled,
    disableHistory,
    goBack,
    href,
    newTab,
    search,
    style
  } = props

  const location = useLocation()

  const locationState = location.state as TLocationState

  const currentPrevLocation = useMemo(() => {
    return {
      pathname: locationState?.prevPathname ?? '/',
      search: locationState?.prevSearch ?? ''
    }
  }, [location])

  const newPrevLocation = useMemo(() => {
    if (disableHistory) {
      return {
        prevPathname: locationState?.prevPathname ?? '/',
        prevSearch: locationState?.prevSearch ?? ''
      }
    } else {
      return {
        prevPathname: location.pathname,
        prevSearch: location.search
      }
    }
  }, [location])

  const newLocation = useMemo(() => {
    if (goBack) {
      return {
        pathname: currentPrevLocation.pathname,
        search: currentPrevLocation.search
      }
    } else {
      return {
        pathname: href ?? '/',
        search: search ?? ''
      }
    }
  }, [location])

  if ((goBack || href) && !disabled) {
    return (
      <Link
        target={newTab ? '_blank' : undefined}
        to={{
          ...newLocation,
          state: newPrevLocation
        }}
        ref={ref}
        rel={newTab ? 'noopener' : undefined}
        role={undefined}
        style={style}
      >
        {children}
      </Link>
    )
  }

  return <>{children}</>
})

LinkWrapper.displayName = 'LinkWrapper'
export default LinkWrapper
