import React, { useState } from 'react'

import {
  InnerWidgetPatchBody,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'

import Icon from 'components_new/atoms/Icon'
import Switch from 'components_new/atoms/Switch'

import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'
import SettingsEditDialog from 'components_new/molecules/SettingsEditDialog'
import { TInputType } from 'components_new/molecules/SettingsEditDialog/utils'

interface AreaSectionProps {
  updateWidget: (arg0: string, arg1: InnerWidgetPatchBody) => void
  widget: WidgetObject
}

const LEGAL_TYPES = [
  WidgetType.BAR_CHART,
  WidgetType.BUBBLE_CHART,
  WidgetType.COMBO_CHART,
  WidgetType.LINE_CHART
]

const AreaSection = (props: AreaSectionProps) => {
  const { updateWidget, widget } = props

  if (!LEGAL_TYPES.includes(widget.settings.type.selected)) {
    return null
  }

  /*-- toggles --*/
  const handleToggle = (property: 'invert_y_axis') => {
    let body: InnerWidgetPatchBody = {}

    switch (property) {
    case 'invert_y_axis':
      body = {
        invert_y_axis: !widget.settings.invert_y_axis
      }
      break
    }

    updateWidget(widget.id, body)
  }

  /*-- dialogs --*/
  const [dialog, setDialog] = useState<{
    open: boolean
    property: TProperty
    title: string
    data: {
      type: TInputType
      value: string
    }
  }>({
    open: false,
    property: 'max_value',
    title: '',
    data: {
      value: '',
      type: 'text'
    }
  })

  // Properties edited in this component.
  type TProperty = 'max_value' | 'min_value'

  const getPropertyTitle = (property: TProperty) => {
    switch (property) {
    case 'max_value':
      return 'Undre gräns'
    case 'min_value':
      return 'Övre gräns'
    }
  }

  const handleCloseDialog = () => {
    setDialog((prevState) => ({ ...prevState, open: false }))
  }

  const handleOpenDialog = (property: TProperty) => {
    let inputValue = ''

    switch (property) {
    case 'max_value':
      inputValue = widget.settings.max_value?.toString() ?? ''
      break
    case 'min_value':
      inputValue = widget.settings.min_value?.toString() ?? ''
      break
    }

    setDialog({
      open: true,
      property: property,
      title: getPropertyTitle(property),
      data: {
        type: 'number',
        value: inputValue
      }
    })
  }

  const handleEditDialogValue = (value: string) => {
    setDialog((prevState) => ({
      ...prevState,
      data: { ...prevState.data, value }
    }))
  }

  const handleSubmitDialog = () => {
    let body: InnerWidgetPatchBody = {}

    switch (dialog.property) {
    case 'max_value':
      body = {
        max_value: dialog.data.value ? parseInt(dialog.data.value) : null
      }
      break
    case 'min_value':
      body = {
        min_value: dialog.data.value ? parseInt(dialog.data.value) : null
      }
      break
    }

    updateWidget(widget.id, body)
  }

  return (
    <>
      <SettingsGroup title={'Yta'}>
        <SettingsItem
          actions={
            <Switch
              checked={widget.settings.invert_y_axis}
              onChange={() => handleToggle('invert_y_axis')}
              size="small"
            />
          }
          title="Invertera"
        />
        <SettingsItem
          icon={<Icon name={'VerticalAlignTopOutlined'} />}
          onClick={() => handleOpenDialog('max_value')}
          title={getPropertyTitle('max_value')}
          value={widget.settings.max_value}
          variant="edit"
        />
        <SettingsItem
          icon={<Icon name={'VerticalAlignBottomOutlined'} />}
          onClick={() => handleOpenDialog('min_value')}
          title={getPropertyTitle('min_value')}
          value={widget.settings.min_value}
          variant="edit"
        />
      </SettingsGroup>

      {/*-- dialogs --*/}
      <SettingsEditDialog
        onChange={handleEditDialogValue}
        onClose={handleCloseDialog}
        onSubmit={handleSubmitDialog}
        open={dialog.open}
        title={dialog.title}
        type={dialog.data.type}
        value={dialog.data.value}
      />
    </>
  )
}

export default AreaSection
