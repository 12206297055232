import React, { useEffect } from 'react'

import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { ApplicationState } from 'redux/Stores/types'

import * as AccountStoreActions from 'redux/actions/Accounts'
import * as CompanyGroupActions from 'redux/actions/CompanyGroups'
import * as DashboardExampleActions from 'redux/actions/DashboardExamples'
import * as DashboardFolderActions from 'redux/actions/DashboardFolders'
import * as KPIDashboardStoreActions from 'redux/actions/Dashboards'

import DashboardExampleExplorer from 'components_new/organisms/DashboardExampleExplorer'

import ExploreTemplate from 'components_new/templates/ExploreTemplate'

const Explore = (props: ComponentProps) => {
  const {
    AccountStore,
    CompanyGroupStore,
    DashboardFolderStore,
    DashboardExampleStore,
    KPIDashboardStore,
    tryGetAllAccounts,
    tryGetAllCompanyGroups,
    tryGetAllDashboards,
    tryGetAllDashboardExamples,
    tryGetAllDashboardFolders
  } = props

  useEffect(() => {
    if (!DashboardExampleStore.fetched && !DashboardExampleStore.fetching) {
      tryGetAllDashboardExamples()
    }

    if (!AccountStore.fetched) {
      tryGetAllAccounts()
    }

    if (!DashboardFolderStore.fetched) {
      tryGetAllDashboardFolders()
    }

    if (!KPIDashboardStore.fetched && !KPIDashboardStore.fetching) {
      tryGetAllDashboards()
    }

    if (!CompanyGroupStore.fetched && !CompanyGroupStore.fetching) {
      tryGetAllCompanyGroups()
    }
  }, [])

  return (
    <ExploreTemplate>
      <DashboardExampleExplorer />
    </ExploreTemplate>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AccountStore: state.AccountStore,
  CompanyGroupStore: state.CompanyGroupStore,
  DashboardExampleStore: state.DashboardExampleStore,
  DashboardFolderStore: state.DashboardFolderStore,
  KPIDashboardStore: state.KPIDashboardStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AccountStoreActions,
      ...CompanyGroupActions,
      ...DashboardExampleActions,
      ...DashboardFolderActions,
      ...KPIDashboardStoreActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector>

export default connector(Explore)
