import Axios from 'axios'

export async function sendMessageToSlack(data, email) {
  const body = {
    blocks: [
      {
        type: 'header',
        text: {
          type: 'plain_text',
          text: ':hammer_and_wrench: Någon vill få hjälp med att bygga en dashboard!'
        }
      },
      {
        type: 'divider'
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*Dashboard*: ${data.title}`
        }
      },
      {
        type: 'divider'
      },
      {
        type: 'context',
        elements: [
          {
            type: 'mrkdwn',
            text: `Skickades av: *${email}*`
          }
        ]
      }
    ].filter(Boolean)
  }

  return Axios.post(
    'https://hooks.slack.com/services/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',
    JSON.stringify(body),
    {
      headers: { 'content-type': 'application/x-www-form-urlencoded' }
    }
  )
}
