import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { User } from 'types/GlobalUser'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'

import Link from 'components_new/atoms/Link'
import Chip from 'components_new/atoms/Chip'
import Portal from 'components_new/atoms/Portal'
import Snackbar from 'components_new/atoms/Snackbar'
import Tooltip from 'components_new/atoms/Tooltip'

interface HeaderProps {
  createdAt: string
  id: string
  sx?: object
  title: string
  totalVisits: number
  uniqueVisits: number
  updatedAt: string
  user: User | null
}

const Header = (props: HeaderProps) => {
  const {
    createdAt,
    id,
    sx,
    title,
    totalVisits,
    uniqueVisits,
    updatedAt,
    user
  } = props

  const history = useHistory()

  const createdDiff = Date.now() - Date.parse(createdAt)
  const createdDayDiff = Math.round(createdDiff / (1000 * 3600 * 24))
  const showNew = createdDayDiff < 30

  const updatedDiff = Date.now() - Date.parse(updatedAt)
  const updatedDayDiff = Math.round(updatedDiff / (1000 * 3600 * 24))
  const showUpdated = updatedDayDiff < 14 && !showNew

  const [copySuccess, setCopySuccess] = useState<boolean>(false)

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(location.href)
    setCopySuccess(true)
  }

  return (
    <>
      <Box sx={sx}>
        <Link
          disableHistory={true}
          href={'/explore'}
          search={history.location.search}
          sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
        >
          Stäng
        </Link>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            mb: 1
          }}
        >
          <Text variant="h2">{title}</Text>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              ml: 2
            }}
          >
            <Button
              onClick={copyLinkToClipboard}
              size="large"
              startIcon={<Icon name="LinkOutlined" />}
              variant="outlined"
            >
              Kopiera länk
            </Button>
            <Button
              component="a"
              endIcon={<Icon name="OpenInNewOutlined" />}
              href={`https://portal.homepal.se/public/dashboards/${id}`}
              newTab={true}
              size="large"
              variant="outlined"
            >
              Visa i fullskärm
            </Button>
          </Box>
        </Box>
        <Box sx={{ mb: 1 }}>
          {showNew ? (
            <Chip color="primary" label="Nyhet ✨" variant="outlined" />
          ) : null}
          {showUpdated ? (
            <Chip color="primary" label="Uppdaterad 🎉" variant="outlined" />
          ) : null}
        </Box>
        <Text color="text.secondary" variant="caption">
          Skapad {new Date(createdAt).toLocaleDateString('sv')} • Uppdaterad{' '}
          {new Date(updatedAt).toLocaleDateString('sv')}
          {user && user.is_homepal_user ? (
            <>
              {' • '}
              <Tooltip
                title={`${uniqueVisits} ${
                  uniqueVisits === 1 ? 'unik' : 'unika'
                } användare`}
              >
                <Link sx={{ cursor: 'default' }}>
                  {totalVisits} {totalVisits === 1 ? 'visning' : 'visningar'}
                </Link>
              </Tooltip>
            </>
          ) : null}
        </Text>
      </Box>

      {/*-- snackbars --*/}
      <Portal>
        <Snackbar
          open={copySuccess}
          autoHideDuration={3000}
          onClose={(event, reason) => {
            if (reason !== 'clickaway') {
              setCopySuccess(false)
            }
          }}
          message="Kopierat till urklipp"
        />
      </Portal>
    </>
  )
}

export default Header
