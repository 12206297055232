import { Dispatch } from 'redux'

import * as Types from 'redux/Types'

import { addVisit, getDashboardExamples } from 'redux/api/DashboardExamples'

export function tryGetAllDashboardExamples() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_DASHBOARD_EXAMPLES
    })

    getDashboardExamples()
      .then((response) => {
        dispatch({
          type: Types.GET_DASHBOARD_EXAMPLES_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_DASHBOARD_EXAMPLES_FAILED
        })
      })
  }
}

export function tryAddDashboardExampleVisit(dashboardId: string) {
  return () => {
    addVisit({ data: { dashboard_id: dashboardId } }).catch(() => {
      // do nothing
    })
  }
}
