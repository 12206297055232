import React from 'react'

import Box from 'components_new/atoms/Box'
import Paper from 'components_new/atoms/Paper'

interface EmbeddedDashboardProps {
  disableAspectRatio?: boolean
  disableBorder?: boolean
  id: string
  sx?: object
}

const EmbeddedDashboard = (props: EmbeddedDashboardProps) => {
  const { disableAspectRatio, disableBorder, id, sx } = props

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: disableAspectRatio ? '100%' : undefined,
          aspectRatio: disableAspectRatio ? undefined : 16 / 9,
          ...sx
        }}
      >
        {disableBorder ? null : (
          <Paper
            sx={{
              position: 'absolute',
              width: 'calc(100% + 2px)',
              height: 'calc(100% + 2px)',
              top: '-1px',
              left: '-1px',
              bgcolor: 'black.main'
            }}
          />
        )}
        <iframe
          key={id}
          id="dashboard-iframe"
          style={{
            position: 'absolute',
            zIndex: 1,
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: '100%',
            height: '100%',
            borderRadius: 16,
            overflow: 'hidden'
          }}
          src={`https://portal.homepal.se/public/dashboards/${id}`}
          allowFullScreen
          frameBorder="0"
        ></iframe>
      </Box>
    </>
  )
}

EmbeddedDashboard.displayName = 'EmbeddedDashboard'
export default EmbeddedDashboard
