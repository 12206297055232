import React from 'react'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { options } from 'utils/richTextRenderer'

import Box from 'components_new/atoms/Box'
import Text from 'components_new/atoms/Text'
import Paper from 'components_new/atoms/Paper'

interface ContentProps {
  body: any
  hint: any | undefined
  summary: any
  sx?: object
}

const Content = (props: ContentProps) => {
  const { body, hint, summary, sx } = props

  return (
    <Box sx={sx}>
      <Text component="div" sx={{ mb: 4 }} variant="subtitle1">
        {documentToReactComponents(summary, options)}
      </Text>
      <Text component="div" sx={{ mb: 2 }} variant="h5">
        Varför ska du använda dashboarden?
      </Text>
      <Text component="div" variant="body1">
        {documentToReactComponents(body, options)}
      </Text>
      {hint ? (
        <Paper sx={{ p: 4, my: 4 }} variant="outlined">
          <Text component="div" sx={{ mb: 2 }} variant="h5">
            Tips!
          </Text>
          <Text component="div" variant="body1">
            {documentToReactComponents(hint, options)}
          </Text>
        </Paper>
      ) : null}
    </Box>
  )
}

export default Content
